(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosAtrasados', ChamadosAtrasados);

    ChamadosAtrasados.$inject = ['$resource'];

    function ChamadosAtrasados($resource) {
        var resourceUrl = 'api/relatorio-chamados-prazo';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            }

        });
    }


})();
