(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {

        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'getAllInternos': {url: 'api/users-internos', method: 'GET', isArray: true},
            'getAllWithEquipe': {url: 'api/users-com-equipe', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'},
            'reset': {method: 'GET', url:'api/users/reset-password/:login'}
        });

        return service;
    }
})();
