(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SolicitacaoDesenvolvimentoImportarController', SolicitacaoDesenvolvimentoImportarController);

    SolicitacaoDesenvolvimentoImportarController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$uibModalInstance',
        'entity', 'SolicitacaoDesenvolvimento', 'User', 'Sistema', 'Principal', 'Cliente', 'Modulo', 'SubModulo'];

    function SolicitacaoDesenvolvimentoImportarController($timeout, $scope, $stateParams, $state, $uibModalInstance,
                                                      entity, SolicitacaoDesenvolvimento, User, Sistema,
                                                      Principal, Cliente, Modulo, SubModulo) {
        var vm = this;

        vm.solicitacaoDesenvolvimento = entity;
        vm.clear = clear;
        vm.save = save;
        vm.carregarSistemas = carregarSistemas;
        vm.carregarSubModulo = carregarSubModulo;
        vm.modulos = Modulo.queryByUser();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            SolicitacaoDesenvolvimento.importarRedmine(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:solicitacaoDesenvolvimentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.solicitacaoDesenvolvimento.solicitante = vm.account;
                Cliente.getByUser({}, function (data) {
                    vm.clientes = data;
                    vm.solicitacaoDesenvolvimento.cliente = data[0];
                    carregarSistemas();

                })

            });
        }

        function carregarSistemas() {
            if (vm.solicitacaoDesenvolvimento.cliente) {
                Sistema.getByCliente({clienteId: vm.solicitacaoDesenvolvimento.cliente.id}, function (data) {
                    vm.sistemas = data;
                    vm.solicitacaoDesenvolvimento.sistema = data[0];

                })
            } else {
                vm.sistemas = [];
            }
        }

        function carregarSubModulo() {
            if (vm.solicitacaoDesenvolvimento.modulo) {
                vm.submodulos = SubModulo.queryByModulo({id: vm.solicitacaoDesenvolvimento.modulo.id});
            }
        }

        getAccount();

    }
})();
