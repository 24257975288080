(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['Cliente', 'entity', 'User', 'JhiLanguageService', 'Modulo', 'SubModulo', 'Chamado', 'Sistema', '$state', 'Account', 'Upload'];

    function UserManagementDialogController(Cliente, entity, User, JhiLanguageService, Modulo, SubModulo, Chamado, Sistema, $state, Account, Upload) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_CLIENTE', 'ROLE_ATENDENTE', 'ROLE_PO', 'ROLE_SCRUM', 'ROLE_RESPONSAVEL', 'ROLE_DESENVOLVEDOR', 'ROLE_SOLICITAR_RELEASE', 'ROLE_PLANEJAMENTO_SPRINT', 'ROLE_OCORRENCIA_CHAMADO','ROLE_DEVOPS'];
        vm.notificacoes = ['DEPLOY_PRODUCAO'];
        vm.languages = null;
        vm.save = save;
        vm.indexCliente = indexCliente;
        vm.indexModulo = indexModulo;
        vm.indexSistema = indexSistema;
        vm.adicionarMdulo = adicionarMdulo;
        vm.adicionarTodosSubModulos = adicionarTodosSubModulos;
        vm.adicionarSistema = adicionarSistema;
        vm.removerTodosSubModulos = removerTodosSubModulos;
        vm.removerMdulo = removerMdulo;
        vm.removerSistema = removerSistema;
        vm.indexSubModulo = indexSubModulo;
        vm.removerFila = removerFila;
        vm.selecionarSistemasCliente = selecionarSistemasCliente;
        vm.removerSistemasCliente = removerSistemasCliente;
        vm.user = entity;
        vm.clientes = Cliente.query({page: 0, size: 99});
        vm.sistemas = [];
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulosTodos = SubModulo.query({page: 0, size: 99});
        vm.filas = Chamado.getSituacoes();
        if (!vm.user.sistemas) {
            vm.user.sistemas = [];
        }
        if (!vm.user.clientes) {
            vm.user.clientes = [];
        }

        if (!vm.user.authorities) {
            vm.user.authorities = [];
        }
        if (!vm.user.notificacoes) {
            vm.user.notificacoes = [];
        }

        if (!vm.user.filas) {
            vm.user.filas = [];
        } else {
            angular.forEach(vm.user.filas, function (fila) {
                vm.notificacoes.push(fila);
            });
        }
        if (!vm.user.modulos) {
            vm.user.modulos = [];
        }
        if (!vm.user.subModulos) {
            vm.user.subModulos = [];
        } else {
            vm.subModulos = vm.user.subModulos;
        }


        function recuperarImagem() {
            if (vm.user.login) {
                Account.getImagemAjustadaByLogin({login: vm.user.login}, function (data) {
                    if (data.imagemAjustada) {
                        vm.imagemOginal = data.imagemAjustada;
                    }
                });
            }
        }

        recuperarImagem();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function onSaveSuccess(result) {
            if (vm.picFile) {

                Upload.dataUrl(vm.picFile, true).then(
                    function (original) {
                        if (original && vm.croppedDataUrl) {
                            Account.saveImagem({
                                imagemOriginal: original,
                                imagemAjustada: vm.croppedDataUrl,
                                usuario: vm.user
                            }, function (data) {
                                vm.imagemOginal = data.imagemOriginal;
                                vm.croppedDataUrl = data.imagemAjustada;
                                $rootScope.$broadcast('authenticationSuccess');
                            });
                        }
                    }
                );
            }

            vm.isSaving = false;
            $state.go('user-management-detail', {login: result.login})
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function save() {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function indexCliente(id) {
            for (var i = 0; i < vm.user.clientes.length; i++) {
                if (vm.user.clientes[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function indexModulo(id) {
            for (var i = 0; i < vm.user.modulos.length; i++) {
                if (vm.user.modulos[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function indexSistema(id) {
            for (var i = 0; i < vm.user.sistemas.length; i++) {
                if (vm.user.sistemas[i].id === id) {
                    return i;
                }
            }
            return -1;
        }


        function indexSistemaDisponivel(id) {
            for (var i = 0; i < vm.sistemas.length; i++) {
                if (vm.sistemas[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function adicionarMdulo(modulo) {
            vm.user.modulos.push(modulo);
            vm.subModulos = [];
            angular.forEach(vm.subModulosTodos, function (submodulo) {
                if (submodulo.modulo != null) {
                    angular.forEach(vm.user.modulos, function (moduloUser) {
                        if (submodulo.modulo.id === moduloUser.id) {
                            vm.subModulos.push(submodulo);
                            vm.user.subModulos.push(submodulo);
                        }
                    });
                }
            });
        }

        function adicionarSistema(sistema) {
            vm.user.sistemas.push(sistema);
        }

        function adicionarTodosSubModulos() {
            angular.forEach(vm.subModulos, function (submodulo) {
                if (submodulo.modulo != null) {
                    angular.forEach(vm.user.modulos, function (moduloUser) {
                        if (submodulo.modulo.id === moduloUser.id) {
                            vm.user.subModulos.push(submodulo);
                        }
                    });
                }
            });
        }

        function selecionarSistemasCliente() {
            vm.sistemas = [];
            angular.forEach(vm.user.clientes, function (cliente) {
                    Cliente.get({id: cliente.id}, function (data) {
                        if (data.sistemas) {
                            angular.forEach(data.sistemas, function (sistema) {
                                if (indexSistemaDisponivel(sistema.id) < 0) {
                                    vm.sistemas.push(sistema);
                                }
                            });
                        }
                    });
                }
            );
        }

        function removerSistemasCliente(cliente) {
            if (vm.user.sistemas) {
                Cliente.get({id: cliente.id}, function (data) {
                    if (data.sistemas) {
                        angular.forEach(data.sistemas, function (sistema) {
                            if (indexSistema(sistema.id) >= 0) {
                                removerSistema(sistema);
                            }
                        });
                    }
                });
            }
            selecionarSistemasCliente();
        }

        selecionarSistemasCliente();

        function removerSistema(sistema) {
            vm.user.sistemas.splice(vm.indexSistema(sistema.id), 1);
        }

        function removerMdulo(modulo) {
            vm.user.modulos.splice(vm.indexModulo(modulo.id), 1)
            angular.forEach(vm.subModulosTodos, function (submodulo) {
                if (submodulo.modulo != null) {
                    if (submodulo.modulo.id === modulo.id) {
                        vm.user.subModulos.splice(vm.indexSubModulo(submodulo.id), 1)
                        vm.subModulos.splice(vm.subModulos.map(function (item) {
                            return item.id;
                        }).indexOf(submodulo.id), 1)
                    }
                }
            });
        }

        function removerTodosSubModulos() {
            angular.forEach(vm.subModulosTodos, function (submodulo) {
                if (submodulo.modulo != null) {
                    vm.user.subModulos.splice(vm.indexSubModulo(submodulo.id), 1)
                }
            });
        }


        function indexSubModulo(id) {
            for (var i = 0; i < vm.user.subModulos.length; i++) {
                if (vm.user.subModulos[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function removerFila(fila) {
            vm.user.filas.splice(vm.user.filas.indexOf(fila), 1);
            if (vm.notificacoes.indexOf(fila) > -1) {
                vm.notificacoes.splice(vm.notificacoes.indexOf(fila), 1);
                if (vm.user.notificacoes.indexOf(fila) > -1) {
                    vm.user.notificacoes.splice(vm.user.notificacoes.indexOf(fila), 1);
                }
            }
        }
    }
})
();
