(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AtaEditController', AtaEditController);

    AtaEditController.$inject = ['$scope', '$state', 'entity', 'Ata'];

    function AtaEditController($scope, $state, entity, Ata) {
        var vm = this;
        vm.ata = entity;
        vm.cancel = cancel;
        vm.save = save;



        function cancel() {
            $state.go('ata');
        }

        function save() {
            vm.isSaving = true;
            if (vm.ata.id !== null) {
                Ata.update(vm.ata, onSaveSuccess, onSaveError);
            } else {
                Ata.save(vm.ata, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:sprintUpdate', result);
            cancel();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
