(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadoEditController', ChamadoEditController);

    ChamadoEditController.$inject = ['$timeout', '$scope',
        '$state', 'previousState', 'entity',
        'Cliente', 'Sistema', 'Chamado', 'Sprint',
        'Modulo', 'Principal',
        '$uibModal', 'Alerta',
        'Upload', 'AnexoChamado',
        'Account', '$http',
        'SweetAlert', 'SubModulo', '$sanitize',
        'SolicitacaoDesenvolvimento', '$filter', 'Agrupador', 'Solicitante'];

    function ChamadoEditController($timeout, $scope,
                                   $state, previousState, entity,
                                   Cliente, Sistema, Chamado, Sprint,
                                   Modulo, Principal,
                                   $uibModal, Alerta,
                                   Upload, AnexoChamado,
                                   Account, $http,
                                   SweetAlert, SubModulo, $sanitize,
                                   SolicitacaoDesenvolvimento, $filter, Agrupador, Solicitante) {
        var vm = this;
        vm.chamado = entity;
        vm.ordemOriginal = vm.chamado.ordem;
        vm.solicitacoes = [];
        vm.datePickerOpenStatus = {};
        vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
        vm.save = save;
        vm.saveAndSolicitaDev = saveAndSolicitaDev;
        vm.saveAndEnviaBackLog = saveAndEnviaBackLog;
        vm.saveSuporte = saveSuporte;
        vm.aceitar = aceitar;
        vm.comentar = comentar;
        vm.solicitarDesenvolvimento = solicitarDesenvolvimento;
        vm.solicitarDesenvolvimentoTicketExistente = solicitarDesenvolvimentoTicketExistente;
        vm.encaminharPO = encaminharPO;
        vm.liberarTeste = liberarTeste;
        vm.retornarDesenvolvimento = retornarDesenvolvimento;
        vm.liberarTesteSuporte = liberarTesteSuporte;
        vm.reprovarDesenvolvimento = reprovarDesenvolvimento;
        vm.impedir = impedir;
        vm.reabrir = reabrir;
        vm.copiar = copiar;
        vm.copiarSimples = copiarSimples;
        vm.encerrar = encerrar;
        vm.validarTeste = validarTeste;
        vm.validarTesteUsuario = validarTesteUsuario;
        vm.rejeitar = rejeitar;
        vm.upload = upload;
        vm.removerAnexo = removerAnexo;
        vm.inserirAnexoConteudo = inserirAnexoConteudo;
        vm.removerSolicitacaoDesenvolvimento = removerSolicitacaoDesenvolvimento;
        vm.downloadAnexo = downloadAnexo;
        vm.calcularOrdem = calcularOrdem;
        vm.buscarRedMine = buscarRedMine;
        vm.definirTempoGasto = definirTempoGasto;
        vm.carregarSubModulo = carregarSubModulo;
        vm.criarDependencia = criarDependencia;
        vm.removerDependencia = removerDependencia;
        vm.removerTodasDependencia = removerTodasDependencia;
        vm.html5Entities = html5Entities;
        vm.trustAsHtml = trustAsHtml;
        vm.carregarSistemas = carregarSistemas;
        vm.sprints = Sprint.query();
        vm.modulos = Modulo.queryByUser();
        vm.previousState = previousState.name;
        vm.ajustarChamado = ajustarChamado;
        vm.aprovarChamado = aprovarChamado;
        vm.reestimarChamado = reestimarChamado;
        vm.alocarChamadoSprintAtual = alocarChamadoSprintAtual;
        vm.desalocarChamadoSprintAtual = desalocarChamadoSprintAtual;
        vm.adicionarTag = adicionarTag;
        vm.tags = [];

        if (vm.chamado.tempoEstimado) {
            vm.temposEstimados = [];
        }

        getAccount();

        function html5Entities(value) {
            return value.replace(/[\u00A0-\u9999<>\&\'\"]/gim, function (i) {
                return '&#' + i.charCodeAt(0) + ';'
            })
        };

        function trustAsHtml(value) {
            return $sanitize(value);
        };

        $timeout(function () {
            buscarTagsPorChamado();
            angular.element('.form-group:eq(1)>input').focus();
            carregarSistemas();
            carregarSubModulo();
            carregarAnexos();
            carregarSolicitacoesDev();
            contarTempoGasto();
            carregarComentarios();
            carregarFotoSolicitante();
            carregarDependentes();
            carregarAgrupadores();
        });

        function buscarTagsPorChamado() {
            if (vm.chamado.id) {
                vm.tags = Chamado.getTagsPorChamado({id: vm.chamado.id});
            }
        }

        function alocarChamadoSprintAtual() {
            vm.isSaving = true;
            Chamado.alocarSprintAtual(vm.chamado, onSaveSuccess, onSaveError);
        }

        function desalocarChamadoSprintAtual() {
            vm.isSaving = true;
            Chamado.desalocarChamadoSprintAtual(vm.chamado, onSaveSuccess, onSaveError);
        }

        function carregarFotoSolicitante() {
            Account.getImagemAjustadaByLogin({login: vm.chamado.solicitante.login}, function (data) {
                vm.fotoSolicitante = data.imagemAjustada;
            });
        }

        function carregarComentarios() {
            if (vm.chamado.id) {
                Chamado.getComentarios({id: vm.chamado.id}, function (data) {
                    vm.comentarios = data;
                    angular.forEach(vm.comentarios, function (comentario) {
                        Account.getImagemAjustadaByLogin({login: comentario.usuario.login}, function (data) {
                            comentario.imagemUsuario = data.imagemAjustada;
                        });
                        if (comentario.descricao === 'Aceite de chamado') {
                            vm.aceitoPor = comentario;
                        }
                    })
                });
                vm.comentario = {chamado: vm.chamado, usuario: vm.account};
            }
        }

        function carregarSolicitacoesDev() {
            if (vm.chamado.id) {
                Chamado.getSolicitacoes({id: vm.chamado.id}, function (data) {
                    vm.solicitacoes = data;
                    if (vm.solicitacoes.length > 0) {
                        if (vm.chamado.situacao === 'EM_DESENVOLVIMENTO') {
                            vm.chamado.desenvolvimentoIniciado = true;
                        }
                        for (var i = 0; i < vm.solicitacoes.length; i++) {
                            if (vm.solicitacoes[i].situacao === "A fazer") {
                                vm.chamado.desenvolvimentoIniciado = false;
                            }
                        }
                    }
                });
            }
        }

        function carregarDependentes() {
            if (vm.chamado.id) {
                vm.dependentes = Chamado.getDependentes({id: vm.chamado.id});
                vm.relacoes = Chamado.getRelacoes({id: vm.chamado.id});
            }
        }

        function carregarAgrupadores() {
            if (vm.chamado.id) {
                vm.agrupadores = Agrupador.getBychamado({id: vm.chamado.id});
            }
        }

        function contarTempoGasto() {
            if (vm.chamado.id) {
                Chamado.getTempoGasto({id: vm.chamado.id}, function (data) {
                    vm.tempos = {};
                    vm.tempoTotalGasto = 0;
                    angular.forEach(data, function (tempo) {
                        vm.tempoTotalGasto = vm.tempoTotalGasto + tempo.tempo;
                        if (!vm.tempos[tempo.tipo]) {

                            vm.tempos[tempo.tipo] = {
                                tipo: tempo.tipo ? $filter('translate')('chamadosApp.TipoHora.' + tempo.tipo) : tempo.conteudo,
                                tempo: tempo.tempo
                            };
                        } else {
                            vm.tempos[tempo.tipo].tempo = vm.tempos[tempo.tipo].tempo + tempo.tempo;
                        }
                    });
                });
            }
        }

        function carregarAnexos() {
            vm.anexos = [];
            vm.anexosInternos = [];
            vm.chamado.id ? AnexoChamado.query({id: vm.chamado.id}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    var anexo = data[i];
                    if (anexo.tipo === 'PUBLICO') {
                        vm.anexos.push(anexo);
                    } else {
                        vm.anexosInternos.push(anexo);
                    }
                }
            }) : [];
        }

        function removerAnexo(index, anexos) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var anexo = anexos[index];
                        if (anexo.id) {
                            AnexoChamado.delete({id: anexo.id});
                        }
                        anexos.splice(index, 1);
                        comentarTextoChamado("Anexo " + anexo.descricao + " removido");

                    }
                });

        }

        function inserirAnexoConteudo(index) {
            var anexo = vm.anexos[index];

            if (!vm.chamado.conteudo)
                vm.chamado.conteudo = "";
            vm.chamado.conteudo += "\n";

            if (anexo.mimeType.includes("image")) {
                vm.chamado.conteudo += "<img style='width: 50%; height: 50%;' src=\"" + anexo.conteudo + "\">";
                vm.anexos.splice(index, 1);
            }
        }

        function removerSolicitacaoDesenvolvimento(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse vinculo com o ticket?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var solicitacao = vm.solicitacoes[index];
                        if (solicitacao.id) {
                            Chamado.removerSolicitacaoDesenvolvimento({id: solicitacao.id});
                        }
                        vm.solicitacoes.splice(index, 1);
                    }
                });
        }

        function comentarTextoChamado(texto) {
            Chamado.comentar({
                chamado: vm.chamado,
                usuario: vm.account,
                descricao: texto
            }, function (data) {
                vm.comentario = {chamado: vm.chamado};
                carregarComentarios();
                vm.isSaving = false;
            }, onSaveError);
        }

        function downloadAnexo(index, lista) {
            var anexo = lista[index];
            AnexoChamado.downloadAnexo(anexo);
        }


        function saveAndEnviaBackLog() {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja enviar esse chamado para Backlog e aguardar aprovação do cliente?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.chamado.situacao = 'BACKLOG';
                        save();
                    }
                });
        }

        function saveAndSolicitaDev() {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja salvar esse chamado e solciitar o seu desenvolvimento?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.solicitarDesenvolvimentoNoRetornoDoSave = true;
                        save();
                    }
                });
        }

        function save() {
            vm.isSaving = true;
            confirmSave();
        }

        function saveSuporte() {
            vm.chamado.severidade = 'SEM_SEVERIDADE';
            vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
            vm.tempoGasto.tipo = vm.tipo;
            Chamado.saveSuporte(vm.tempoGasto, onSaveSuccess, onSaveError);
        }


        function confirmSave() {
            if (vm.chamado.id !== null) {
                Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
            } else {
                Chamado.save(vm.chamado, onSaveSuccess, onSaveError);
            }
        }

        function calcularOrdem() {
            Chamado.getProximaOrdem({modulo: vm.chamado.modulo.id}, function (data) {
                vm.chamado.ordem = data.ordem;
            })
        }

        function buscarRedMine() {
            if (vm.ticketRedmine) {
                vm.chamado = Chamado.getChamadoRedmine({id: vm.ticketRedmine}, carregarSubModulo);
            }
        }

        function aceitar() {
            if (vm.chamado.tipoChamado !== 'SUPORTE' && vm.chamado.cliente.perguntasAoAceitar) {
                var modalInstance = $uibModal.open({
                    size: 'lg',
                    animation: true,
                    templateUrl: 'aceitarDialog.html',
                    controller: 'ChamadoAceiteController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowClass: 'modal-grande',
                    resolve: {
                        chamado: vm.chamado
                    }
                });

                modalInstance.result.then(function (chamado) {
                    vm.chamado = chamado;
                    Chamado.aceitar(vm.chamado, onSaveSuccess, onSaveError);
                });
            } else {
                vm.isSaving = true;
                Alerta.confirm("Confirme a operação",
                    "Você tem certeza que quer Aceitar esse chamado? Ao continuar o chamado irá mudar de situação, certifique-se do total entendimento do relatado para prosseguir",
                    "warning",
                    function () {
                        Chamado.aceitar(vm.chamado, onSaveSuccess, onSaveError);
                    },
                    onSaveError);
            }
        }

        function comentar() {
            vm.isSaving = true;
            Chamado.comentar(vm.comentario, function (data) {
                vm.comentario = {chamado: vm.chamado};
                carregarComentarios();
                vm.isSaving = false;
            }, onSaveError);
        }

        function definirTempoGasto() {
            vm.isSaving = true;
            vm.tempoGasto.numero = vm.tempoGasto.atividade.id;
            vm.tempoGasto.conteudo = vm.tempoGasto.atividade.name;
            vm.tempoGasto.tempo = vm.tempoGasto.horas + (vm.tempoGasto.minutos / 60)
            Chamado.defirnirTempo(vm.tempoGasto, function (data) {
                vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
                vm.isSaving = false;
                contarTempoGasto();
                vm.inserirHora = false;
            }, onSaveError);
        }

        function liberarTesteSuporte() {
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer encaminhar esse chamado para o teste do suporte? Ao continuar o chamado irá mudar de situação, certifique-se do total entendimento do relatado para prosseguir",
                "warning",
                function () {
                    vm.isSaving = true;
                    vm.chamado.situacao = 'TESTE_SUPORTE';
                    Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
                },
                onSaveError);
        }

        function reprovarDesenvolvimento() {
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer reprovar esse chamado, enviando para a analise do P.O.? Ao continuar o chamado irá mudar de situação, certifique-se do total entendimento do relatado para prosseguir",
                "warning",
                function () {
                    vm.isSaving = true;
                    vm.chamado.situacao = 'EM_ANALISE';
                    Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
                },
                onSaveError);
        }

        function retornarDesenvolvimento() {
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer retornar esse chamado para Desenvolvimento?",
                "warning",
                function () {
                    vm.isSaving = true;
                    vm.chamado.situacao = 'EM_DESENVOLVIMENTO';
                    Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
                },
                onSaveError);
        }

        function liberarTeste() {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'testarContent.html',
                controller: 'ChamadoTesteController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    comentario: vm.comentario
                }
            });
            modalInstance.result.then(function (comentario) {
                vm.isSaving = true;
                vm.comentario = comentario;
                comentar();

                if (comentario.anexos) {
                    angular.forEach(comentario.anexos, function (anexo) {
                        anexo.chamado = vm.chamado;
                        AnexoChamado.save(anexo, function (data) {
                            vm.anexos.push(data);
                        });
                    })
                }
                vm.chamado.situacao = 'AGUARDANDO_TESTE';
                Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
            });

        }

        function validarTeste() {
            vm.isSaving = true;
            vm.chamado.situacao = 'AGUARDANDO_VALIDACAO';
            Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
        }

        function validarTesteUsuario() {
            if (vm.chamado.cliente.releaseNote) {
                if (!vm.chamado.releaseNote) {
                    vm.chamado.releaseNote = vm.chamado.titulo;
                }
                var modalInstance = $uibModal.open({
                    size: 'lg',
                    animation: true,
                    templateUrl: 'validarDialog.html',
                    controller: 'ChamadoValidarController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    windowClass: 'modal-grande',
                    resolve: {
                        chamado: vm.chamado
                    }
                });
                modalInstance.result.then(function (chamado) {
                    vm.chamado = chamado;
                    vm.isSaving = true;
                    vm.chamado.situacao = 'VALIDADO';
                    Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
                });
            } else {
                vm.isSaving = true;
                vm.chamado.situacao = 'VALIDADO';
                Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
            }
        }

        function impedir() {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'impedirContent.html',
                controller: 'ChamadoImpedidoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    comentario: vm.comentario
                }
            });
            modalInstance.result.then(function (comentario) {
                vm.comentario = comentario;
                comentar();
                vm.isSaving = true;
                vm.chamado.situacao = 'IMPEDIDO';
                Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
            });

        }

        function reabrir() {
            vm.isSaving = true;

            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'reabrirContent.html',
                controller: 'ChamadoReabertoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    comentario: vm.comentario
                }
            });
            modalInstance.result.then(function (comentario) {
                vm.comentario = comentario;
                comentar();

                if (comentario.anexos) {
                    angular.forEach(comentario.anexos, function (anexo) {
                        anexo.chamado = vm.chamado;
                        AnexoChamado.save(anexo, function (data) {
                            vm.anexos.push(data);
                        });
                    })
                }

                vm.chamado.situacao = 'ABERTO';
                vm.chamado.sprint = null;
                vm.chamado.solicitante = vm.account;
                Chamado.update(vm.chamado, onSaveSuccess, onSaveError);

            });
        }

        function copiar() {
            vm.isSaving = true;
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer Re-abrir esse chamado já fechado? Ao continuar o chamado será copiado e vinculado para manter a relação ",
                "warning",
                function () {
                    Chamado.copiar({id: vm.chamado.id}, onSaveSuccess, onSaveError)
                },
                onSaveError);
        }

        function copiarSimples() {
            vm.isSaving = true;
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer copiar esse chamado? Ao continuar um novo chamado será criado idêntico a esse, possibilitando a edição",
                "info",
                function () {
                    Chamado.copiar({id: vm.chamado.id}, onSaveSuccess, onSaveError)
                },
                onSaveError);
        }

        function confirmEcerrar() {
            vm.chamado.situacao = 'FECHADO';
            Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
        }

        function encerrar() {
            vm.isSaving = true;
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que quer ENCERRAR esse chamado?",
                "warning",
                confirmEcerrar,
                onSaveError);
        }

        function confirmEncaminharPO() {
            vm.chamado.situacao = 'EM_ANALISE';
            Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
        }

        function encaminharPO() {
            if (vm.chamado.tipoChamado === 'SUPORTE') {
                Alerta.warn("Tipo de Chamado não compativel",
                    "Você pode apenas encaminhar para análise chamados de Melhoria, Bug ou Nova Funcionalidade!")
            } else {
                vm.isSaving = true;
                Alerta.confirm("Confirme a operação",
                    "Você tem certeza que quer encaminhar esse chamado para o P.O?",
                    "warning",
                    confirmEncaminharPO,
                    onSaveError);
            }
        }

        function confirmRejeitar() {
            if (vm.chamado.situacao === 'AGUARDANDO_VALIDACAO') {
                vm.chamado.situacao = 'AGUARDANDO_TESTE';
            } else {
                vm.chamado.situacao = 'REJEITADO';
            }
            Chamado.update(vm.chamado, onSaveSuccess, onSaveError);
        }

        function rejeitar() {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'rejeitarContent.html',
                controller: 'ChamadoRejeicaoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    comentario: vm.comentario
                }
            });
            modalInstance.result.then(function (comentario) {
                vm.comentario = comentario;
                comentar();
                confirmRejeitar();
            });
        }

        function solicitarDesenvolvimento() {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'myModalContent.html',
                controller: 'ChamadoDesenvolvimentoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    chamado: vm.chamado
                }
            });
            modalInstance.result.then(function (solicitacao) {
                vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
                vm.tempoGasto.criadoEm = new Date();
                vm.tempoGasto.numero = 23;
                vm.tempoGasto.conteudo = 'Análise';
                vm.tempoGasto.tempo = solicitacao.horaAnalise;
                Chamado.defirnirTempo(vm.tempoGasto);
                $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
                Alerta.success("Operação realizada!", "Ticket " + solicitacao.numero + " criado no redmine!");
            });
        }

        function criarDependencia() {
            if (vm.dependente) {
                Chamado.get({id: vm.dependente}, function (chamado) {
                    Chamado.criarDependente({chamado: chamado, dependente: vm.chamado}, function (data) {
                        Alerta.success("Operação realizada!", "Dependência criada com sucesso!");
                        carregarDependentes();
                        vm.inserirDependenciaManual = false;
                    }, function (error) {
                        Alerta.error("Operação Não realizada!", "Dependência não pode ser criada!");
                    });
                }, function (error) {
                    Alerta.error("Operação Não realizada!", "Não foi possivel encontrar o chamado com o numero " + vm.dependente);
                });
            }
        }

        function removerTodasDependencia() {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover todas dependências?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        angular.forEach(vm.dependentes, function (dependencia) {
                            Chamado.removerDependente({id: dependencia.id}, function (data) {
                                carregarDependentes();
                            });
                        });
                    }
                });

        }

        function removerDependencia(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover essa dependência?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var dependencia = vm.dependentes[index];
                        if (dependencia.id) {
                            Chamado.removerDependente({id: dependencia.id}, function (data) {
                                carregarDependentes();
                            });
                        }
                    }
                });

        }

        function solicitarDesenvolvimentoTicketExistente(novoTicketRedmine) {
            vm.solicitacaoDesenvolvimento = {numero: novoTicketRedmine, chamado: vm.chamado};
            SolicitacaoDesenvolvimento.save(vm.solicitacaoDesenvolvimento, function (solicitacao) {
                carregarSolicitacoesDev();
                vm.inserirTicketManual = false;
                Alerta.success("Operação realizada!", "Ticket " + solicitacao.numero + " criado no redmine!");
            });
        }

        function onSaveSuccess(result) {
            if (vm.anexos) {
                angular.forEach(vm.anexos, function (anexo) {
                    if (!anexo.id) {
                        anexo.chamado = result;
                        AnexoChamado.save(anexo);
                    }
                });
            }
            $scope.$emit('chamadosApp:chamadoUpdate', result);
            vm.isSaving = false;
            if (vm.solicitarDesenvolvimentoNoRetornoDoSave) {
                $state.go('solicitar-desenvolvimento', {id: result.id}, {reload: true});
            } else {
                $state.go('edit-chamado', {id: result.id}, {reload: true});
            }
            Alerta.success("Operação realizada!", "Chamado " + result.id + " - " + result.titulo + " salvo com sucesso!");
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                Cliente.getByUser({}, function (data) {
                    vm.clientes = data;
                    if (data.length == 1) {
                        vm.chamado.cliente = data[0];
                        carregarSistemas();
                    }
                })

            });
        }

        function carregarSistemas() {
            if (vm.chamado.cliente) {
                carregarSolicitantes();

                Sistema.getByCliente({clienteId: vm.chamado.cliente.id}, function (data) {
                    vm.sistemas = data;
                    if (data.length == 1 && vm.chamado.sistema == null) {
                        vm.chamado.sistema = data[0];
                    }
                })
            } else {
                vm.sistemas = [];
            }
        }

        function carregarSolicitantes() {
            if (vm.chamado.cliente) {
                Solicitante.getByCliente({clienteId: vm.chamado.cliente.id}, function (data) {
                    vm.solicitantes = data;
                })
            } else {
                vm.solicitantes = [];
            }
        }

        function upload($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.mimeType = $file.type;
                        if (vm.chamado.id) {
                            anexo.chamado = vm.chamado;
                            AnexoChamado.save(anexo, function (data) {
                                vm.anexos.push(data);
                                comentarTextoChamado("Anexo " + anexo.descricao + " adicionado");
                            });

                        } else {
                            vm.anexos.push(anexo);
                        }
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 100Mb");
            }
        }

        function carregarSubModulo() {
            if (vm.chamado.modulo) {
                vm.submodulos = SubModulo.queryByModulo({id: vm.chamado.modulo.id});
            }
        }

        function ajustarChamado() {
            var modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'ajustarDialog.html',
                controller: 'AjustarChamadoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    chamado: vm.chamado
                }
            });


            modalInstance.result.then(function (chamado) {
                vm.chamado = chamado;
                confirmSave();
            });
        }

        function adicionarTag() {
            var modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'adicionarTagDialog.html',
                controller: 'AjustarChamadoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    chamado: vm.chamado
                }
            });

            modalInstance.result.then(function (chamado) {
                vm.chamado = chamado;
                confirmSave();
            });
        }

        function aprovarChamado() {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja aprovar esse Chamado?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.chamado.situacao = 'EM_ANALISE';
                        vm.chamado.solicitante = vm.account;
                        confirmSave();
                    }
                });
        }

        function reestimarChamado() {
            var modalInstance = $uibModal.open({
                size: 'md',
                animation: true,
                templateUrl: 'reestimarChamado.html',
                controller: 'ReestimarChamadoController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'modal-grande',
                resolve: {
                    chamado: vm.chamado
                }
            });


            modalInstance.result.then(function (chamado) {
                vm.chamado = chamado;
                confirmSave();
            });
        }
    }

    angular
        .module('chamadosApp')
        .controller('ReestimarChamadoController', ReestimarChamadoController);
    ReestimarChamadoController.$inject = ['$uibModalInstance', 'chamado', 'Chamado'];

    function ReestimarChamadoController($uibModalInstance, chamado, Chamado) {
        var vm = this;
        vm.chamado = chamado;
        vm.calcularPlanoDesenvolvimento = calcularPlanoDesenvolvimento;
        vm.atualizarHoraEstimada = atualizarHoraEstimada;


        function calcularPlanoDesenvolvimento() {
            if (vm.chamado.complexidade && chamado.tempoEstimado)
                Chamado.calcularPlanoDesenvolvimento(vm.chamado, function (data) {
                    vm.chamado.planoDesenvolvimento = data;
                    var total = 0;
                    for (var i = 0; i < vm.chamado.planoDesenvolvimento.length; i++) {
                        var plano = vm.chamado.planoDesenvolvimento[i];
                        total = total + plano.tempoEstimado;
                    }
                });
        }

        function atualizarHoraEstimada() {
            var horas = 0;
            angular.forEach(vm.chamado.planoDesenvolvimento, function (plano) {
                horas = horas + plano.tempoEstimado;
            });

            vm.chamado.tempoEstimado = horas;
        }

        vm.ok = function () {
            vm.isSaving = true;
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoRejeicaoController', ChamadoRejeicaoController);
    ChamadoRejeicaoController.$inject = ['$uibModalInstance', 'comentario'];

    function ChamadoRejeicaoController($uibModalInstance, comentario) {
        var vm = this;
        vm.comentario = comentario;


        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Rejeição do Chamado:</strong><br/>" +
                "<strong>Ambiente em que o teste foi realizado: </strong><br/>" +
                "" + vm.ambiente + "<br/> <br/>" +
                "<strong>Motivo da Rejeição: <br/></strong>" +
                "" + (vm.motivoRejeicao === 'NAO_ATENDIDO' ? 'Um ou mais de um item solicitado originalmente não foi atendido.' : ' O solicitado originalmente foi atendido porém correções são necessárias para liberação.') + "<br/> <br/>" +
                (vm.motivoRejeicao === 'NAO_ATENDIDO' ? '<strong>Item ou os itens que não foram atendidos: </strong><br/> ' : '') +
                (vm.motivoRejeicao === 'NAO_ATENDIDO' ? vm.itemNaoAtendido + ' <br/> <br/>' : '') +
                "<strong>Como realizar o teste e o motivo da rejeição: </strong><br/>" +
                vm.descricaoRejeicao + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoTesteController', ChamadoTesteController);
    ChamadoTesteController.$inject = ['$uibModalInstance', 'comentario', 'Upload', 'Alerta'];

    function ChamadoTesteController($uibModalInstance, comentario, Upload, Alerta) {
        var vm = this;
        vm.comentario = comentario;
        vm.upload = upload;
        vm.removerAnexo = removerAnexo;
        vm.descricaoTeste = '';

        function upload($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.mimeType = $file.type;
                        vm.descricaoTeste = vm.descricaoTeste + " Anexado o arquivo - " + anexo.descricao + "\n";
                        vm.anexos.push(anexo);
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 10Mb");
            }
        }


        function removerAnexo(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.anexos.splice(index, 1);
                    }
                });
        }

        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Teste do Chamado:</strong><br/>" +
                "<strong>Ambiente em que o teste foi realizado: </strong><br/>" +
                "" + vm.ambiente + "<br/> <br/>" +
                "<strong>Descrição do teste: <br/></strong>" +
                vm.descricaoTeste + "<br/> <br/>" +
                "<strong>Houve atualização do manual? <br/></strong>" +
                vm.manual + "<br/> <br/>";
            vm.comentario.anexos = vm.anexos
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoValidarController', ChamadoValidarController);
    ChamadoValidarController.$inject = ['$uibModalInstance', 'chamado'];

    function ChamadoValidarController($uibModalInstance, chamado) {
        var vm = this;
        vm.chamado = chamado;

        vm.ok = function () {
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoImpedidoController', ChamadoImpedidoController);
    ChamadoImpedidoController.$inject = ['$uibModalInstance', 'comentario'];

    function ChamadoImpedidoController($uibModalInstance, comentario) {
        var vm = this;
        vm.comentario = comentario;

        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Chamado Impedido:</strong><br/>" + vm.motivoImpedido + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoReabertoController', ChamadoReabertoController);
    ChamadoReabertoController.$inject = ['$uibModalInstance', 'comentario', 'Upload'];

    function ChamadoReabertoController($uibModalInstance, comentario, Upload) {
        var vm = this;
        vm.comentario = comentario;
        vm.upload = upload;
        vm.removerAnexo = removerAnexo;

        function upload($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.mimeType = $file.type;
                        vm.descricaoTeste = vm.descricaoTeste + " Anexado o arquivo - " + anexo.descricao + "\n";
                        vm.anexos.push(anexo);
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 10Mb");
            }
        }


        function removerAnexo(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.anexos.splice(index, 1);
                    }
                });
        }


        vm.ok = function () {
            vm.comentario.anexos = vm.anexos
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Chamado Reaberto:</strong><br/>" + vm.motivoReabrir + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('AjustarChamadoController', AjustarChamadoController);
    AjustarChamadoController.$inject = ['$uibModalInstance', 'chamado', 'Sprint', 'Modulo', 'SubModulo', 'Sistema', 'Cliente', 'Chamado', '$state'];

    function AjustarChamadoController($uibModalInstance, chamado, Sprint, Modulo, SubModulo, Sistema, Cliente, Chamado, $state) {
        var vm = this;

        vm.chamado = chamado;
        vm.sprints = Sprint.query();
        vm.modulos = Modulo.queryByUser();
        vm.sistemas = Sistema.query;

        vm.tag = {
            cor: 'DC0F0F',
            descricao: '',
            chamado: [vm.chamado]
        };

        vm.options = {
            name: 'Cor',
            restrictToFormat: true,
            format: 'hex',
            case: 'upper',
        }

        vm.selecionou = function ($item, $model, $label) {
            vm.tag.descricao = $item.descricao;
            vm.tag.cor = $item.cor;
            vm.tag.id = $item.id;
        }

        vm.saiuDoCampo = function () {
            vm.tag.descricao = vm.filtroTag.descricao;
        }
        vm.search = function () {
            Chamado.getTags({chamado: '', descricao: vm.filtroTag}, function (data) {
                vm.tags = data;
            });
        }

        vm.carregarSubModulo = carregarSubModulo;
        vm.carregarSistemas = carregarSistemas;

        vm.salvarTag = function () {
            vm.tag.descricao = vm.tag.descricao == null ? vm.filtroTag : vm.tag.descricao;
            vm.response = Chamado.salvarTagChamado(vm.tag);
            $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
            $uibModalInstance.close(vm.chamado);
        }

        vm.ok = function () {
            vm.isSaving = true;
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function carregarSubModulo() {
            if (vm.chamado.modulo) {
                vm.submodulos = SubModulo.queryByModulo({id: vm.chamado.modulo.id});
            }
        }

        function carregarSistemas() {
            if (vm.chamado.cliente) {
                Sistema.getByCliente({clienteId: vm.chamado.cliente.id}, function (data) {
                    vm.sistemas = data;
                })
            } else {
                vm.sistemas = [];
            }
        }

        Cliente.getByUser({}, function (data) {
            vm.clientes = data;
        })


        carregarSubModulo();
        carregarSistemas();
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoAceiteController', ChamadoAceiteController);
    ChamadoAceiteController.$inject = ['$uibModalInstance', 'chamado', 'Cliente'];

    function ChamadoAceiteController($uibModalInstance, entity) {
        var vm = this;

        vm.chamado = entity;
        vm.clear = clear;
        vm.confirmAceite = confirmAceite;
        vm.renderPergunta = renderPergunta;
        vm.isRequiredPergunta = isRequiredPergunta;
        vm.minLenghtPergunta = minLenghtPergunta;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmAceite() {
            vm.chamado.conteudo += montarString();
            $uibModalInstance.close(vm.chamado);
        }

        function init() {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    vm.pergunta1 = 'Descreva o teste realizado:';
                    vm.pergunta2 = 'Descreva o resultado do teste: ';
                    vm.pergunta3 = 'Descreva qual o resultado esperado: ';
                    vm.pergunta4 = 'Qual o ambiente que o teste foi realizado (url, nome): ';
                    break;
                case 'MELHORIA':
                    vm.pergunta1 = 'Descreva o caso de uso que receberá a melhoria (caminho, menu, nome da tela): ';
                    vm.pergunta2 = 'Descreva como o caso de uso funciona atualmente:';
                    vm.pergunta3 = 'Descreva como o caso de uso deve funcionar após a melhoria ser implementada: ';
                    vm.pergunta4 = 'Como testar? Qual a resposta esperada?';
                    break;
                case 'NOVA_FUNCIONALIDADE':
                    vm.pergunta1 = 'Descreva como o caso de uso deve funcionar após ser implementado: ';
                    vm.pergunta2 = 'Descreva onde deverá ser implementado (menu, caminho ou nome da tela) :';
                    vm.pergunta3 = 'Como testar? Qual a resposta esperada?';
            }
        }

        function montarString() {
            switch (vm.chamado.tipoChamado) {
                case 'BUG' :
                case 'MELHORIA':
                    return "<p><strong>" + vm.pergunta1 + "</strong></p><p>" + (vm.resposta1 ? vm.resposta1 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta2 + "</strong></p><p>" + (vm.resposta2 ? vm.resposta2 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta3 + "</strong></p><p>" + (vm.resposta3 ? vm.resposta3 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta4 + "</strong></p><p>" + (vm.resposta4 ? vm.resposta4 : 'não informado') + "</p>";
                case 'NOVA_FUNCIONALIDADE':
                    return "<p><strong>" + vm.pergunta1 + "</strong></p><p>" + (vm.resposta1 ? vm.resposta1 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta2 + "</strong></p><p>" + (vm.resposta2 ? vm.resposta2 : 'não informado') + "</p>";
            }
        }

        function renderPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    return numero <= 4;
                case 'MELHORIA':
                    return numero <= 4;
                case 'NOVA_FUNCIONALIDADE':
                    return numero <= 3;
            }
        }

        function isRequiredPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    return true;
                case 'MELHORIA':
                    return numero !== 4;
                case 'NOVA_FUNCIONALIDADE':
                    return numero === 1;
            }
        }

        function minLenghtPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    if (numero === 4)
                        return 5;
                    break;
                case 'MELHORIA':
                    if (numero === 1 || numero === 2 || numero === 3)
                        return 10;
                    break;
                case 'NOVA_FUNCIONALIDADE':
                    if (numero === 1)
                        return 10;
                    break;
            }
            return 0;
        }

        init();
    }
})();
