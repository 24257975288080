(function () {
    'use strict';

    var template = '<table class="jh-table table table-striped table-hover">' +
        '<tbody>' +
        '<tr>' +
        '<th>Ordem</th>' +
        '<th class="issue-info">' +
        'Nº do chamado' +
        '<small>Titulo</small>' +
        '</th>' +
        '<th class="issue-info">' +
        'Solicitante' +
        '<small>Responsável</small>' +
        '</th>' +
        '<th>Data</th>' +
        '<th>Tempo</th>' +
        '<th>Módulo</th>' +
        '</tr>' +
        '<tr ng-repeat="chamado in chamados track by chamado.id">' +
        '<td>' +
        '{{chamado.ordem}}' +
        '</td>' +
        '<td class="issue-info" style="width: 30%">' +
        '<a ui-sref="edit-chamado({id:chamado.id})">' +
        '#{{chamado.id}} - ' +
        '<span class="text-{{getCorPorTipo(chamado.tipoChamado)}}"  data-translate="{{\'chamadosApp.TipoChamado.\' + chamado.tipoChamado}}">{{chamado.tipoChamado}}</span>' +
        '</a>' +
        '<small>' +
        '{{chamado.titulo}}' +
        '</small>' +
        '</td>' +
        '<td class="issue-info" style="width: 10%">' +
        '{{chamado.solicitante.firstName}}' +
        '<small>' +
        '{{chamado.responsavel.firstName}}' +
        '</small>' +
        '</td>' +
        '<td>{{chamado.criadoEm | date:\'mediumDate\'}}</td>' +
        '<td>' +
        '' +
        '<i ng-if="chamado.tempoEstimado" class="fa fa-clock-o"></i>' +
        '{{chamado.tempoEstimado}}' +
        '</td>' +
        '<td>' +
        '{{chamado.modulo.descricao}}' +
        '</td>' +
        '</tr>' +
        '</tbody>' +
        '</table>';


    angular
        .module('chamadosApp')
        .directive('listChamado', listChamado);

    function listChamado() {
        var directive = {
            template: template,
            restrict: 'E',
            scope: {
                chamados: '=value'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel) {

            scope.getCorPorTipo = function(tipo) {
                switch (tipo) {
                    case 'SUPORTE':
                        return 'warning';
                    case 'BUG':
                        return 'danger';
                    case 'MELHORIA':
                        return 'info';
                    case 'NOVA_FUNCIONALIDADE':
                        return 'success';
                    default:
                        return 'info';
                }
            }

            scope.getCorPorSituacao = function(tipo) {
                switch (tipo) {
                    case 'AGUARDANDO_TESTE':
                        return 'warning';
                    case 'IMPEDIDO':
                        return 'danger';
                    case 'ABERTO':
                        return 'info';
                    case 'EM_DESENVOLVIMENTO':
                        return 'success';
                    default:
                        return 'info';
                }
            }
        }
    }
})();
