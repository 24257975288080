(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AgrupadorDialogController', AgrupadorDialogController);

    AgrupadorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'previousState', 'entity', 'Principal', 'Cliente', 'Agrupador', 'User', 'Chamado'];

    function AgrupadorDialogController($timeout, $scope, $stateParams, $uibModalInstance, previousState, entity, Principal, Cliente, Agrupador, User, Chamado) {
        var vm = this;

        vm.previousState = previousState;
        vm.agrupador = entity;
        vm.clear = clear;
        vm.save = save;
        vm.carregarCliente = carregarCliente;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.alocar = alocar;
        vm.desalocar = desalocar;
        vm.getTamanhoBarraPorSituacao = getTamanhoBarraPorSituacao;
        vm.ocorrencia = {};

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        carregarCliente();

        function carregarCliente() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                if (vm.agrupador.cliente) {
                    for (var i = 0; i < vm.clientes.length; i++) {
                        if (vm.clientes[i].id === vm.agrupador.cliente.id) {
                            vm.agrupador.cliente = vm.clientes[i];
                            break;
                        }
                    }
                } else {
                    vm.agrupador.cliente = vm.clientes[0];
                }
            })
        }


        function clear() {
            $uibModalInstance.close(previousState);
        }

        function save() {
            vm.isSaving = true;
            if (vm.agrupador.id !== null) {
                Agrupador.update(vm.agrupador, onSaveSuccess, onSaveError);
            } else {
                Agrupador.save(vm.agrupador, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:agrupadorUpdate', result);
            $uibModalInstance.close(vm.previousState);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function alocar(chamado) {
            if (!vm.agrupador.chamados) {
                vm.agrupador.chamados = [];
            }
            Chamado.get({id: chamado}, function (data) {
                vm.agrupador.chamados.push(data);
                vm.adicionar = '';
            })

        }

        function desalocar(chamado) {
            vm.agrupador.chamados.splice(indexChamado(chamado.id), 1);
        }

        function agruparChamados(data) {
            vm.agrupador = data;
        }

        function indexChamado(id) {
            for (var i = 0; i < vm.agrupador.chamados.length; i++) {
                if (vm.agrupador.chamados[i].id === id) {
                    return i;
                }
            }
            return -1;
        }


        function getTamanhoBarraPorSituacao(situacao) {
            switch (situacao) {
                case 'ABERTO':
                    return '0%';
                case 'IMPEDIDO':
                    return '0%';
                case 'EM_SUPORTE':
                    return '10%';
                case 'EM_ANALISE':
                    return '20%';
                case 'TESTE_SUPORTE':
                    return '70%';
                case 'EM_DESENVOLVIMENTO':
                    return '60%';
                case 'AGUARDANDO_TESTE':
                    return '80%';
                case 'AGUARDANDO_VALIDACAO':
                    return '90%';
                case 'FECHADO':
                    return '100%';
                case 'REJEITADO':
                    return '0%';
                case 'VALIDADO':
                    return '100%';
                case 'AGUARDANDO_DESENVOLVIMENTO':
                    return '30%';
                default:
                    return '0%';
            }
        }
    }
})();
