(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosPorSolicitante', ChamadosPorSolicitante);

    ChamadosPorSolicitante.$inject = ['$resource'];

    function ChamadosPorSolicitante($resource) {
        var resourceUrl = 'api/chamados-por-solicitante';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            }

        });
    }


})();
