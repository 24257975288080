(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('acompanhamento-sprint', {
            parent: 'app',
            url: '/acompanhamento-sprint',
            data: {
                authorities: ['ROLE_ATENDENTE'],
            },
            ncyBreadcrumb: {
                label: 'Relatório de acompanhamento do Ciclo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/relatorios/acompanhamento-sprint/acompanhamento-sprint.html',
                    controller: 'AcompanhamentoSprintController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('chamado');
                    $translatePartialLoader.addPart('situacaoChamado');
                    $translatePartialLoader.addPart('tipoChamado');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
