(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AcumuloDemandasController', AcumuloDemandasController);

    AcumuloDemandasController.$inject = ['MovimentacaoDemandas', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', '$filter', 'Cliente'];

    function AcumuloDemandasController(MovimentacaoDemandas, DateUtils, Modulo, SubModulo, Sprint, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.diasSemMovimentar = 10;
        vm.orderBy = '-diasAtraso';
        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;


        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
                loadAll();
            });
        }

        function loadAll() {
            vm.hoje = new Date();

            vm.diasAtraso = 0;
            vm.atrasoPorModulo = {};


            MovimentacaoDemandas.query(vm.chamado, function (data) {
                if (vm.diasSemMovimentar) {
                    vm.chamados = [];
                    angular.forEach(data, function (chamado) {
                        if (chamado.diasAtraso > vm.diasSemMovimentar) {
                            vm.chamados.push(chamado);
                        }
                    });
                } else {
                    vm.chamados = data;
                }
                angular.forEach(vm.chamados, function (chamado) {
                    if (!vm.atrasoPorModulo [chamado.modulo]) {
                        vm.atrasoPorModulo [chamado.modulo] = {modulo: chamado.modulo, chamados: []};
                    }
                    vm.atrasoPorModulo [chamado.modulo].chamados.push(chamado);

                    chamado.inicio = DateUtils.convertDateTimeFromServer(chamado.inicio);
                    chamado.fim = DateUtils.convertDateTimeFromServer(chamado.fim);
                    chamado.entrega = DateUtils.convertDateTimeFromServer(chamado.entrega);

                    if (chamado.diasAtraso > 0) {
                        chamado.cor = "success";
                    }
                    if (chamado.diasAtraso > 5) {
                        chamado.cor = "info";
                    }
                    if (chamado.diasAtraso > 10) {
                        chamado.cor = "warning";
                    }
                    if (chamado.diasAtraso > 20) {
                        chamado.cor = "danger";
                    }

                    if (chamado.diasAtraso) {
                        vm.diasAtraso = vm.diasAtraso + chamado.diasAtraso;
                    }
                });
                vm.diasAtraso = vm.diasAtraso / vm.chamados.length;

                vm.modulosAtrasados = [];

                angular.forEach(vm.atrasoPorModulo, function (modulo) {
                    modulo.diasAtraso = 0;
                    angular.forEach(modulo.chamados, function (chamado) {
                        if (chamado.diasAtraso) {
                            modulo.diasAtraso = modulo.diasAtraso + chamado.diasAtraso;
                        }
                    });
                    modulo.diasAtraso = modulo.diasAtraso / modulo.chamados.length;
                    vm.modulosAtrasados.push(modulo);

                    modulo.atrasoPorSituacao = {};
                });

            });
        }
    }

})();


