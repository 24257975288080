(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Ata', Ata);

    Ata.$inject = ['$resource'];

    function Ata($resource) {
        var resourceUrl = 'api/ata/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getTempoGasto': {url: 'api/tempo-gasto-by-ata/:id', method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'getByNumero': {
                url: 'api/ata/por-numero/:numero',
                method: 'GET'
            },
            'update': {method: 'PUT'},
            'criarPlanejamento': {
                method: 'PUT',
                url: 'api/ata/criar-planejamento',
            },
            'criarEncerramento': {
                method: 'PUT',
                url: 'api/ata/criar-encerramento',
            },
            'criarReuniaoDiaria': {
                method: 'PUT',
                url: 'api/ata/criar-reuniaodiaria',
            }
        });
    }
})();
