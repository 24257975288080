(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AplicacaoDialogController', AplicacaoDialogController);

    AplicacaoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'Principal', 'Cliente', 'Aplicacao', 'User', 'Sistema', 'Cidade', 'Uf'];

    function AplicacaoDialogController($timeout, $scope, $stateParams, $uibModalInstance,
                                       entity, Principal, Cliente, Aplicacao, User, Sistema,
                                       Cidade, Uf) {
        var vm = this;

        vm.aplicacao = entity;
        vm.clear = clear;
        vm.save = save;
        vm.carregarCliente = carregarCliente;
        vm.carregarSistemas = carregarSistemas;
        vm.adicionarOcorrencia = adicionarOcorrencia;
        vm.removerOcorrencia = removerOcorrencia;
        vm.indexOcorrencia = indexOcorrencia;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.ocorrencia = {};
        vm.ufs = [];
        vm.loadUfs = loadUfs;
        vm.cidades = [];
        vm.loadCidades = loadCidades;

        $timeout(function () {
            vm.loadUfs();
            if (vm.aplicacao && vm.aplicacao.cidade) {
                vm.aplicacao.uf = vm.aplicacao.cidade.uf;
                vm.loadCidades();
            }
            angular.element('.form-group:eq(1)>input').focus();
        });

        carregarCliente();

        function carregarCliente() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                if (vm.aplicacao.id === null) {
                    vm.aplicacao.cliente = vm.clientes[0];
                }
                carregarSistemas();
            })
        }

        function carregarSistemas() {
            if (vm.aplicacao.cliente) {
                Sistema.getByCliente({clienteId: vm.aplicacao.cliente.id}, function (data) {
                    vm.sistemas = data;
                })
            } else {
                vm.sistemas = [];
            }
        }

        function adicionarOcorrencia() {
            if (vm.aplicacao.ocorrencias === undefined) {
                vm.aplicacao.ocorrencias = [];
            }
            vm.aplicacao.ocorrencias.push(vm.ocorrencia);
            vm.ocorrencia = {};
        }

        function indexOcorrencia(id) {
            for (var i = 0; i < vm.aplicacao.ocorrencias.length; i++) {
                if (vm.aplicacao.ocorrencias[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function removerOcorrencia(ocorrencia) {
            vm.aplicacao.ocorrencias.splice(vm.indexOcorrencia(ocorrencia.id), 1)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            console.log('aa ..:' + JSON.stringify(vm.aplicacao))
            vm.isSaving = true;
            if (vm.aplicacao.id !== null) {
                Aplicacao.update(vm.aplicacao, onSaveSuccess, onSaveError);
            } else {
                Aplicacao.save(vm.aplicacao, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:clienteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadUfs() {
            vm.ufs = [];
            Uf.findAll({}, function (data) {
                vm.ufs = data;
            });
        }

        function loadCidades() {
            vm.cidades = [];
            if (vm.aplicacao.uf) {
                Cidade.findByUf({idUf: vm.aplicacao.uf.id}, function (data) {
                    vm.cidades = data;
                });
            }
        }

    }
})();
