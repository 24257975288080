(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('SolicitacaoDesenvolvimento', SolicitacaoDesenvolvimento);

    SolicitacaoDesenvolvimento.$inject = ['$resource', '$uibModal', '$state', 'DateUtils'];

    function SolicitacaoDesenvolvimento($resource, $uibModal, $state, DateUtils) {
        var resourceUrl = 'api/solicitacaoDesenvolvimento/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getBranches': {url: 'api/solicitacao-desenvolvimento-branches/:id', method: 'GET', isArray: true},
            'getByNumero': {url: 'api/solicitacao-desenvolvimento-por-numero/:numero', method: 'GET', isArray: false},
            'getBranchesByName': {url: 'api/branch-por-nome', method: 'GET', isArray: true},
            'getAllConcluidasSprint': {url: 'api/total-concluidos-sprint-atual', method: 'POST', isArray: true},
            'getAllRejeitadasSprint': {url: 'api/total-rejeicoes-sprint-atual', method: 'POST', isArray: true},
            'getGitRepositories': {url: 'api/git-repositories', method: 'GET', isArray: true},
            'getBySprint': {url: 'api/solicitacao-desenvolvimento-por-sprint/:id', method: 'GET', isArray: true},
            'getBySprintAndUsuario': {
                url: 'api/solicitacao-desenvolvimento-por-sprint-usuario',
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'atribuir': {url: 'api/atribuir-tarefa', method: 'PUT'},
            'dasatribuir': {url: 'api/dasatribuir-tarefa', method: 'PUT'},
            'sincronizarGit': {url: 'api/sincronizar-git/:id', method: 'GET'},
            'alocar': {url: 'api/alocar-tarefa', method: 'PUT'},
            'avancar': {url: 'api/avancar-tarefa', method: 'PUT'},
            'criarBranch': {url: 'api/criar-branch', method: 'PUT'},
            'importarRedmine': {url: 'api/copiar-tarefas-redmine-produto', method: 'PUT'},
            'update': {method: 'PUT'},
            'getTempoGastoByTicket': {
                url: 'api/tempo-gasto-by-ticket/:id',
                method: 'GET',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'getAllEmDesenvolvimento': {
                url: 'api/solicitacao-desenvolvimento-em-desenvolvimento',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            var sol = data[i];
                            if (sol.concluidaEm) {
                                sol.concluidaEm = DateUtils.convertDateTimeFromServer(sol.concluidaEm);
                            }
                        }
                    }
                    return data;
                }
            },
            'getAllEmDesenvolvimentoPorUsuario': {
                url: 'api/solicitacao-desenvolvimento-do-usuario/:idUsuario',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            var sol = data[i];
                            if (sol.concluidaEm) {
                                sol.concluidaEm = DateUtils.convertDateTimeFromServer(sol.concluidaEm);
                            }
                        }
                    }
                    return data;
                }
            },
            'getStatusBySolicitacao': {
                url: 'api/historico-desenvolvimento',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            var sol = data[i];
                            if (sol.inicio) {
                                sol.inicio = DateUtils.convertDateTimeFromServer(sol.inicio);
                            }
                            if (sol.fim) {
                                sol.fim = DateUtils.convertDateTimeFromServer(sol.fim);
                            }
                        }
                    }
                    return data;
                }
            },
            'getComentarios': {
                method: 'GET',
                url: 'api/comentarios-da-solicitacao/:id',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertDateTimeFromServer(data.criadoEm);
                    }
                    return data;
                }
            },
            'getSituacoePossiveis': {
                method: 'GET',
                url: 'api/solicitacao-situacoes-possiveis/:situacao',
                isArray: true
            },
            'comentarSolicitacao': {
                method: 'POST',
                url: 'api/comentar-solicitacao-desenvolvimento'
            }
        });

        return service;
    }
})();
