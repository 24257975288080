(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SistemaDialogController', SistemaDialogController);

    SistemaDialogController.$inject = ['$timeout', '$scope', 'Modulo', '$uibModalInstance', 'entity', 'Sistema', 'User', 'Cliente'];

    function SistemaDialogController($timeout, $scope, Modulo, $uibModalInstance, entity, Sistema, User, Cliente) {
        var vm = this;

        vm.sistema = entity;
        vm.clear = clear;
        vm.save = save;
        vm.indexModulo = indexModulo;
        vm.adicionarModulo = adicionarModulo;
        vm.removerModulo = removerModulo;
        vm.modulos = Modulo.query({page: 0, size: 99});

        if (!vm.sistema.modulos) {
            vm.sistema.modulos = [];
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.sistema.id !== null) {
                Sistema.update(vm.sistema, onSaveSuccess, onSaveError);
            } else {
                Sistema.save(vm.sistema, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:sistemaUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function indexModulo(id) {
            for (var i = 0; i < vm.sistema.modulos.length; i++) {
                if (vm.sistema.modulos[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function adicionarModulo(modulo) {
            vm.sistema.modulos.push(modulo);
            vm.subModulos = [];
        }

        function removerModulo(modulo) {
            vm.sistema.modulos.splice(vm.indexModulo(modulo.id), 1)

        }

    }
})();
