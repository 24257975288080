(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosPorVolumetria', ChamadosPorVolumetria);

    ChamadosPorVolumetria.$inject = ['$resource'];

    function ChamadosPorVolumetria($resource) {
        var resourceUrl = 'api/chamados-por-volumetria';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: false
            }

        });
    }


})();
