(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ReleaseController', ReleaseController);

    ReleaseController.$inject = ['AlertService', 'pagingParams', 'ParseLinks', 'paginationConstants', '$state',
        '$filter', 'Chamado', 'Release', 'SweetAlert', 'Sistema', 'Agrupador'];

    function ReleaseController(AlertService, pagingParams, ParseLinks, paginationConstants, $state,
                               $filter, Chamado, Release, SweetAlert, Sistema, Agrupador) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = true;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.alocar = alocar;
        vm.alocarTodos = alocarTodos;

        vm.desalocar = desalocar;
        vm.desalocarTodos = desalocarTodos;
        vm.carregarSistema = carregarSistema;
        vm.selecionarSistema = selecionarSistema;
        vm.solicitar = solicitar;
        vm.release = {};


        function agruparChamados() {
            vm.chamadosPorSistema = {};
            Chamado.queryValidadosSemRelease({}, function (chamados) {
                for (var i = 0; i < vm.sistemas.length; i++) {
                    var sistema = vm.sistemas[i];
                    sistema.bugs = 0;
                    sistema.melhorias = 0;
                    sistema.funcionalidades = 0;
                    sistema.suporte = 0;
                    vm.chamadosPorSistema[sistema.id] = {sistema: sistema, chamados: []};
                    for (var j = 0; j < chamados.length; j++) {
                        var chamado = chamados[j];
                        if (chamado.sistema.id == sistema.id) {
                            if (!vm.chamadosPorSistema[chamado.sistema.id]) {
                                vm.chamadosPorSistema[chamado.sistema.id] = {sistema: chamado.sistema, chamados: []};
                            }
                            switch (chamado.tipoChamado) {
                                case 'BUG':
                                    sistema.bugs++;
                                    break;
                                case 'MELHORIA':
                                    sistema.melhorias++;
                                    break;
                                case 'NOVA_FUNCIONALIDADE':
                                    sistema.funcionalidades++;
                                    break;
                                case 'SUPORTE':
                                    sistema.suporte++;
                                    break;
                            }
                            vm.chamadosPorSistema[chamado.sistema.id].chamados.push(chamado);
                        }
                    }
                }

                selecionarSistema(vm.sistemas[0]);
            });
        }

        function alocarTodos() {
            selecionarSistema(vm.sistema);
        }

        function alocar(chamado) {
            if(chamado.numeroAgrupadores >0){
                    SweetAlert.swal({
                        title: "Chamado agrupado!",
                        text: "todos os chamados do agrupador <b>"+chamado.agrupadores[0].nome+"</b> devem ser validados para adiciona-los em uma release",
                        type: "error",
                        html: true
                    });
            }
            if (chamado.numeroAgrupadores ===0 && vm.release.chamados.indexOf(chamado) < 0) {
                vm.release.chamados.push(chamado);
            }
        }

        function desalocar(remover) {
            var index = -1;
            angular.forEach(vm.release.chamados, function (chamado, ind) {
                if (chamado.id === remover.id) {
                    index = ind;
                }
            });

            if (index >= 0) {
                vm.release.chamados.splice(index, 1);
            }
        }

        function desalocarTodos() {
            vm.release.chamados = [];
        }

        loadAll();

        function selecionarSistema(sistema) {
            vm.sistema = sistema;

            vm.release = {detalhesRelease: [], chamados: [], sistema: sistema};
            vm.release.detalhesRelease.push({
                quantidade: vm.chamadosPorSistema[sistema.id].chamados.length,
                texto: "Chamados"
            });
            var chamadoTipo = {};
            angular.forEach(vm.chamadosPorSistema[sistema.id].chamados, function (chamado) {
                chamado.numeroAgrupadores = 0
                Agrupador.getBychamado({id: chamado.id}, function (agrupadores) {
                    chamado.agrupadores = agrupadores;
                    chamado.numeroAgrupadores = agrupadores.length;
                    if (!chamadoTipo[chamado.tipoChamado]) {
                        chamadoTipo[chamado.tipoChamado] = [];
                    }
                    chamadoTipo[chamado.tipoChamado].push(chamado);
                    if (chamado.agrupadores.length ===0) {
                        vm.release.chamados.push(chamado);
                    }
                });
            });

            angular.forEach(chamadoTipo, function (chamados, tipo) {
                vm.release.detalhesRelease.push({
                    quantidade: chamados.length,
                    texto: $filter('translate')('chamadosApp.TipoChamado.' + tipo)
                });
            });

        }


        function carregarSistema() {
            Sistema.getByUser({}, function (data) {
                vm.sistemas = data;

                agruparChamados();
            })
        }

        function loadAll() {
            carregarSistema();
            carregarReleases();
        }

        function carregarReleases() {
            Release.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + ('desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.releases = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        function solicitar() {

            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja solicitar uma nova release? Chamados com agrupadores não finalizados serao removidos da release",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.solicitados = [];
                        vm.release.situacao = 'ABERTO';
                        var modulos = [];
                        var chamadoModulo = {};
                        for (var i = 0; i < vm.release.chamados.length; i++) {
                            var chamado = vm.release.chamados[i];
                            if (chamado.agrupadores.length == 0) {
                                if (!chamadoModulo[chamado.modulo.id]) {
                                    chamadoModulo[chamado.modulo.id] = [];
                                    modulos.push(chamado.modulo);
                                }
                                chamadoModulo[chamado.modulo.id].push(chamado);
                            }
                        }

                        vm.release.notas = "";
                        for (var i = 0; i < modulos.length; i++) {
                            var modulo = modulos[i];
                            vm.release.notas += "&nbsp; " + modulo.descricao + " <br>";
                            var sorted = chamadoModulo[modulo.id];
                            for (var j = 0; j < sorted.length; j++) {
                                var chamado = sorted[j];
                                vm.release.notas += "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " + chamado.subModulo.descricao + " <small><i>(" + $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado) + ")</i></small>: "
                                    + "#" + chamado.id + "&nbsp;-&nbsp;"
                                    + (chamado.releaseNotes ? chamado.releaseNotes : chamado.titulo) + "<br>";

                            }
                            vm.release.notas += "<br>";
                        }

                        Release.save(vm.release, function (data) {
                            vm.release.totalChamados = 0;
                            for (var i = 0; i < vm.release.chamados.length; i++) {
                                var chamado = vm.release.chamados[i];
                                if (chamado.agrupadores.length == 0) {
                                    vm.release.totalChamados++;
                                    chamado.release = data;
                                    Chamado.update(chamado, function (chamado) {
                                        aposSolicitar(vm.release, chamado);
                                    });
                                }
                            }
                        }, function (error) {
                            setTimeout(function () {
                                SweetAlert.swal({
                                    title: "Atenção",
                                    text: error.data.message,
                                    type: "error",
                                    html: true
                                });
                            }, 1000);
                        });
                    }
                });
        }

        function aposSolicitar(release, chamado) {
            vm.solicitados.push(chamado);
            if (release.totalChamados === vm.solicitados.length) {
                $state.reload(true);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }

})();


