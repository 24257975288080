(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadoDesenvolvimentoController', ChamadoDesenvolvimentoController);
    ChamadoDesenvolvimentoController.$inject = ['chamado', 'Chamado', '$state', 'Upload', 'AnexoChamado', 'SweetAlert'];

    function ChamadoDesenvolvimentoController(chamado, Chamado, $state, Upload, AnexoChamado, SweetAlert) {
        var vm = this;
        vm.chamado = chamado;
        vm.chamado.alteracaoCodigo = true;
        vm.solicitacaoDesenvolvimento = {
            chamado: chamado,
            conteudo: chamado.conteudo,
            titulo: chamado.titulo
        };
        vm.chamado.complexidade = 'BAIXA';
        vm.chamado.releaseNotes = null;
        vm.calcularPlanoDesenvolvimento = calcularPlanoDesenvolvimento;
        vm.atualizarHoraEstimada = atualizarHoraEstimada;
        vm.conferirTempoApontadoComMedia = conferirTempoApontadoComMedia;
        vm.buscarHorasDoModuloAndSubmodulo = buscarHorasDoModuloAndSubmodulo;

        function buscarHorasDoModuloAndSubmodulo() {
            if (!vm.horasPorModulo || vm.horasPorModulo.length === 0) {
                Chamado.getTempoPorModuloSubModulo(vm.chamado, function (data) {
                    vm.horasPorModulo = data;
                    calcularPlanoDesenvolvimento();
                })
            }
        }

        buscarHorasDoModuloAndSubmodulo();

        function calcularPlanoDesenvolvimento() {
            vm.tempoTarefa = 0;
            if (vm.chamado.complexidade && chamado.tempoEstimado) {
                Chamado.calcularPlanoDesenvolvimento(vm.chamado, function (data) {
                    vm.chamado.planoDesenvolvimento = data;
                    var total = 0;
                    for (var i = 0; i < vm.chamado.planoDesenvolvimento.length; i++) {
                        var plano = vm.chamado.planoDesenvolvimento[i];
                        total = total + plano.tempoEstimado;

                        if (plano.tipoHora === 'DESENVOLVIMENTO' ||
                            plano.tipoHora === 'REVISAO') {
                            vm.tempoTarefa = vm.tempoTarefa + plano.tempoEstimado;
                        }

                    }
                });
                vm.conferirTempoApontadoComMedia();
            }
        }

        function atualizarHoraEstimada() {
            var horas = 0;
            angular.forEach(vm.chamado.planoDesenvolvimento, function (plano) {
                horas = horas + plano.tempoEstimado;
            });

            vm.chamado.tempoEstimado = horas;
        }


        function conferirTempoApontadoComMedia() {
            vm.tempoEstimadoMenorQueMedia = false;
            for (var i = 0; i < vm.horasPorModulo.length; i++) {
                var media = vm.horasPorModulo[i];
                media.escolhida = false;
                if (media.tipoChamado === vm.chamado.tipoChamado
                    && media.complexidade === vm.chamado.complexidade
                    && vm.chamado.tempoEstimado < media.tempoGasto) {
                    vm.tempoEstimadoMenorQueMedia = true;
                    media.escolhida = true;
                    vm.verHorasModulo = true;
                }
            }
        }

        vm.ok = function () {
            vm.isSaving = true;
            vm.chamado.situacao = 'EM_ANALISE_DESENVOLVIMENTO';
            if (vm.criterio)
                vm.chamado.criterio = vm.criterio;
            Chamado.update(vm.chamado, function (chamado) {
                vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
                vm.tempoGasto.criadoEm = new Date();
                vm.tempoGasto.numero = 23;
                vm.tempoGasto.conteudo = 'Análise';
                vm.tempoGasto.tempo = vm.horaAnalise;
                Chamado.defirnirTempo(vm.tempoGasto);
                $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
            }, function (error) {
            });

        };

        vm.cancel = function () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
        };

        vm.removerAnexo = function (index, anexos) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var anexo = anexos[index];
                        if (anexo.id) {
                            AnexoChamado.delete({id: anexo.id});
                        }
                        anexos.splice(index, 1);
                    }
                });

        }

        vm.upload = function ($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.tipo = 'INTERNO';
                        anexo.mimeType = $file.type;
                        if (vm.chamado.id) {
                            anexo.chamado = vm.chamado;
                            AnexoChamado.save(anexo, function (data) {
                                vm.anexosInternos.push(data);
                            });

                        } else {
                            vm.anexosInternos.push(anexo);
                        }
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 100Mb");
            }
        }

        function carregarAnexos() {
            vm.anexos = [];
            vm.anexosInternos = [];
            vm.chamado.id ? AnexoChamado.query({id: vm.chamado.id}, function (data) {
                for (var i = 0; i < data.length; i++) {
                    var anexo = data[i];
                    if (anexo.tipo === 'PUBLICO') {
                        vm.anexos.push(anexo);
                    } else {
                        vm.anexosInternos.push(anexo);
                    }
                }
            }) : [];
        }

        carregarAnexos();
    };

    angular
        .module('chamadosApp')
        .controller('ReestimarChamadoController', ReestimarChamadoController);
    ReestimarChamadoController.$inject = ['$uibModalInstance', 'chamado', 'Chamado'];

    function ReestimarChamadoController($uibModalInstance, chamado, Chamado) {
        var vm = this;
        vm.chamado = chamado;
        vm.calcularPlanoDesenvolvimento = calcularPlanoDesenvolvimento;
        vm.atualizarHoraEstimada = atualizarHoraEstimada;


        function calcularPlanoDesenvolvimento() {
            if (vm.chamado.complexidade && chamado.tempoEstimado)
                Chamado.calcularPlanoDesenvolvimento(vm.chamado, function (data) {
                    vm.chamado.planoDesenvolvimento = data;
                    var total = 0;
                    for (var i = 0; i < vm.chamado.planoDesenvolvimento.length; i++) {
                        var plano = vm.chamado.planoDesenvolvimento[i];
                        total = total + plano.tempoEstimado;
                    }
                });
        }

        function atualizarHoraEstimada() {
            var horas = 0;
            angular.forEach(vm.chamado.planoDesenvolvimento, function (plano) {
                horas = horas + plano.tempoEstimado;
            });

            vm.chamado.tempoEstimado = horas;
        }

        vm.ok = function () {
            vm.isSaving = true;
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoRejeicaoController', ChamadoRejeicaoController);
    ChamadoRejeicaoController.$inject = ['$uibModalInstance', 'comentario'];

    function ChamadoRejeicaoController($uibModalInstance, comentario) {
        var vm = this;
        vm.comentario = comentario;


        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Rejeição do Chamado:</strong><br/>" +
                "<strong>Ambiente em que o teste foi realizado: </strong><br/>" +
                "" + vm.ambiente + "<br/> <br/>" +
                "<strong>Motivo da Rejeição: <br/></strong>" +
                "" + (vm.motivoRejeicao === 'NAO_ATENDIDO' ? 'Um ou mais de um item solicitado originalmente não foi atendido.' : ' O solicitado originalmente foi atendido porém correções são necessárias para liberação.') + "<br/> <br/>" +
                (vm.motivoRejeicao === 'NAO_ATENDIDO' ? '<strong>Item ou os itens que não foram atendidos: </strong><br/> ' : '') +
                (vm.motivoRejeicao === 'NAO_ATENDIDO' ? vm.itemNaoAtendido + ' <br/> <br/>' : '') +
                "<strong>Como realizar o teste e o motivo da rejeição: </strong><br/>" +
                vm.descricaoRejeicao + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoTesteController', ChamadoTesteController);
    ChamadoTesteController.$inject = ['$uibModalInstance', 'comentario', 'Upload', 'Alerta'];

    function ChamadoTesteController($uibModalInstance, comentario, Upload, Alerta) {
        var vm = this;
        vm.comentario = comentario;
        vm.upload = upload;
        vm.removerAnexo = removerAnexo;
        vm.descricaoTeste = '';

        function upload($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.mimeType = $file.type;
                        vm.descricaoTeste = vm.descricaoTeste + " Anexado o arquivo - " + anexo.descricao + "\n";
                        vm.anexos.push(anexo);
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 10Mb");
            }
        }


        function removerAnexo(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.anexos.splice(index, 1);
                    }
                });
        }

        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Teste do Chamado:</strong><br/>" +
                "<strong>Ambiente em que o teste foi realizado: </strong><br/>" +
                "" + vm.ambiente + "<br/> <br/>" +
                "<strong>Descrição do teste: <br/></strong>" +
                vm.descricaoTeste + "<br/> <br/>" +
                "<strong>Houve atualização do manual? <br/></strong>" +
                vm.manual + "<br/> <br/>";
            vm.comentario.anexos = vm.anexos
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoImpedidoController', ChamadoImpedidoController);
    ChamadoImpedidoController.$inject = ['$uibModalInstance', 'comentario'];

    function ChamadoImpedidoController($uibModalInstance, comentario) {
        var vm = this;
        vm.comentario = comentario;

        vm.ok = function () {
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Chamado Impedido:</strong><br/>" + vm.motivoImpedido + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoReabertoController', ChamadoReabertoController);
    ChamadoReabertoController.$inject = ['$uibModalInstance', 'comentario', 'Upload'];

    function ChamadoReabertoController($uibModalInstance, comentario, Upload) {
        var vm = this;
        vm.comentario = comentario;
        vm.upload = upload;
        vm.removerAnexo = removerAnexo;

        function upload($file) {
            if ($file) {
                if (!vm.anexos) {
                    vm.anexos = [];
                }
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        var anexo = {};
                        anexo.descricao = $file.name;
                        anexo.conteudo = conteudo;
                        anexo.mimeType = $file.type;
                        vm.descricaoTeste = vm.descricaoTeste + " Anexado o arquivo - " + anexo.descricao + "\n";
                        vm.anexos.push(anexo);
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 10Mb");
            }
        }


        function removerAnexo(index) {
            SweetAlert.swal({
                    title: "Atenção",
                    text: "Tem certeza que deseja remover esse anexo?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55", confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        vm.anexos.splice(index, 1);
                    }
                });
        }


        vm.ok = function () {
            vm.comentario.anexos = vm.anexos
            vm.isSaving = true;
            vm.comentario.descricao = "<strong>Chamado Reaberto:</strong><br/>" + vm.motivoReabrir + "<br/> <br/>";
            $uibModalInstance.close(vm.comentario);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

    angular
        .module('chamadosApp')
        .controller('AjustarChamadoController', AjustarChamadoController);
    AjustarChamadoController.$inject = ['$uibModalInstance', 'chamado', 'Sprint', 'Modulo', 'SubModulo', 'Sistema', 'Cliente'];

    function AjustarChamadoController($uibModalInstance, chamado, Sprint, Modulo, SubModulo, Sistema, Cliente) {
        var vm = this;

        vm.chamado = chamado;
        vm.sprints = Sprint.query();
        vm.modulos = Modulo.queryByUser();
        vm.sistemas = Sistema.query

        vm.carregarSubModulo = carregarSubModulo;
        vm.carregarSistemas = carregarSistemas;

        vm.ok = function () {
            vm.isSaving = true;
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        function carregarSubModulo() {
            if (vm.chamado.modulo) {
                vm.submodulos = SubModulo.queryByModulo({id: vm.chamado.modulo.id});
            }
        }

        function carregarSistemas() {
            if (vm.chamado.cliente) {
                Sistema.getByCliente({clienteId: vm.chamado.cliente.id}, function (data) {
                    vm.sistemas = data;
                })
            } else {
                vm.sistemas = [];
            }
        }

        Cliente.getByUser({}, function (data) {
            vm.clientes = data;
        })


        carregarSubModulo();
        carregarSistemas();
    };

    angular
        .module('chamadosApp')
        .controller('ChamadoAceiteController', ChamadoAceiteController);
    ChamadoAceiteController.$inject = ['$uibModalInstance', 'chamado', 'Cliente'];

    function ChamadoAceiteController($uibModalInstance, entity) {
        var vm = this;

        vm.chamado = entity;
        vm.clear = clear;
        vm.confirmAceite = confirmAceite;
        vm.renderPergunta = renderPergunta;
        vm.isRequiredPergunta = isRequiredPergunta;
        vm.minLenghtPergunta = minLenghtPergunta;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmAceite() {
            vm.chamado.conteudo += montarString();
            $uibModalInstance.close(vm.chamado);
        }

        function init() {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    vm.pergunta1 = 'O que você espera que a funcionalidade faça? ';
                    vm.pergunta2 = 'O que ele está fazendo? ';
                    vm.pergunta3 = 'O caminho do erro (a URL inteira, tela) ';
                    vm.pergunta4 = 'Qual a base de teste, aplicação? ';
                    break;
                case 'MELHORIA':
                    vm.pergunta1 = 'O que você espera que a funcionalidade faça? ';
                    vm.pergunta2 = 'Como essa melhoria ira te ajudar? ';
                    vm.pergunta3 = 'Em qual menu está melhoria devera fica? ';
                    vm.pergunta4 = 'Há fundamentação legal? Se sim, informar ';
                    break;
                case 'NOVA_FUNCIONALIDADE':
                    vm.pergunta1 = 'Qual o caminho no menu para chegar na nova funcionalidade? ';
                    vm.pergunta2 = 'Há fundamentação legal? Se sim, informar ';
            }
        }

        function montarString() {
            switch (vm.chamado.tipoChamado) {
                case 'BUG' :
                case 'MELHORIA':
                    return "<p><strong>" + vm.pergunta1 + "</strong></p><p>" + (vm.resposta1 ? vm.resposta1 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta2 + "</strong></p><p>" + (vm.resposta2 ? vm.resposta2 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta3 + "</strong></p><p>" + (vm.resposta3 ? vm.resposta3 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta4 + "</strong></p><p>" + (vm.resposta4 ? vm.resposta4 : 'não informado') + "</p>";
                case 'NOVA_FUNCIONALIDADE':
                    return "<p><strong>" + vm.pergunta1 + "</strong></p><p>" + (vm.resposta1 ? vm.resposta1 : 'não informado') + "</p>" +
                        "<p><strong>" + vm.pergunta2 + "</strong></p><p>" + (vm.resposta2 ? vm.resposta2 : 'não informado') + "</p>";
            }
        }

        function renderPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    return numero <= 4;
                case 'MELHORIA':
                    return numero <= 4;
                case 'NOVA_FUNCIONALIDADE':
                    return numero <= 2;
            }
        }

        function isRequiredPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    return true;
                case 'MELHORIA':
                    return numero !== 4;
                case 'NOVA_FUNCIONALIDADE':
                    return numero === 1;
            }
        }

        function minLenghtPergunta(numero) {
            switch (vm.chamado.tipoChamado) {
                case 'BUG':
                    if (numero === 4)
                        return 5;
                case 'MELHORIA':
                    if (numero === 1 || numero === 2)
                        return 50;
                    if (numero === 3)
                        return 10;
                    break;
                case 'NOVA_FUNCIONALIDADE':
                    if (numero === 1)
                        return 10;
                    break;
            }
            return 0;
        }


        init();
    }
})();
