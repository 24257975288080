(function() {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('UserManagementResetController', UserManagementResetController);

    UserManagementResetController.$inject = ['$uibModalInstance', 'entity', 'User'];

    function UserManagementResetController ($uibModalInstance, entity, User) {
        var vm = this;

        vm.user = entity;
        vm.clear = clear;
        vm.confirmReset = confirmReset;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmReset (login) {
            User.reset({login: login},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
