(function () {
    'use strict';


    angular
        .module('chamadosApp')
        .controller('ChamadoController', ChamadoController);

    ChamadoController.$inject = ['$scope', 'Principal', '$state',
        'Chamado', 'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', 'Modulo', 'SubModulo', 'Sprint', '$filter', 'localStorageService', 'Cliente', 'Solicitante'];

    function ChamadoController($scope, Principal, $state,
                               Chamado, ParseLinks, AlertService, paginationConstants,
                               pagingParams, Modulo, SubModulo, Sprint, $filter, localStorageService, Cliente,
                               Solicitante) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.register = register;
        vm.editarChamado = editarChamado;
        vm.loadPage = loadPage;
        vm.sincronizarArquivos = sincronizarArquivos;
        vm.migrarDoRedmineByQueryId = migrarDoRedmineByQueryId;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selecionarCliente = selecionarCliente;
        vm.carregarSolicitantes = carregarSolicitantes;
        vm.loadAll = loadAll;
        vm.limpar = limpar;
        vm.getCorPorTipo = getCorPorTipo;
        vm.filtrarApenasPorModulo = filtrarApenasPorModulo;
        vm.filtrarApenasPorTipoChamado = filtrarApenasPorTipoChamado;
        vm.imprimir = imprimir;
        vm.tagdescricao = '';
        vm.tags = [];


        Chamado.getTags({chamado: '', descricao: vm.filtroTag}, function (data) {
            vm.tags = data;
        });

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};

        function carregarClientes() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                contarPorFiltro(vm.chamado, loadAll);
            });
        }

        function contarPorFiltro(filtro, callback) {
            Chamado.contarByFiltro(filtro, function (data) {
                vm.chamadosContados = data;
                vm.clientesChamado = {};
                vm.tiposChamado = {};
                vm.modulosChamado = {};
                for (var j = 0; j < vm.chamadosContados.length; j++) {
                    if (!vm.tiposChamado[vm.chamadosContados[j].tipoChamado]) {
                        vm.tiposChamado[vm.chamadosContados[j].tipoChamado] = {
                            quantidade: 0,
                            nome: vm.chamadosContados[j].tipoChamado
                        };
                    }
                    vm.tiposChamado[vm.chamadosContados[j].tipoChamado].quantidade += vm.chamadosContados[j].quantidade;

                    if (!vm.modulosChamado[vm.chamadosContados[j].modulo]) {
                        vm.modulosChamado[vm.chamadosContados[j].modulo] = {
                            quantidade: 0,
                            nome: vm.chamadosContados[j].modulo,
                            id: vm.chamadosContados[j].moduloId
                        };
                    }
                    vm.modulosChamado[vm.chamadosContados[j].modulo].quantidade += vm.chamadosContados[j].quantidade;

                    if (!vm.clientesChamado[vm.chamadosContados[j].cliente]) {
                        vm.clientesChamado[vm.chamadosContados[j].cliente] = {
                            quantidade: 0,
                            nome: vm.chamadosContados[j].cliente,
                            id: vm.chamadosContados[j].clienteId
                        };
                    }
                    vm.clientesChamado[vm.chamadosContados[j].cliente].quantidade += vm.chamadosContados[j].quantidade;

                }
                callback();
            })
        }

        function filtrarApenasPorTipoChamado(tipoChamado) {
            vm.chamado.tipoChamado = vm.chamado.tipoChamado && vm.chamado.tipoChamado == tipoChamado.nome ? null : tipoChamado.nome;
            transition();
        }

        function filtrarApenasPorModulo(modulo) {
            vm.chamado.modulo = vm.chamado.modulo && vm.chamado.modulo.id == modulo.id ? null : {id: modulo.id};
            transition();
        }

        function selecionarCliente(cliente) {
            vm.chamado.cliente = vm.chamado.cliente && vm.chamado.cliente.id == cliente.id ? null : cliente;
            transition();
        }

        function carregarSolicitantes() {
            if (vm.chamado.cliente) {
                Solicitante.getByCliente({clienteId: vm.chamado.cliente.id}, function (data) {
                    vm.solicitantes = data;
                })
            } else {
                vm.solicitantes = [];
            }
        }

        function login() {
            $state.go('login');
        }

        function limpar() {
            vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
            loadAll();
        }

        getAccount();


        function migrarDoRedmineByQueryId() {
            if (vm.queryId && vm.projectId) {
                Chamado.migrarRedmineByQuery({queryId: vm.queryId, projectId: vm.projectId});
            }
        }

        function isPesquisandoChamado() {
            return vm.chamado &&
                (vm.chamado.id
                    || vm.chamado.modulo
                    || vm.chamado.tipoChamado
                    || vm.chamado.titulo
                    || vm.chamado.sprint
                    || vm.chamado.subModulo
                    || vm.chamado.situacao);
        }

        function loadAll() {
            vm.chamado.size = vm.itemsPerPage;
            vm.chamado.sort = sort();
            vm.chamado.page = pagingParams.page - 1;
            vm.chamado.tag = vm.tagdescricao.descricao;
            Chamado.queryByFiltro(vm.chamado, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'ordem') {
                    result.push('ordem');
                }
                return result;
            }

            function onSuccess(data, headers) {
                localStorageService.set("chamado", vm.chamado);
                vm.filasProntas = [];
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.chamados = data;
                if (pagingParams.search && vm.chamados.length === 1) {
                    $state.go("edit-chamado", {id: vm.chamados[0].id, sitaucao: null, search: null});
                }

                angular.forEach(vm.chamados, function (chamado) {
                    if (vm.filasProntas.indexOf(chamado.situacao) < 0) {
                        vm.filasProntas.push(chamado.situacao);
                    }
                });

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            var params = {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                situacao: vm.situacao,
                clienteId: vm.chamado.cliente ? vm.chamado.cliente.id : null,
                moduloId: vm.chamado.modulo ? vm.chamado.modulo.id : null,
                tipoChamado: vm.chamado.tipoChamado ? vm.chamado.tipoChamado : null
            };
            $state.transitionTo($state.$current, params);
        }


        function carregarCombos(account) {
            vm.situacoes = account.filas;
            vm.modulos = Modulo.query({page: 0, size: 99});
            vm.subModulos = SubModulo.query({page: 0, size: 99});
            vm.sprints = Sprint.query({page: 0, size: 99});
        }

        function carregarPagingParams() {
            if (pagingParams.search) {
                var texto = pagingParams.search.match(/[^0-9]/g);
                if (texto) {
                    vm.chamado.titulo = pagingParams.search;
                } else {
                    var numero = pagingParams.search.match(/\d+$/);
                    vm.chamado.id = numero ? numero [0] : null;
                }
            }
            if (pagingParams.situacao) {
                vm.chamado.situacao = pagingParams.situacao;
                vm.situacao = pagingParams.situacao;
            }
            if (pagingParams.tipoChamado) {
                vm.chamado.tipoChamado = pagingParams.tipoChamado;
            }
            if (pagingParams.moduloId) {
                vm.chamado.modulo = {id: pagingParams.moduloId};
            }
            if (pagingParams.clienteId) {
                vm.chamado.cliente = {id: pagingParams.clienteId};
            }
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                carregarCombos(account);
                carregarPagingParams();
                // if (localStorageService.get("chamado")) {
                //     vm.chamado = localStorageService.get("chamado");
                // }
                carregarClientes();
            });
        }

        function register() {
            $state.go('register');
        }

        function editarChamado(chamado) {
            $state.go('edit-chamado', ({id: chamado.id}));
        }


        function getCorPorTipo(tipo) {
            switch (tipo) {
                case 'SUPORTE':
                    return 'warning';
                case 'BUG':
                    return 'danger';
                case 'MELHORIA':
                    return 'info';
                case 'NOVA_FUNCIONALIDADE':
                    return 'success';
                default:
                    return 'info';
            }
        }


        function sincronizarArquivos() {
            Chamado.sincronizarArquivos();
        }


        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header', alignment: 'center'},
                    {text: 'Relatório de Acompanhamento dos Chamados por Fila', style: 'subheader'},
                    filasImpressao()
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    footer: {
                        fontSize: 14,
                        italic: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function filasImpressao() {
            var filasImprimir = [];

            angular.forEach(vm.filasProntas, function (fila) {

                filasImprimir.push(
                    {
                        text: $filter('translate')('chamadosApp.SituacaoChamado.' + fila),
                        style: 'header',
                        alignment: 'center',
                        margin: [5, 10, 5, 10]
                    },
                    table(getChamadosParaImpressao(fila), ['Severidade', 'Tipo', 'Chamado', 'Título', 'Módulo', 'Sub-Módulo'])
                );

            });

            return filasImprimir;

        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function getChamadosParaImpressao(situacao) {
            var chamados = [];

            angular.forEach(vm.chamados, function (chamado) {
                if (chamado.situacao === situacao) {
                    chamados.push(
                        {
                            'Severidade': $filter('translate')('chamadosApp.Severidade.' + chamado.severidade),
                            'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado),
                            'Chamado': chamado.id,
                            'Título': chamado.titulo,
                            'Módulo': chamado.modulo.descricao,
                            'Sub-Módulo': chamado.subModulo
                        }
                    )
                }
            });


            return chamados;
        }
    }
})();
