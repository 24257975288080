(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('OcorrenciaDialogController', OcorrenciaDialogController);

    OcorrenciaDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Principal', 'Cliente', 'Ocorrencia', 'User', 'Sistema', 'Chamado'];

    function OcorrenciaDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Principal, Cliente, Ocorrencia, User, Sistema, Chamado) {
        var vm = this;

        vm.ocorrencia = entity;
        vm.clear = clear;
        vm.save = save;
        vm.carregarCliente = carregarCliente;
        vm.carregarSistemas = carregarSistemas;
        vm.adicionarChamado = adicionarChamado;
        vm.removerChamado = removerChamado;
        vm.indexOcorrencia = indexOcorrencia;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.ocorrenciaChamado = {chamado: null, ocorrencia: null};

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        carregarCliente();

        function carregarCliente() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                if (vm.ocorrencia.id === null) {
                    vm.ocorrencia.cliente = vm.clientes[0];
                }
                carregarSistemas();
            });
        }

        function carregarSistemas() {
            if (vm.ocorrencia.cliente) {
                Sistema.getByCliente({clienteId: vm.ocorrencia.cliente.id}, function (data) {
                    vm.sistemas = data;
                })
            } else {
                vm.sistemas = [];
            }
        }

        function adicionarChamado(id) {
            if (vm.ocorrencia.chamados === undefined) {
                vm.ocorrencia.chamados = [];
            }
            Chamado.get({id: id}, function (chamado) {
                vm.ocorrenciaChamado.chamado = chamado;
                vm.ocorrenciaChamado.ocorrencia = vm.ocorrenci;
                vm.ocorrencia.chamados.push(vm.ocorrenciaChamado);
                vm.ocorrenciaChamado = {chamado: null, ocorrencia: null};
                vm.adicionar = null;
            });

        }

        function indexOcorrencia(id) {
            for (var i = 0; i < vm.ocorrencia.chamados.length; i++) {
                if (vm.ocorrencia.chamados[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function removerChamado(chamados) {
            vm.ocorrencia.chamados.splice(vm.indexOcorrencia(chamados.id), 1)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.ocorrencia.id !== null) {
                Ocorrencia.update(vm.ocorrencia, onSaveSuccess, onSaveError);
            } else {
                Ocorrencia.save(vm.ocorrencia, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:clienteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
