(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ReleaseDetailController', ReleaseDetailController);

    ReleaseDetailController.$inject = ['$scope', '$rootScope', 'Alerta', 'previousState', 'entity', 'Release', 'Chamado', '$state', '$filter'];

    function ReleaseDetailController($scope, $rootScope, Alerta, previousState, entity, Release, Chamado, $state, $filter) {
        var vm = this;

        vm.release = entity;
        vm.previousState = previousState.name;
        vm.salvar = salvar;
        vm.remover = remover;
        vm.alocar = alocar;
        vm.desalocar = desalocar;
        vm.encerrarRelease = encerrarRelease;
        vm.iniciarRelease = iniciarRelease;
        vm.sincronizartGit = sincronizartGit;
        vm.copyToClipboard = copyToClipboard;
        vm.atualizarNotas = atualizarNotas;

        var unsubscribe = $rootScope.$on('chamadosApp:releaseUpdate', function (event, result) {
            vm.release = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function sincronizartGit() {
            Release.sincronizartGit({id: vm.release.id}, loadChamados);
        }

        function copyToClipboard(copyText) {
            navigator.clipboard.writeText(copyText);
        }

        function loadChamados() {
            vm.chamado = {release: vm.release};
            Chamado.getChamadosByRelease({releaseId: vm.chamado.release.id}, function (data) {
                vm.chamados = data;
                vm.release.detalhesRelease = [];
                vm.release.detalhesRelease.push({
                    quantidade: vm.chamados.length,
                    texto: "Chamados",
                    cor: "navy-bg",
                    icon: 'fa-cloud'
                });

                var chamadoTipo = {};
                vm.projetosGit = {};
                angular.forEach(vm.chamados, function (chamado) {
                    Chamado.getSolicitacoes({id: chamado.id}, function (solicitacoes) {
                        chamado.solicitacoes = solicitacoes;
                        for (var i = 0; i < chamado.solicitacoes.length; i++) {
                            var tarefa = chamado.solicitacoes[i];
                            for (var j = 0; j < tarefa.projetosGit.length; j++) {
                                var projetoGit = tarefa.projetosGit[j];
                                if (!vm.projetosGit[projetoGit.repositorioGit]) {
                                    vm.projetosGit[projetoGit.repositorioGit] = projetoGit;
                                }
                            }

                        }
                    });
                });

                angular.forEach(vm.chamados, function (chamado) {
                    if (!chamadoTipo[chamado.tipoChamado]) {
                        chamadoTipo[chamado.tipoChamado] = [];
                    }
                    chamadoTipo[chamado.tipoChamado].push(chamado);
                });

                angular.forEach(chamadoTipo, function (chamados, tipo) {
                    vm.release.detalhesRelease.push({
                        quantidade: chamados.length,
                        texto: $filter('translate')('chamadosApp.TipoChamado.' + tipo),
                        cor: tipo === 'BUG' ? 'red-bg' : tipo === 'MELHORIA' ? 'yellow-bg' : tipo === 'NOVA_FUNCIONALIDADE' ? 'blue-bg' : 'navy-bg',
                        icon: tipo === 'BUG' ? 'fa-bug' : tipo === 'MELHORIA' ? 'fa-cogs' : tipo === 'NOVA_FUNCIONALIDADE' ? 'fa-cloud-upload' : 'fa-cloud'
                    });
                });


            });
        }

        function atualizarNotas() {
            var modulos = [];
            var chamadoModulo = {};
            for (var i = 0; i < vm.chamados.length; i++) {
                var chamado = vm.chamados[i];

                if (!chamadoModulo[chamado.modulo.id]) {
                    chamadoModulo[chamado.modulo.id] = [];
                    modulos.push(chamado.modulo);
                }
                chamadoModulo[chamado.modulo.id].push(chamado);

            }

            vm.release.notas = "";
            for (var i = 0; i < modulos.length; i++) {
                var modulo = modulos[i];
                vm.release.notas += "&nbsp; " + modulo.descricao + " <br>";
                var sorted = chamadoModulo[modulo.id];
                for (var j = 0; j < sorted.length; j++) {
                    var chamado = sorted[j];
                    vm.release.notas += "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; " + chamado.subModulo.descricao + " <small><i>(" + $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado) + ")</i></small>: "
                        + "#" + chamado.id + "&nbsp;-&nbsp;"
                        + (chamado.releaseNotes ? chamado.releaseNotes : chamado.titulo) + "<br>";

                }
                vm.release.notas += "<br>";
            }
        }


        loadChamados();

        function confirmEncerrar() {
            Release.encerrar(vm.release, function (data) {
                Alerta.success("Operação realizada", "A Release foi encerrada!");
                $state.go('release');
            }, function error(error) {
                Alerta.error("Operação Não realizada", "Tente novamente!");
            });
        }

        function confirmIniciar() {
            Release.iniciar(vm.release, function (data) {
                Alerta.success("Operação realizada", "A Release foi iniciada!");
                $state.reload(true);
            });
        }

        function cancel() {
            //Alerta.success("Operação NÃO Realizada", "")
        }


        function encerrarRelease() {
            Alerta.confirm("Atenção!",
                "Você tem certeza que deseja encerrar a Release " + vm.release.versao + "?",
                "warning",
                confirmEncerrar, cancel);
        }


        function iniciarRelease() {
            Alerta.confirm("Atenção!",
                "Você tem certeza que deseja iniciar a Release " + vm.release.versao + "?",
                "warning",
                confirmIniciar, cancel);
        }

        function salvar() {
            Release.update(vm.release, function (data) {
                Alerta.success("Operação realizada", "A Release foi Salva!");
                $state.go('release');
            }, function error(error) {
                Alerta.error("Operação Não realizada", "Tente novamente!");
            });
        }

        function remover() {
            Release.delete({id: vm.release.id}, function (data) {
                Alerta.success("Operação realizada", "A Release foi removida!");
                $state.go('release');
            }, function error(error) {
                Alerta.error("Operação Não realizada", "Tente novamente!");
            });
        }

        function desalocar(remover) {

            Chamado.get({id: remover.id}, function (chamado) {
                chamado.release = null;
                Chamado.update(chamado, function (removido) {
                    Alerta.success("Operação realizada", "O chamado " + removido.id + " foi removido");
                    $state.reload(true);
                });
            });
        }

        function alocar(id) {
            Chamado.get({id: id}, function (chamado) {
                chamado.release = vm.release;
                Chamado.update(chamado, function (adicionado) {
                    Alerta.success("Operação realizada", "O chamado " + adicionado.id + " foi adicionado");
                    $state.reload(true);
                });
            });
        }

    }
})();
