(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosPorVolumetriaController', ChamadosPorVolumetriaController);

    ChamadosPorVolumetriaController.$inject = ['ChamadosPorVolumetria', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', 'Release', '$filter', 'Cliente'];

    function ChamadosPorVolumetriaController(ChamadosPorVolumetria, DateUtils, Modulo, SubModulo, Sprint, Release, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null, criadoEmInicio: null, criadoEmFim: null, sprints:[]};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        vm.diasSemMovimentar = 1;


        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
            });
        }

        function loadAll() {
            ChamadosPorVolumetria.query(vm.chamado, function (data) {
                vm.total = data.total;
                vm.quantidadeAberto = data.quantidadeAberto;
                vm.quantidadeImpedido = data.quantidadeImpedido;
                vm.quantidadeEncerrado = data.quantidadeEncerrado;
                vm.quantidadeRejeitado = data.quantidadeRejeitado;
                vm.quantidadeAbertoVencidos = data.quantidadeAbertoVencidos;
                vm.quantidadeAguardandoValidacao = data.quantidadeAguardandoValidacao;
                vm.quantidadeAguardandoDesenvolvimento = data.quantidadeAguardandoDesenvolvimento;
                vm.quantidadeAbertoSprint = data.quantidadeAbertoSprint;
                vm.quantidadeTeste = data.quantidadeTeste;
                vm.produtividade = data.produtividade;
                vm.esforcoEmpregado = data.esforcoEmpregado;
                vm.tempoMedio = data.tempoMedio;
                vm.atendimentoSegundoNivel = data.atendimentoSegundoNivel;
                vm.atendimentoDesenvolvimento = data.atendimentoDesenvolvimento;
                vm.severidades = data.severidades;
            });
        }

    }

})();


