(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('OcorrenciaController', OcorrenciaController);

    OcorrenciaController.$inject = ['$scope', '$state', 'Ocorrencia', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Alerta', 'Cliente'];

    function OcorrenciaController($scope, $state, Ocorrencia, ParseLinks, AlertService, paginationConstants, pagingParams, Alerta, Cliente) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.remover = remover;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selecionarCliente = selecionarCliente;

        loadAll();

        function carregarClientes() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.cliente = data[0];
                carregarOcorrencias();
            })
        }

        function loadAll() {
            carregarClientes();

        }

        function carregarOcorrencias() {
            Ocorrencia.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.ocorrencias = [];
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                angular.forEach(data, function (ocorrencia) {
                    if (ocorrencia.cliente.id === vm.cliente.id) {
                        vm.ocorrencias.push(ocorrencia);
                    }
                });

                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function selecionarCliente(cliente) {
            vm.cliente = cliente;
            carregarOcorrencias();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function remover(id) {
            Alerta.confirm("Atenção", "Tem certeza que deseja remover esse Ocorrencia?", "warning", function () {
                Ocorrencia.delete({id: id},
                    function () {
                        Alerta.success("Operação realizada", "O Ocorrencia foi removido!");
                        loadAll();
                    });
            });
        }

    }
})();
