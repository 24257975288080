(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosPorSolicitanteController', ChamadosPorSolicitanteController);

    ChamadosPorSolicitanteController.$inject = ['ChamadosPorSolicitante', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', 'Release', 'Solicitante', '$filter', 'Cliente'];

    function ChamadosPorSolicitanteController(ChamadosPorSolicitante, DateUtils, Modulo, SubModulo, Sprint, Release, Solicitante, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null, criadoEmInicio: new Date(), criadoEmFim: new Date()};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        vm.solicitantes = Solicitante.query({page: 0, size: 99});

        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        vm.imprimir = imprimir;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
            });
        }

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header'},
                    {text: 'Relatório de chamados por solicitantes', style: 'subheader'},
                    {text: vm.chamado.modulo ? 'Módulo: ' + vm.chamado.modulo.descricao : " "},
                    {text: vm.chamado.situacao ? 'Situação: ' + vm.chamado.situacao : " "},
                    solicitantesImpressao()
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('chamados-por-solicitante.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function solicitantesImpressao() {
            var solicitantesImprimir = [];

            angular.forEach(vm.solicitantesProntos, function (solicitante) {
                solicitantesImprimir.push(
                    {text: solicitante.nome, style: 'header', alignment: 'center', margin: [5, 10, 5, 10]},
                    table(getChamadosParaImpressao(solicitante), ['Chamado', 'Tipo', 'Título', 'Tempo Est.', 'Módulo', 'Situação'])
                );

            });

            return solicitantesImprimir;

        }

        function loadAll() {
            vm.chamadosAgrupados = {};
            vm.solicitantesProntos = [];
            ChamadosPorSolicitante.query(vm.chamado, function (data) {
                vm.chamados = data;
                angular.forEach(vm.chamados, function (chamado) {
                    if (!vm.chamadosAgrupados[chamado.entidadeSolicitante.nome]) {
                        vm.solicitantesProntos.push(chamado.entidadeSolicitante);
                        vm.chamadosAgrupados[chamado.entidadeSolicitante.nome] = {solicitante: chamado.entidadeSolicitante, chamados: []};
                    }
                    vm.chamadosAgrupados[chamado.entidadeSolicitante.nome].chamados.push(chamado);
                })

            });
        }


        function getChamadosParaImpressao(solicitante) {
            var chamados = [];
            angular.forEach(vm.chamadosAgrupados[solicitante.nome].chamados, function (chamado) {
                chamados.push(
                    {
                        'Chamado': chamado.id,
                        'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado),
                        'Título': chamado.titulo,
                        'Tempo Est.': chamado.tempoEstimado,
                        'Módulo': chamado.modulo.descricao,
                        'Situação': $filter('translate')('chamadosApp.SituacaoChamado.' + chamado.situacao)
                    }
                )
            });
            return chamados;
        }
    }

})();


