(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SolicitacaoDesenvolvimentoEditController', SolicitacaoDesenvolvimentoEditController);

    SolicitacaoDesenvolvimentoEditController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity',
        'SolicitacaoDesenvolvimento', 'User', 'Sistema', 'Principal', 'Cliente', 'Modulo', 'SubModulo', 'Sprint'];

    function SolicitacaoDesenvolvimentoEditController($timeout, $scope, $stateParams, $state, entity,
                                                      SolicitacaoDesenvolvimento, User, Sistema, Principal,
                                                      Cliente, Modulo, SubModulo, Sprint) {
        var vm = this;

        vm.solicitacaoDesenvolvimento = entity;
        vm.save = save;
        vm.carregarSistemas = carregarSistemas;
        vm.carregarSubModulo = carregarSubModulo;
        vm.encerraChamado = encerraChamado;
        vm.modulos = Modulo.queryByUser();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function encerraChamado(encerra) {
            vm.solicitacaoDesenvolvimento.chamado.situacao = encerra? 'FECHADO': 'EM_DESENVOLVIMENTO';
        }

        function save() {
            vm.isSaving = true;
            if (vm.solicitacaoDesenvolvimento.id !== null) {
                SolicitacaoDesenvolvimento.update(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            } else {
                SolicitacaoDesenvolvimento.save(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(data) {
            if (data && data.id) {
                vm.isSaving = false;
                $state.go("solicitacaoDesenvolvimento.detail", {id: data.id});
            } else {
                vm.isSaving = false;
                $state.go("scrumboard");
            }

        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                vm.solicitacaoDesenvolvimento.solicitante = vm.account;
                Cliente.getByUser({}, function (data) {
                    vm.clientes = data;
                    if (vm.solicitacaoDesenvolvimento.sistema) {
                        for (var i = 0; i < data.length; i++) {
                            if (vm.solicitacaoDesenvolvimento.cliente.id === data[i].id) {
                                vm.solicitacaoDesenvolvimento.cliente = data[i];
                            }
                        }
                    } else {
                        vm.solicitacaoDesenvolvimento.cliente = data[0];
                    }
                    carregarSistemas();

                })

            });
        }

        function carregarSistemas() {
            if (vm.solicitacaoDesenvolvimento.cliente) {
                Sistema.getByCliente({clienteId: vm.solicitacaoDesenvolvimento.cliente.id}, function (data) {
                    vm.sistemas = data;
                    if (vm.solicitacaoDesenvolvimento.sistema) {
                        for (var i = 0; i < data.length; i++) {
                            if (vm.solicitacaoDesenvolvimento.sistema.id === data[i].id) {
                                vm.solicitacaoDesenvolvimento.sistema = data[i];
                            }
                        }
                    } else {
                        vm.solicitacaoDesenvolvimento.sistema = data[0];
                    }
                })
            } else {
                vm.sistemas = [];
            }
        }

        function carregarSubModulo() {
            if (vm.solicitacaoDesenvolvimento.modulo) {
                vm.submodulos = SubModulo.queryByModulo({id: vm.solicitacaoDesenvolvimento.modulo.id});
            }
        }

        function carregarSprint() {
            Sprint.query({}, function (data) {
                vm.sprints = data;
                if (!vm.solicitacaoDesenvolvimento.sprint) {
                    for (var i = 0; i < vm.sprints.length; i++) {
                        if (vm.sprints[i].situacao === 'EM_ANDAMENTO')
                            vm.solicitacaoDesenvolvimento.sprint = vm.sprints[i];
                    }
                }

            });

        }

        getAccount();
        carregarSubModulo();
        carregarSprint();
    }
})();
