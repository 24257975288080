(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Release', Release);

    Release.$inject = ['$resource', 'DateUtils'];

    function Release($resource, DateUtils) {
        var resourceUrl = 'api/releases/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'sincronizartGit': {url: '/api/sincronizar-git-releases/:id', method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.solicitacao = DateUtils.convertLocalDateFromServer(data.solicitacao);
                        data.liberacao = DateUtils.convertLocalDateFromServer(data.liberacao);
                    }
                    return data;
                }
            },
            'getByVersao': {
                url: '/api/release-by-version/:versao',
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.solicitacao = DateUtils.convertLocalDateFromServer(data.solicitacao);
                        data.liberacao = DateUtils.convertLocalDateFromServer(data.liberacao);
                    }
                    return data;
                }
            },
            'getBySituacao': {
                url: '/api/release-by-situacao',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.solicitacao = DateUtils.convertLocalDateFromServer(data.solicitacao);
                        data.liberacao = DateUtils.convertLocalDateFromServer(data.liberacao);
                    }
                    return data;
                }
            },
            'new': {
                url: '/api/releases/new',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.solicitacao = DateUtils.convertLocalDateFromServer(data.solicitacao);
                        data.liberacao = DateUtils.convertLocalDateFromServer(data.liberacao);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.solicitacao = DateUtils.convertLocalDateToServer(copy.solicitacao);
                    copy.liberacao = DateUtils.convertLocalDateToServer(copy.liberacao);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.solicitacao = DateUtils.convertLocalDateToServer(copy.solicitacao);
                    copy.liberacao = DateUtils.convertLocalDateToServer(copy.liberacao);
                    return angular.toJson(copy);
                }
            },
            'encerrar': {
                method: 'PUT',
                url: '/api/releases/encerramento',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.solicitacao = DateUtils.convertLocalDateToServer(copy.solicitacao);
                    copy.liberacao = DateUtils.convertLocalDateToServer(copy.liberacao);
                    return angular.toJson(copy);
                }
            },

            'iniciar': {
                method: 'PUT',
                url: '/api/releases/iniciar',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.solicitacao = DateUtils.convertLocalDateToServer(copy.solicitacao);
                    copy.liberacao = DateUtils.convertLocalDateToServer(copy.liberacao);
                    return angular.toJson(copy);
                }
            },
        });
    }
})();
