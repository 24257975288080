(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('ata', {
                parent: 'entity',
                url: '/ata?page&sort&search',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Ata'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/ata/ata.html',
                        controller: 'AtaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('edit-ata', {
                parent: 'ata',
                url: '/ata-edit/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Edição de ATA'
                },
                ncyBreadcrumb: {
                    label: 'Edição de ATA'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/ata/ata-edit.html',
                        controller: 'AtaEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Ata', function ($stateParams, Ata) {
                        return Ata.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('detail-ata', {
                parent: 'ata',
                url: '/ata-detail/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Detalhes da Ata'
                },
                ncyBreadcrumb: {
                    label: 'Detalhes da Ata'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/ata/ata-detail.html',
                        controller: 'AtaDetailController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Ata', function ($stateParams, Ata) {
                        return Ata.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('tipoHora');

                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('new-ata', {
                parent: 'ata',
                url: '/ata-new/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Nova ATA'
                },
                ncyBreadcrumb: {
                    label: 'Nova ATA'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/ata/ata-edit.html',
                        controller: 'AtaEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Ata', function ($stateParams, Ata) {
                        return {};
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })


    }

})();
