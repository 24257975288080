(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AplicacaoController', AplicacaoController);

    AplicacaoController.$inject = ['$scope', '$state', 'Aplicacao', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Alerta', 'Cliente'];

    function AplicacaoController($scope, $state, Aplicacao, ParseLinks, AlertService, paginationConstants, pagingParams, Alerta, Cliente) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.remover = remover;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selecionarCliente = selecionarCliente;
        vm.verificarSituacao = verificarSituacao;

        loadAll();

        function carregarClientes() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.cliente = data[0];
                carregarAplicacoes();
            })
        }

        function loadAll() {
            carregarClientes();

        }

        function carregarAplicacoes() {
            Aplicacao.query({
                page: pagingParams.page - 1,
                size: 9999,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.agrupador = {};
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                for (var i = 0; i < data.length; i++) {
                    if (!vm.agrupador[data[i].agrupador]) {
                        vm.agrupador[data[i].agrupador] = {nome: data[i].agrupador, aplicacoes: []};
                    }
                    vm.agrupador[data[i].agrupador].aplicacoes.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function selecionarCliente(cliente) {
            vm.cliente = cliente;
            carregarAplicacoes();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function remover(id) {
            Alerta.confirm("Atenção", "Tem certeza que deseja remover esse Aplicacao?", "warning", function () {
                Aplicacao.delete({id: id},
                    function () {
                        Alerta.success("Operação realizada", "O Aplicacao foi removido!");
                        loadAll();
                    });
            });
        }

        function verificarSituacao(id) {

            Aplicacao.verififcarSituacao({id: id},
                function () {
                    loadAll();
                });

        }
    }
})();
