(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosRejeitadosController', ChamadosRejeitadosController);

    ChamadosRejeitadosController.$inject = ['$sanitize', 'ChamadosRejeitados', 'DateUtils', '$uibModal', 'Account', 'Modulo', 'SubModulo', 'Sprint', 'Release', '$filter', 'Cliente'];

    function ChamadosRejeitadosController($sanitize, ChamadosRejeitados, DateUtils, $uibModal, Account, Modulo, SubModulo, Sprint, Release, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null, criadoEmInicio: null, criadoEmFim: null, sprints:[]};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];
        vm.visualizar = visualizar;
        vm.imprimir = imprimir;
        vm.loadAll = loadAll;
        vm.limpar = limpar;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
            });
        }

        function limpar() {
            vm.chamado = {
                id: null,
                modulo: null,
                tipoChamado: null,
                titulo: null,
                criadoEmInicio: null,
                criadoEmFim: null
            };
            limparVariaveis();
        }

        function limparVariaveis() {
            vm.totalChamados = 0;
            vm.totalRejeicoes = 0;
            vm.totalMelhorias = 0;
            vm.labels = [];
            vm.data = [];
            vm.percentual = [];
            vm.chamados = [];
            vm.modulosAtrasados = [];
        }

        function loadAll() {
            limparVariaveis();
            var rejeicaoPorModulo = {};

            vm.labels.push('Rejeições');
            vm.labels.push('Melhorias');

            ChamadosRejeitados.query(vm.chamado, function (data) {
                vm.totalChamados = data.length;
                angular.forEach(data, function (chamado) {

                    if (chamado.rejeitado) {
                        vm.chamados.push(chamado);
                        vm.totalRejeicoes = vm.totalRejeicoes + chamado.quantidadeRejeicao;
                        vm.totalMelhorias = vm.totalMelhorias + chamado.quantidadeMelhorias;

                        angular.forEach(chamado.comentariosRejeicao, function (comentario) {
                            Account.getImagemAjustadaByLogin({login: comentario.usuario.login}, function (data) {
                                comentario.imagemUsuario = data.imagemAjustada;
                            });
                        });

                        if (!rejeicaoPorModulo[chamado.chamado.modulo.descricao]) {
                            rejeicaoPorModulo[chamado.chamado.modulo.descricao] = [];
                        }
                        rejeicaoPorModulo[chamado.chamado.modulo.descricao].push(chamado);

                        vm.modulosAtrasados = [];

                        angular.forEach(rejeicaoPorModulo, function (value, key) {
                            var modulo = {modulo: key, quantidade: 0};
                            angular.forEach(value, function (chamado) {
                                modulo.quantidade += (chamado.quantidadeRejeicao + chamado.quantidadeMelhorias);
                            });
                            vm.modulosAtrasados.push(modulo);
                        });
                    }
                });

                vm.data.push(vm.totalRejeicoes);
                vm.data.push(vm.totalMelhorias);

                for (var i = 0; i < vm.data.length; i++) {
                    var valor = vm.data[i];
                    vm.percentual[i] = (valor * 100) / (vm.totalRejeicoes + vm.totalMelhorias);
                }
            });
        }


        function visualizar(chamado) {
            var modalInstance = $uibModal.open({
                size: 'lg',
                animation: true,
                templateUrl: 'visualizarRejeicao.html',
                controller: 'VisualizarRejeicaoController',
                controllerAs: 'vm',
                windowClass: 'modal-grande',
                resolve: {
                    chamado: chamado
                }
            });
            modalInstance.result.then(function (chamado) {
                /*ao fecherar o dialog*/
            });
        }

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header', alignment: 'center'},
                    {text: 'Relatório de Rejeição', style: 'subheader'},
                    table(getChamadosParaImpressao(), ['Chamado', 'Tipo', 'Título', 'Tempo Est.', 'Módulo', 'Situação', 'Rejeição', 'Melhoria']),
                    {text: 'Total de rejeitados:'+ vm.totalRejeicoes, style: 'subheader'},
                    {text: 'Total de melhorias:'+ vm.totalMelhorias, style: 'subheader'},
                    {text: 'Total de chamados:'+ vm.totalChamados, style: 'subheader'},
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados Rejeitados.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }


        function getChamadosParaImpressao() {
            /*table(getChamadosParaImpressao(), ['Chamado', 'Tipo', 'Título', 'Tempo Est.', 'Módulo', 'Situação', 'Rejeição', 'Melhoria'])*/
            var chamados = [];
            angular.forEach(vm.chamados, function (chamado) {
                chamados.push(
                    {
                        'Chamado': chamado.chamado.id,
                        'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.chamado.tipoChamado),
                        'Título': chamado.chamado.titulo,
                        'Tempo Est.': chamado.chamado.tempoEstimado,
                        'Módulo': chamado.chamado.modulo.descricao,
                        'Situação': $filter('translate')('chamadosApp.SituacaoChamado.' + chamado.chamado.situacao),
                        'Rejeição': chamado.quantidadeRejeicao,
                        'Melhoria': chamado.quantidadeMelhorias
                    }
                )
            });
            return chamados;
        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }
    }

    angular
        .module('chamadosApp')
        .controller('VisualizarRejeicaoController', VisualizarRejeicaoController);

    VisualizarRejeicaoController.$inject = ['$sanitize', '$uibModalInstance', 'chamado'];

    function VisualizarRejeicaoController($sanitize, $uibModalInstance, chamado) {
        var vm = this;
        vm.trustAsHtml = trustAsHtml;
        vm.chamado = chamado;

        function trustAsHtml(value) {
            return $sanitize(value);
        };

        vm.ok = function () {
            $uibModalInstance.close(vm.chamado);
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    };

})();


