(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AcaoEditController', AcaoEditController);

    AcaoEditController.$inject = ['$rootScope', '$scope', '$state', 'entity', 'Acao', 'SolicitacaoDesenvolvimento', 'Chamado', 'User', 'Ata'];

    function AcaoEditController($rootScope, $scope, $state, entity, Acao, SolicitacaoDesenvolvimento, Chamado, User, Ata) {
        var vm = this;

        vm.save = save;
        vm.buscarChamadoOrTarefa = buscarChamadoOrTarefa;
        vm.getAllUsers = getAllUsers;
        vm.isSaving = false;
        vm.acao = entity;
        vm.numeroBusca;
        vm.users;

        function save() {
            vm.isSaving = true;
            if (vm.acao.id !== null) {
                Acao.update(vm.acao, onSucessSave, onErrorSave);
            } else {
                Acao.save(vm.acao, onSucessSave, onErrorSave);
            }
        }

        function getAllUsers() {
            User.getAllInternos(function (data) {
                vm.users = data;
                if (vm.acao.user) {
                    for (var i = 0; i < vm.users.length; i++) {
                        var user = vm.users[i];
                        if (user.login === vm.acao.user.login) {
                            vm.acao.user = user;
                            break;
                        }
                    }
                }
            });
        }

        vm.getAllUsers();

        function onSucessSave() {
            $scope.$emit('chamadosApp:acaoUpdate', vm.acao);
            $state.go('acao');
        }

        function onErrorSave() {
            vm.isSaving = false;
        }

        function buscarChamadoOrTarefa() {
            if(!vm.numeroBusca){
                return;
            }
            SolicitacaoDesenvolvimento.getByNumero({numero: vm.numeroBusca},
                function (ticket) {
                    vm.acao.solicitacaoDesenvolvimento = ticket;
                }, function (error) {
                    Chamado.get({id: vm.numeroBusca}, function (chamado) {
                        vm.acao.chamado = chamado;
                    }, function (error) {
                        Ata.getByNumero({numero: vm.numeroBusca}, function (ata) {
                            vm.acao.ata = ata;
                        }, function (error) {
                            Alerta.error("Ops!", "Não foi possível encontrar chamado, tarefa ou ata com o número" + vm.numeroBusca + "!");
                        });
                    });
                });
        }

        function defineVariaveisTransient() {
            vm.numeroBusca = vm.acao.chamado ? vm.acao.chamado.numero :
                vm.acao.solicitacaoDesenvolvimento ? vm.acao.solicitacaoDesenvolvimento.numero :  vm.acao.ata?
                    vm.acao.ata.numero : null;


        }

        defineVariaveisTransient();
    }
})();
