(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AlterarSituacaoTarefaController', AlterarSituacaoTarefaController);

    AlterarSituacaoTarefaController.$inject = ['$uibModalInstance', '$timeout', '$scope', '$stateParams', 'solicitacaoDesenvolvimento', 'tempoGasto',
        'SolicitacaoDesenvolvimento', '$state', 'Sprint', 'Principal', 'Chamado', 'Alerta'];

    function AlterarSituacaoTarefaController($uibModalInstance, $timeout, $scope, $stateParams, solicitacaoDesenvolvimento, tempoGasto,
                                             SolicitacaoDesenvolvimento, $state, Sprint, Principal, Chamado, Alerta
    ) {
        var vm = this;
        vm.solicitacaoDesenvolvimento = solicitacaoDesenvolvimento;

        vm.tempoGasto = tempoGasto;
        vm.avancarTarefa = avancarTarefa;
        vm.save = save;
        vm.isSaving = false;
        vm.clear = clear;
        vm.adicionarRepositorio = adicionarRepositorio;

        function ajustarSituacaoAtual() {
            vm.situacaoAtual = vm.solicitacaoDesenvolvimento.situacao;
            if (vm.solicitacaoDesenvolvimento.situacao === 'A_FAZER') {
                Sprint.getBySituacao({situacao: 'EM_ANDAMENTO'}, function (sprint) {
                    vm.solicitacaoDesenvolvimento.sprint = sprint;
                    vm.solicitacaoDesenvolvimento.sprintOriginal = sprint;
                });
                vm.solicitacaoDesenvolvimento.situacao = 'FAZENDO';
                return;
            }
            if (vm.solicitacaoDesenvolvimento.situacao === 'FAZENDO') {
                vm.solicitacaoDesenvolvimento.situacao = 'CODE_REVIEW';
                return;
            }
            if (vm.solicitacaoDesenvolvimento.situacao === 'CODE_REVIEW') {
                vm.solicitacaoDesenvolvimento.situacao = 'A_TESTAR';
                vm.tempoGasto.tipo = 'CODE_REVIEW';
                return;
            }
            if (vm.solicitacaoDesenvolvimento.situacao === 'A_TESTAR') {
                vm.solicitacaoDesenvolvimento.situacao = 'TESTANDO';
                return;
            }
            if (vm.solicitacaoDesenvolvimento.situacao === 'TESTANDO') {
                vm.tempoGasto.tipo = 'TESTE';
                vm.solicitacaoDesenvolvimento.situacao = 'CONCLUIDA';
                return;
            }
        }

        ajustarSituacaoAtual();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });


        Principal.identity().then(function (account) {
            vm.account = account;
        });


        function save() {
            vm.isSaving = true;
            SolicitacaoDesenvolvimento.update(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
        }

        function avancarTarefa() {
            vm.isSaving = true;
            vm.editTarefa = false;
            SolicitacaoDesenvolvimento.avancar(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.solicitacaoDesenvolvimento = result;
            vm.isSaving = false;
            vm.editTarefa = false;
            if (vm.tempoGasto.horas || vm.tempoGasto.minutos) {
                definirTempoGasto();
            } else {
                Alerta.success("Operação realizada!", "Tarefa Alterada com sucesso!");
                $uibModalInstance.close(vm.solicitacaoDesenvolvimento);
            }
        }

        function definirTempoGasto() {
            vm.isSaving = true;
            vm.tempoGasto.tempo = vm.tempoGasto.horas + (vm.tempoGasto.minutos / 60);
            vm.tempoGasto.solicitacaoDesenvolvimento = vm.solicitacaoDesenvolvimento;
            vm.tempoGasto.chamado = vm.solicitacaoDesenvolvimento.chamado;
            Chamado.defirnirTempo(vm.tempoGasto, function (data) {
                vm.openChat = false;
                Alerta.success("Operação realizada!", "Tarefa Alterada e tempo apontado com sucesso!");
                $uibModalInstance.close(vm.solicitacaoDesenvolvimento);
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function carregarSituacoes() {
            vm.situacoes = SolicitacaoDesenvolvimento.getSituacoePossiveis({situacao: vm.situacaoAtual})
        }

        function adicionarRepositorio() {
            if (!vm.solicitacaoDesenvolvimento.projetosGit) {
                vm.solicitacaoDesenvolvimento.projetosGit = [];
            }
            vm.solicitacaoDesenvolvimento.projetosGit
                .push({
                    projetoGit: vm.projetoGit.key,
                    repositorioGit: vm.repositorioGit.name
                })
            vm.projetoGit = {};
        }

        function carregarRepositories() {
            if (vm.solicitacaoDesenvolvimento.sistema && !vm.solicitacaoDesenvolvimento.projetosGit) {
                vm.solicitacaoDesenvolvimento.projetosGit = [];
                vm.solicitacaoDesenvolvimento.projetosGit
                    .push({
                        projetoGit: vm.solicitacaoDesenvolvimento.sistema.projetoGit,
                        repositorioGit: vm.solicitacaoDesenvolvimento.sistema.repositorioGit
                    })
            }
            vm.projetos = SolicitacaoDesenvolvimento.getGitRepositories()
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        carregarSituacoes();
        carregarRepositories();

    }

})();
