(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AtaDetailController', AtaDetailController);

    AtaDetailController.$inject = ['$scope', 'entity', 'Ata'];

    function AtaDetailController($scope, entity, Ata) {
        var vm = this;
        vm.ata = entity;

        vm.temposGastos = Ata.getTempoGasto({id: vm.ata.id});
    }
})();
