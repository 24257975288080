(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosRejeitados', ChamadosRejeitados);

    ChamadosRejeitados.$inject = ['$resource'];

    function ChamadosRejeitados($resource) {
        var resourceUrl = 'api/chamados-rejeitados';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            }

        });
    }


})();
