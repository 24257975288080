(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'menu@': {
                    templateUrl: 'app/layouts/menu/menu.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'notifications@': {
                    templateUrl: 'app/layouts/notification/ws-notification.html',
                    controller: 'BrowserNotificationController',
                    controllerAs: 'vm'
                },
                'filas@': {
                    templateUrl: 'app/layouts/filasbar/filasbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            data: {
                specialClass: "body-bg"
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('tipoChamado');
                    $translatePartialLoader.addPart('situacaoChamado');
                }]
            }
        });



    }
})();
