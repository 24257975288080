(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Cidade', Cidade);

    Cidade.$inject = ['$resource'];

    function Cidade($resource) {
        return $resource('api/cidades/:id', {}, {
            'findByUf': {
                url: 'api/cidades-by-uf',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
