(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('RedmineController', RedmineController);

    RedmineController.$inject = ['$state', 'Account', 'Alerta'];

    function RedmineController($state, Account, Alerta) {
        var vm = this;
        vm.error = null;
        vm.save = save;
        vm.saveGit = saveGit;
        vm.redmineAccount = Account.getRedmine();

        function save() {
            Account.saveRedmine(vm.redmineAccount, function (data) {
                $state.go('redmine', {}, {reload: true});
                Alerta.success("Operação realizada!", "Vinculo com Redmine criado");
            }, function (error) {
                Alerta.error("Operação NÃO realizada!", "Vinculo com Redmine não pode ser criado");
            });
        }

        function saveGit() {
            Account.saveGit(vm.redmineAccount, function (data) {
                $state.go('redmine', {}, {reload: true});
                Alerta.success("Operação realizada!", "Vinculo com Git criado");
            }, function (error) {
                Alerta.error("Operação NÃO realizada!", "Vinculo com Git não pode ser criado");
            });
        }
    }
})();
