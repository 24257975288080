(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SolicitanteDialogController', SolicitanteDialogController);

    SolicitanteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Cliente', 'Solicitante', 'User', 'Principal'];

    function SolicitanteDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Cliente, Solicitante, User, Principal) {
        var vm = this;

        vm.solicitante = entity;
        vm.clear = clear;
        vm.save = save;

        getAccount();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                Cliente.getByUser({}, function (data) {
                    vm.clientes = data;
                })

            });
        }

        function save() {
            vm.isSaving = true;
            if (vm.solicitante.id !== null) {
                Solicitante.update(vm.solicitante, onSaveSuccess, onSaveError);
            } else {
                Solicitante.save(vm.solicitante, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:solicitanteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
