(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('MovimentacaoDemandasController', MovimentacaoDemandasController);

    MovimentacaoDemandasController.$inject = ['MovimentacaoDemandas', '$filter'];

    function MovimentacaoDemandasController(MovimentacaoDemandas, $filter) {
        var vm = this;
        var curr = new Date;
        vm.fim = new Date(curr);
        vm.inicio = new Date(curr.setDate(curr.getDate() - 7));

        vm.datePickerOpenStatus = {};
        vm.chamados = []
        vm.agruparPorCampo = agruparPorCampo;
        vm.load = load;
        vm.openCalendar = openCalendar;

        vm.orderBy = 'id';

        vm.chamadosPorCliente = {};

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function load() {
            MovimentacaoDemandas.situacaoPorPeriodo({inicio: vm.inicio, fim: vm.fim}, function (data) {
                vm.chamados = data;
                agruparPorCliente();
            });
        }

        function agruparPorCliente() {
            for (var i = 0; i < vm.chamados.length; i++) {
                var chamado = vm.chamados[i];
                if (!vm.chamadosPorCliente[chamado.clienteId]) {
                    vm.chamadosPorCliente[chamado.clienteId] = {
                        cliente: {id: chamado.clienteId, nome: chamado.cliente},
                        chamados: []
                    }
                }
                vm.chamadosPorCliente[chamado.clienteId].chamados.push(chamado);
            }
            if (vm.chamados.length > 0) {
                vm.clienteSelecionado = vm.chamadosPorCliente[vm.chamados[0].clienteId];
            }
            agruparPorModulo();
            angular.forEach(vm.chamadosPorCliente, function (cliente) {
                agruparPorCampo(cliente, 'situacao');
            });
        }

        function agruparPorModulo() {
            angular.forEach(vm.chamadosPorCliente, function (cliente) {
                cliente.modulos = {};
                for (var x = 0; x < cliente.chamados.length; x++) {
                    var chamado = cliente.chamados[x];
                    if (!cliente.modulos[chamado.moduloId]) {
                        cliente.modulos[chamado.moduloId] = {
                            modulo: {id: chamado.moduloId, nome: chamado.modulo},
                            chamados: []
                        }
                    }
                    cliente.modulos[chamado.moduloId].chamados.push(chamado);
                }
                angular.forEach(cliente.modulos, function (modulo) {
                    agruparPorCampo(modulo, 'situacao');
                });
            });
        }

        function agruparPorCampo(modulo, campo) {
            modulo.agrupamento = {};
            for (var x = 0; x < modulo.chamados.length; x++) {
                var chamado = modulo.chamados[x];
                if (!modulo.agrupamento[chamado[campo]]) {
                    modulo.agrupamento[chamado[campo]] = {
                        campo: chamado[campo],
                        chamados: []
                    }
                }
                modulo.agrupamento[chamado[campo]].chamados.push(chamado);
            }
            montarGraficoPizza(modulo);
        }

        function montarGraficoPizza(modulo) {
            if (modulo.chamados) {
                modulo.pie = {labels: [], data: []};
                modulo.pie.options = {responsive: true, legend: {display: true, position: 'bottom'}};

                angular.forEach(modulo.agrupamento, function (campo) {
                    modulo.pie.labels.push(campo.campo);
                    modulo.pie.data.push(campo.chamados.length);
                });
            }
        }

        load();
    }

})();


