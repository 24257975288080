(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('TempoGastoController', TempoGastoController);

    TempoGastoController.$inject = ['Principal',
        'Chamado', 'Sistema', 'AlertService', 'User', 'Alerta', 'DateUtils', '$sanitize', 'Cliente', 'pagingParams', '$uibModal'];

    function TempoGastoController(Principal,
                                  Chamado, Sistema, AlertService,
                                  User, Alerta, DateUtils, $sanitize, Cliente, pagingParams, $uibModal) {
        var vm = this;

        if (pagingParams.dia) {
            var parts =pagingParams.dia.split('-');
            var firstday = new Date(parts[0], parts[1] - 1, parts[2]);
            vm.filtro = {inicio: firstday, fim: firstday};
            vm.detalhado = true;
            vm.resumido = false;
        } else {
            var curr = new Date;
            var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
            var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
            vm.filtro = {inicio: firstday, fim: lastday};
            vm.detalhado = false;
            vm.resumido = true;
        }


        vm.account = null;
        vm.isAuthenticated = null;
        vm.datePickerOpenStatus = {};
        vm.geral = false;


        vm.excluir = excluir;
        vm.getTempoPorDia = getTempoPorDia;
        vm.getTempoTotalPorUsuario = getTempoTotalPorUsuario;
        vm.carregarTemposPorCliente = carregarTemposPorCliente;
        vm.carregarTemposPorSistemaCliente = carregarTemposPorSistemaCliente;
        vm.trustAsHtml = trustAsHtml;
        vm.loadAll = loadAll;
        vm.getUserByLogin = getUserByLogin;

        vm.openCalendar = openCalendar;
        vm.editarApontamento = editarApontamento;


        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                User.getAllInternos({}, function (data) {
                    vm.users = data;
                    angular.forEach(vm.users, function (user) {
                        if (vm.account.login === user.login)
                            vm.filtro.user = user;
                    });
                    loadAll();
                });
            });

            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                for (var i = 0; i < vm.clientes.length; i++) {
                    var cliente = vm.clientes[i];
                    cliente.sistemas = Sistema.getByCliente({clienteId: cliente.id});
                }
            });


        }

        getAccount();

        function carregarTemposPorCliente(cliente) {
            var total = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (atividade.solicitacaoDesenvolvimento) {
                    if (atividade.solicitacaoDesenvolvimento.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                } else if (atividade.chamado) {
                    if (atividade.chamado.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                }
            });
            return total;
        }


        function carregarTemposPorSistemaCliente(cliente, sistema) {
            var total = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (atividade.solicitacaoDesenvolvimento) {
                    if (atividade.solicitacaoDesenvolvimento.sistema.id === sistema.id
                        && atividade.solicitacaoDesenvolvimento.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                } else if (atividade.chamado) {
                    if (atividade.chamado.sistema.id === sistema.id
                        && atividade.chamado.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                }
            });
            return total;
        }

        function getTempoTotalPorUsuario(login, cliente, sistema) {
            var total = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (atividade.solicitacaoDesenvolvimento) {
                    if (atividade.user.login === login
                        && atividade.solicitacaoDesenvolvimento.sistema.id === sistema.id
                        && atividade.solicitacaoDesenvolvimento.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                } else if (atividade.chamado) {
                    if (atividade.user.login === login
                        && atividade.chamado.sistema.id === sistema.id
                        && atividade.chamado.cliente.id === cliente.id) {
                        total = total + atividade.tempo;
                    }
                }
            });
            return total;
        }

        function getTempoPorDia(dia, login) {
            var total = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (atividade.criadoEm.getTime() === dia.getTime() && atividade.user.login === login) {
                    total = total + atividade.tempo;
                }
            });

            return total;
        }

        function trustAsHtml(value) {
            return $sanitize(value);
        };

        function getUserByLogin(value) {
            for (var i = 0; i < vm.users.length; i++) {
                var user = vm.users[i];
                if (user.login === value) {
                    return user;
                }
            }
        };

        function loadAll() {
            vm.dateArray = [];
            vm.usuariosListados = [];
            Chamado.queryTempoGasto(vm.filtro, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.atividades = data;

            angular.forEach(vm.atividades, function (atividade) {
                atividade.criadoEm = DateUtils.convertLocalDateFromServer(atividade.criadoEm);
                if (vm.usuariosListados.indexOf(atividade.user.login) < 0) {
                    vm.usuariosListados.push(atividade.user.login);
                }
            });

            var currentDate = new Date(vm.filtro.inicio);
            while (currentDate <= vm.filtro.fim) {
                var date = new Date(currentDate);
                date.setHours(0, 0, 0, 0);
                vm.dateArray.push(date);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }


        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function excluir(id) {
            vm.isSaving = true;
            vm.id = id;
            Alerta.confirm("Confirme a operação",
                "Você tem certeza que excluir esse Apontamento de Horas?",
                "error",
                confirmExcluir,
                onSaveError);
        }

        function confirmExcluir() {
            Chamado.excluirTempo({id: vm.id}, function () {
                loadAll();
                vm.isSaving = false;
                vm.id = null;
                Alerta.success("Operação realizada!", "O apontamento de horas foi removido da base de dados e do redmine!");
            });
        }

        function onSaveError() {
            vm.isSaving = false;
            Alerta.error("Operação NÃO realizada!", "não foi possível remover o registro!");

        }

        function editarApontamento(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/tempogasto/tempogasto-dialog.html',
                controller: 'TempoGastoDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return entity;
                    }
                }
            })
        }


    }
})();
