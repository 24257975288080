(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('EquipeDialogController', EquipeDialogController);

    EquipeDialogController.$inject = ['$scope', 'entity', '$uibModalInstance', 'User', 'Sistema', 'Equipe'];

    function EquipeDialogController($scope, entity, $uibModalInstance, User, Sistema, Equipe) {
        var vm = this;

        vm.equipe = entity;
        vm.clear = clear;
        vm.save = save;

        vm.indexUsuario = indexUsuario;
        vm.adicionarUsuario = adicionarUsuario;
        vm.removerUsuario = removerUsuario;
        vm.users = User.getAllInternos({});
        if (!vm.equipe.usuarios) {
            vm.equipe.usuarios = [];
        }

        vm.indexSistema = indexSistema;
        vm.adicionarSistema = adicionarSistema;
        vm.removerSistema = removerSistema;
        vm.sistemas = Sistema.query({page: 0, size: 99});

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.equipe.id !== null) {
                Equipe.update(vm.equipe, onSaveSuccess, onSaveError);
            } else {
                Equipe.save(vm.equipe, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:equipeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function indexUsuario(id) {
            for (var i = 0; i < vm.equipe.usuarios.length; i++) {
                if (vm.equipe.usuarios[i].usuario.id === id) {
                    return i;
                }
            }
            return -1;
        }

        function adicionarUsuario(user) {
            vm.equipe.usuarios.push({usuario: user});
        }

        function removerUsuario(user) {
            vm.equipe.usuarios.splice(vm.indexUsuario(user.id), 1)
        }

        function indexSistema(id) {
            for (var i = 0; i < vm.equipe.sistemas.length; i++) {
                if (vm.equipe.sistemas[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function adicionarSistema(sistema) {
            vm.equipe.sistemas.push(sistema);
        }

        function removerSistema(sistema) {
            vm.equipe.sistemas.splice(vm.indexSistema(sistema.id), 1)
        }

    }
})();
