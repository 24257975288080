(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Acao', Acao);

    Acao.$inject = ['$resource'];

    function Acao($resource) {
        var resourceUrl = 'api/acao/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            },
            'update': {method: 'PUT'},
            'byUserPendente': {
                method: 'GET',
                url: 'api/acao/by-user-pendente',
                isArray: true
            }
        });
    }
})();
