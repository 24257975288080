(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('wiki', {
                parent: 'entity',
                authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                url: '/wiki',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_CLIENTE', 'ROLE_RESPONSAVEL', 'ROLE_DESENVOLVEDOR'],
                    pageTitle: 'Visualização de Wiki'
                },
                ncyBreadcrumb: {
                    label: 'Visualização de Wiki'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/wiki/wiki-detail.html',
                        controller: 'WikiDetailController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Wiki', function ($stateParams, Wiki) {
                        return Wiki.first().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    },],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('chamado');
                        $translatePartialLoader.addPart('severidade');
                        $translatePartialLoader.addPart('tipoChamado');
                        $translatePartialLoader.addPart('complexidadeChamado');
                        $translatePartialLoader.addPart('tipoHora');
                        $translatePartialLoader.addPart('situacaoChamado');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('new-wiki', {
                parent: 'wiki',
                url: '/wiki-new?superior',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Inclusão de Wiki'
                },
                ncyBreadcrumb: {
                    label: 'Inclusão de Wiki'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/wiki/wiki-edit.html',
                        controller: 'WikiEditController',
                        controllerAs: 'vm',
                    }
                },
                params: {
                    superior: null,
                },
                resolve: {
                    entity: ['$stateParams', function ($stateParams) {
                        var wiki = {
                            titulo: null,
                            conteudo: null,
                            id: null
                        };
                        if ($stateParams.superior) {
                            wiki.superior = {id: $stateParams.superior}
                        }
                        return wiki;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('edit-wiki', {
                parent: 'wiki',
                authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                url: '/wiki-edit/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_CLIENTE', 'ROLE_RESPONSAVEL', 'ROLE_DESENVOLVEDOR'],
                    pageTitle: 'Edição de Wiki'
                },
                ncyBreadcrumb: {
                    label: 'Edição de Wiki'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/wiki/wiki-edit.html',
                        controller: 'WikiEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Wiki', function ($stateParams, Wiki) {
                        return Wiki.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('detail-wiki', {
                parent: 'wiki',
                authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                url: '/wiki-detail/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_CLIENTE', 'ROLE_RESPONSAVEL', 'ROLE_DESENVOLVEDOR'],
                    pageTitle: 'Visualização de Wiki'
                },
                ncyBreadcrumb: {
                    label: 'Visualização de Wiki'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/wiki/wiki-detail.html',
                        controller: 'WikiDetailController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Wiki', function ($stateParams, Wiki) {
                        return Wiki.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'home',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('delete-wiki', {
                authorities: ['ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],

                parent: 'wiki',
                url: '/wiki-delete/{id}',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_CLIENTE'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/wiki/wiki-delete-dialog.html',
                        controller: 'WikiDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Wiki', function (Wiki) {
                                return Wiki.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('home', null, {reload: 'home'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
