(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('agrupador', {
                parent: 'entity',
                url: '/agrupador?page&sort&search&clienteId',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'chamadosApp.agrupador.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/agrupador/agrupador.html',
                        controller: 'AgrupadorController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    clienteId: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            clienteId: $stateParams.clienteId
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('agrupador');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('agrupador.new', {
                parent: 'agrupador',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/agrupador/agrupador-dialog.html',
                        controller: 'AgrupadorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    nome: null,
                                    url: null,
                                    id: null,
                                    cliente: null,
                                    criadoEm: null,
                                    previsaoEntrega: null,
                                    chamados: []
                                };
                            },
                            previousState: ["$state", function ($state) {
                                var currentStateData = {
                                    name: $state.current.name || 'home',
                                    params: $state.params,
                                    url: $state.href($state.current.name, $state.params)
                                };
                                return currentStateData;
                            }]
                        }
                    }).result.then(function (previousState) {
                        $state.go(previousState.name);
                    }, function () {
                        $state.go('agrupador');
                    });
                }]
            })
            .state('agrupador.edit', {
                parent: 'agrupador',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal',
                    function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/agrupador/agrupador-dialog.html',
                            controller: 'AgrupadorDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                entity: ['Agrupador', function (Agrupador) {
                                    return Agrupador.get({id: $stateParams.id}).$promise;
                                }],
                                previousState: ["$state", function ($state) {
                                    var currentStateData = {
                                        name: $state.current.name || 'home',
                                        params: $state.params,
                                        url: $state.href($state.current.name, $state.params)
                                    };
                                    return currentStateData;
                                }]
                            },

                        }).result.then(function (previousState) {
                            $state.go(previousState.name);
                        }, function () {
                            $state.go('^');
                        });
                    }]
            })
    }

})();
