(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AgrupadorController', AgrupadorController);

    AgrupadorController.$inject = ['$scope', '$state', 'Agrupador', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Alerta', 'Cliente'];

    function AgrupadorController($scope, $state, Agrupador, ParseLinks, AlertService, paginationConstants, pagingParams, Alerta, Cliente) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.remover = remover;
        vm.loadAll = loadAll;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.selecionarCliente = selecionarCliente;
        vm.filtro = pagingParams.search ? pagingParams.search : "";

        loadAll();

        function carregarClientes() {
            Agrupador.contarPorCliente({}, function (data) {
                vm.clientes = data;
                if (pagingParams.clienteId) {
                    for (var i = 0; i < vm.clientes.length; i++) {
                        if (vm.clientes[i].id == pagingParams.clienteId) {
                            vm.cliente = vm.clientes[i];
                            break;
                        }
                    }
                } else {
                    vm.cliente = data[0];
                }
                carregarAplicacoes();
            })
        }

        function loadAll() {
            carregarClientes();
        }

        function carregarAplicacoes() {
            Agrupador.query({
                clienteId: vm.cliente.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                filtro: vm.filtro,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.agrupadores = [];
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
                vm.agrupadores = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function selecionarCliente(cliente) {
            vm.cliente = cliente;
            carregarAplicacoes();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.filtro,
                clienteId: vm.cliente.id,
            });
        }

        function remover(id) {
            Alerta.confirm("Atenção", "Tem certeza que deseja remover esse Agrupador?", "warning", function () {
                Agrupador.delete({id: id},
                    function () {
                        Alerta.success("Operação realizada", "O Agrupador foi removido!");
                        loadAll();
                    });
            });
        }
    }
})();
