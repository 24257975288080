(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('FeedBackDialogController', FeedBackDialogController);

    FeedBackDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FeedBack', 'User', 'Sistema'];

    function FeedBackDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, FeedBack, User, Sistema) {
        var vm = this;

        vm.feedBack = entity;
        vm.clear = clear;
        vm.save = save;


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.feedBack.id !== null) {
                FeedBack.update(vm.feedBack, onSaveSuccess, onSaveError);
            } else {
                FeedBack.save(vm.feedBack, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:FeedBackUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
