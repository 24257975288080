(function() {
    'use strict';

    angular
        .module('chamadosApp')
        .directive('escapeHtml', escapeHtml);


    escapeHtml.$inject =['$sanitize'];

    function escapeHtml ($sanitize) {

        var directive = {
            restrict: 'E',
            scope: {
                conteudo: '=conteudo'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs) {
            if (!scope.conteudo) {
                return;
            }

            element.text((scope.conteudo));

        }
    }

})();
