(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosPorReleaseController', ChamadosPorReleaseController);

    ChamadosPorReleaseController.$inject = ['ChamadosPorRelease', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', 'Release', '$filter', 'Cliente'];

    function ChamadosPorReleaseController(ChamadosPorRelease, DateUtils, Modulo, SubModulo, Sprint, Release, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        vm.diasSemMovimentar = 1;


        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        vm.imprimir = imprimir;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
            });
        }

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header'},
                    {text: 'Relatório de chamados por releases', style: 'subheader'},
                    {text: vm.chamado.modulo ? 'Módulo: ' + vm.chamado.modulo.descricao : " "},
                    {text: vm.chamado.situacao ? 'Situação: ' + vm.chamado.situacao : " "},
                    releasesImpressao()
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function releasesImpressao() {
            var releasesImprimir = [];

            angular.forEach(vm.realsesProntas, function (release) {
                releasesImprimir.push(
                    {text: release.versao, style: 'header', alignment: 'center', margin: [5, 10, 5, 10]},
                    {
                        text: release.liberacao ?
                            "Solicitado em: " + $filter('date')(release.solicitacao, 'mediumDate') + " Liberado em:" + $filter('date')(release.liberacao, 'mediumDate')
                            : " Aguardando Liberação em Produção ",
                        style: 'subheader', alignment: 'center'
                    },
                    table(getChamadosParaImpressao(release), ['Chamado', 'Tipo', 'Título', 'Tempo Est.', 'Módulo', 'Situação'])
                );

            });

            return releasesImprimir;

        }

        function loadAll() {
            vm.chamadosAgrupados = {};
            vm.realsesProntas = [];
            ChamadosPorRelease.query(vm.chamado, function (data) {
                vm.chamados = data;
                angular.forEach(vm.chamados, function (chamado) {
                    if (!vm.chamadosAgrupados[chamado.release.versao]) {
                        vm.realsesProntas.push(chamado.release);
                        vm.chamadosAgrupados[chamado.release.versao] = {release: chamado.release, chamados: []};
                    }
                    vm.chamadosAgrupados[chamado.release.versao].chamados.push(chamado);
                })

            });
        }


        function getChamadosParaImpressao(release) {
            var chamados = [];
            angular.forEach(vm.chamadosAgrupados[release.versao].chamados, function (chamado) {
                chamados.push(
                    {
                        'Chamado': chamado.id,
                        'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado),
                        'Título': chamado.titulo,
                        'Tempo Est.': chamado.tempoEstimado,
                        'Módulo': chamado.modulo.descricao,
                        'Situação': $filter('translate')('chamadosApp.SituacaoChamado.' + chamado.situacao)
                    }
                )
            });
            return chamados;
        }
    }

})();


