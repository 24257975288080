(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('PesquisaController', PesquisaController);

    PesquisaController.$inject = ['$scope', 'Principal', '$state',
        'Chamado', 'SolicitacaoDesenvolvimento', 'ParseLinks', 'AlertService', 'paginationConstants',
        'pagingParams', 'Ata'];

    function PesquisaController($scope, Principal, $state,
                                Chamado, SolicitacaoDesenvolvimento, ParseLinks, AlertService, paginationConstants,
                                pagingParams, Ata) {
        var vm = this;

        vm.loadAll = loadAll;

        if (pagingParams.search) {
            vm.search = pagingParams.search;
            loadAll();
        }

        function loadAll() {
            vm.chamado = {};

            var texto = vm.search.match(/[^0-9]/g);
            if (texto) {
                vm.chamado.titulo = vm.search;
            } else {
                var numero = vm.search.match(/\d+$/);
                vm.chamado.id = numero ? numero [0] : null;
            }

            vm.chamado.size = 999;
            vm.chamado.page = 0;

            Chamado.queryByFiltro(vm.chamado, function (data) {
                vm.chamados = data;

                SolicitacaoDesenvolvimento.query({
                    page: 0,
                    size: 999,
                    filtro: vm.search
                }, function (data) {
                    vm.solicitacaoDesenvolvimentos = data;

                    Ata.query({
                        page: 0,
                        size: 999,
                        filtro: vm.search
                    }, function (data) {
                        vm.atas = data;
                        if (vm.chamados.length === 1 && vm.solicitacaoDesenvolvimentos.length === 0 && vm.atas.length === 0) {
                            $state.go("edit-chamado", {id: vm.chamados[0].id, sitaucao: null, search: null});
                        }
                        if (vm.chamados.length === 0 && vm.solicitacaoDesenvolvimentos.length === 1 && vm.atas.length === 0) {
                            $state.go("solicitacaoDesenvolvimento.detail", {id: vm.solicitacaoDesenvolvimentos[0].id});
                        }
                        if (vm.chamados.length === 0 && vm.solicitacaoDesenvolvimentos.length === 0 && vm.atas.length === 1) {
                            $state.go("detail-ata", {id: vm.atas[0].id});
                        }
                    }, onError)


                }, onError);
            }, onError);


            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
