(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SprintDetailController', SprintDetailController);

    SprintDetailController.$inject = ['$scope', '$rootScope', '$uibModal', 'Alerta', 'previousState', 'entity', 'Sprint', 'Chamado', '$state', 'SolicitacaoDesenvolvimento', 'Ata', 'Sistema', '$sce'];

    function SprintDetailController($scope, $rootScope, $uibModal, Alerta, previousState, entity, Sprint, Chamado, $state, SolicitacaoDesenvolvimento, Ata, Sistema, $sce) {
        var vm = this;

        vm.sprint = entity;
        vm.previousState = previousState.name;
        vm.horasPlanejadas = 0;
        vm.horasPlanejadasSprintAberta = 0;
        vm.tempos = {};
        vm.tempoTotalGasto = 0;
        vm.modulosSprintAndamento = [];
        vm.modulosSprintAberta = [];
        vm.encerrarSprint = encerrarSprint;
        vm.criarPlanejamento = criarPlanejamento;
        vm.criarEncerramento = criarEncerramento;
        vm.criarReuniaoDiaria = criarReuniaoDiaria;

        var unsubscribe = $rootScope.$on('chamadosApp:sprintUpdate', function (event, result) {
            vm.sprint = result;
        });
        $scope.$on('$destroy', unsubscribe);


        function buildBashboardUrl() {
            var dashBoardUrl = "https://dashboard.webpublico.app.br/public/dashboard/6284eb5c-8fe2-419c-bb48-f2fc985570e9?sprint="+vm.sprint.id;
            vm.dashBoardUrl = $sce.trustAsResourceUrl(dashBoardUrl);
        }

        function loadChamados() {

            vm.chamado = {sprint: vm.sprint};
            Chamado.queryByFiltro(vm.chamado, function (data) {
                vm.chamados = data;
            });


            Sistema.getByUser({}, function (data) {
                vm.sistemas = data;

                angular.forEach(vm.sistemas, function (sistema) {

                    Chamado.queryBySprint({sprintId: vm.sprint.id, sistemaId: sistema.id}, function (chamados) {
                        vm.sprint.chamados = chamados;
                        vm.sprint.horasDisponiveis = 0;

                        angular.forEach(vm.sprint.clientes, function (cliente) {
                            vm.sprint.horasDisponiveis = vm.sprint.horasDisponiveis + cliente.horasDisponiveis;
                        });

                        angular.forEach(chamados, function (chamado) {

                            var index = -1;
                            angular.forEach(vm.modulosSprintAndamento, function (modulo, ind) {
                                if (modulo.modulo.id === chamado.modulo.id) {
                                    index = ind;
                                }
                            });

                            if (index < 0) {
                                var novoModulo = {
                                    modulo: chamado.modulo,
                                    chamadosAFazer: 0,
                                    chamadosFazendo: 0,
                                    chamadosEntregues: 0,
                                }
                                vm.modulosSprintAndamento.push(novoModulo);
                                index = vm.modulosSprintAndamento.indexOf(novoModulo);
                            }


                            if (chamado.situacao === 'EM_DESENVOLVIMENTO') {
                                vm.modulosSprintAndamento[index].chamadosFazendo = vm.modulosSprintAndamento[index].chamadosFazendo + 1;
                            }

                            if (chamado.situacao !== 'EM_DESENVOLVIMENTO') {
                                vm.modulosSprintAndamento[index].chamadosEntregues = vm.modulosSprintAndamento[index].chamadosEntregues + 1;
                            }

                            Chamado.getSolicitacoes({id: chamado.id}, function (solicitacoes) {
                                chamado.solicitacoes = solicitacoes;

                                angular.forEach(solicitacoes, function (sol, ind) {
                                    if (sol.situacao === 'A_FAZER' && chamado.situacao === 'EM_DESENVOLVIMENTO') {
                                        vm.modulosSprintAndamento[index].chamadosAFazer = vm.modulosSprintAndamento[index].chamadosAFazer + 1;
                                    }


                                        SolicitacaoDesenvolvimento.getTempoGastoByTicket({id: sol.id}, function (data) {
                                            adicionarTempo(data);
                                        });



                                });
                            });

                            vm.horasPlanejadas = vm.horasPlanejadas + chamado.tempoEstimado;
                        });
                    });
                });

                angular.forEach(vm.sistemas, function (sistema) {
                    Sprint.getBySituacao({situacao: 'ABERTA'}, function (aberta) {
                        Chamado.queryBySprint({sprintId: aberta.id, sistemaId: sistema.id}, function (chamados) {
                            aberta.chamados = chamados;
                            vm.sprintAberta = aberta;
                            vm.sprintAberta.horasDisponiveis = 0;

                            angular.forEach(vm.sprintAberta.clientes, function (cliente) {
                                vm.sprintAberta.horasDisponiveis = vm.sprintAberta.horasDisponiveis + cliente.horasDisponiveis;
                            });

                            angular.forEach(chamados, function (chamado) {
                                var index = -1;
                                angular.forEach(vm.modulosSprintAberta, function (modulo, ind) {
                                    if (modulo.modulo.id === chamado.modulo.id) {
                                        index = ind;
                                    }
                                });

                                if (index < 0) {
                                    var novoModulo = {
                                        modulo: chamado.modulo,
                                        chamadosAFazer: 0,
                                        tempoEstimado: 0,
                                        chamadosFazendo: 0,
                                        chamadosEntregues: 0,
                                        chamadosSemTarefas: [],
                                    }
                                    vm.modulosSprintAberta.push(novoModulo);
                                    index = vm.modulosSprintAberta.indexOf(novoModulo);
                                }


                                vm.modulosSprintAberta[index].tempoEstimado = vm.modulosSprintAberta[index].tempoEstimado + chamado.tempoEstimado;

                                if (chamado.situacao === 'AGUARDANDO_DESENVOLVIMENTO' || chamado.situacao === 'EM_ANALISE' || chamado.situacao === 'EM_ANALISE_DESENVOLVIMENTO'  ) {
                                    vm.modulosSprintAberta[index].chamadosAFazer = vm.modulosSprintAberta[index].chamadosAFazer + 1;
                                }else{
                                    vm.modulosSprintAberta[index].chamadosEntregues = vm.modulosSprintAberta[index].chamadosEntregues + 1;
                                }
                                if (chamado.situacao === 'EM_DESENVOLVIMENTO') {
                                    vm.modulosSprintAberta[index].chamadosFazendo = vm.modulosSprintAberta[index].chamadosFazendo + 1;
                                }


                                Chamado.getSolicitacoes({id: chamado.id}, function (solicitacoes) {
                                    chamado.solicitacoes = solicitacoes;


                                    if (solicitacoes.length === 0) {
                                        vm.modulosSprintAberta[index].chamadosSemTarefas.push(chamado);
                                    }
                                });

                                vm.horasPlanejadasSprintAberta = vm.horasPlanejadasSprintAberta + chamado.tempoEstimado;
                            });
                        });
                    });


                });

            });

        }

        loadChamados();
        buildBashboardUrl();

        function confirmEncerrar() {
            Sprint.encerrar(vm.sprint, function (data) {
                Alerta.success("Operação realizada", "A Sprint foi encerrada!")
                $state.go('sprint')
            }, function error(error) {
                Alerta.error("Operação Não realizada", "Tente novamente!")
            });
        }

        function cancel() {
            //Alerta.success("Operação NÃO Realizada", "")
        }


        function encerrarSprint() {
            Alerta.confirm("Atenção!",
                "Você tem certeza que deseja encerrar a Srpint " + vm.sprint.descricao + "?",
                "warning",
                confirmEncerrar, cancel);
        }

        function criarPlanejamento(sprint) {
            Ata.criarPlanejamento(sprint, function (data) {
                $state.reload(true);
            });
        }

        function criarEncerramento(sprint) {
            Ata.criarEncerramento(sprint, function (data) {
                $state.reload(true);
            });
        }

        function criarReuniaoDiaria(sprint) {
            Ata.criarReuniaoDiaria(sprint, function (data) {
                $state.reload(true);
            });
        }

        function adicionarTempo(data) {
            angular.forEach(data, function (tempo) {
                vm.tempoTotalGasto = vm.tempoTotalGasto + tempo.tempo;
                if (!vm.tempos[tempo.tipo]) {
                    vm.tempos[tempo.tipo] = {
                        tipo: tempo.tipo,
                        tempo: tempo.tempo
                    };
                } else {
                    vm.tempos[tempo.tipo].tempo = vm.tempos[tempo.tipo].tempo + tempo.tempo;
                }
            });
        }
    }
})();
