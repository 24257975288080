(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ImagemController', ImagemController);

    ImagemController.$inject = ['$scope', '$q', 'Principal', 'AnexoChamado', '$state', 'Upload', '$window', '$uibModalInstance', 'Alerta'];

    function ImagemController($scope, $q, Principal, AnexoChamado, $state, Upload, $window, $uibModalInstance, Alerta) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;

        vm.clear = clear;
        vm.submit = submit;
        vm.upload = upload;
        vm.rotateImage = rotateImage;
        vm.ok = ok;
        vm.progressPercentage = null;
        vm.carregando = false;

        vm.fileOriginal = null;
        vm.cropper = {sourceImage: null, croppedImage: null};
        vm.bounds = {left: 0, right: 0, top: 0, bottom: 0};

        vm.cropper.sourceImage = vm.fileOriginal;

        function ok() {
            console.log("vm.cropper ", vm.cropper);
            submit();
        }


        function clear() {
            $uibModalInstance.dismiss('close');
        }


        function rotateImage() {
            vm.carregando = true;
            convertImage(function () {
                vm.carregando = false;
            });
        }

        function convertImage(callback) {
            var img = new Image();
            img.src = vm.cropper.sourceImage;
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext("2d");
            canvas.width = img.height;
            canvas.height = img.width;
            ctx.drawImage(img, 0, 0);
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            vm.cropper.sourceImage = canvas.toDataURL();
            callback();
        }


        function submit() {
            if (vm.anexo) {
                vm.carregando = true;
                AnexoChamado.upload(vm.anexo, function (data) {
                    vm.anexo = data;
                    $uibModalInstance.close(data);
                    vm.carregando = false;
                }, function (error) {
                    vm.carregando = false;
                });
            }
        }


        function upload($file) {
            if ($file) {
                vm.anexo = {};
                vm.anexo.descricao = $file.name;
                vm.anexo.mimeType = $file.type;
                Upload.dataUrl($file, true).then(
                    function (conteudo) {
                        vm.fileOriginal = conteudo;
                        vm.anexo.conteudo = conteudo;
                    }
                );
            } else {
                Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 10Mb");
            }
        }


    }
})();

