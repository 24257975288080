(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('AcompanhamentoSprint', AcompanhamentoSprint);

    AcompanhamentoSprint.$inject = ['$resource', 'DateUtils'];

    function AcompanhamentoSprint($resource, DateUtils) {
        var resourceUrl = 'api/acompanhamento-sprint';

        return $resource(resourceUrl, {}, {
            'getSprintRedmine': {
                url: 'api/sprints-redmine/:id',
                method: 'GET',
                isArray: true
            },
            'getTicketsRedmine': {
                url: 'api/tickets-redmine/:id',
                method: 'GET'
            },
            'getTicketsRedminePorUsuario': {
                url: 'api/tickets-redmine/:idSprint/:idUser',
                method: 'GET'
            },
            'getUsersRedmine': {
                url: 'api/usuarios-redmine/:id',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
