(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ClienteDialogController', ClienteDialogController);

    ClienteDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Cliente', 'User', 'Sistema'];

    function ClienteDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Cliente, User, Sistema) {
        var vm = this;

        vm.cliente = entity;
        vm.clear = clear;
        vm.save = save;
        vm.indexSistema = indexSistema;
        vm.adicionarSistema = adicionarSistema;
        vm.removerSistema = removerSistema;
        vm.sistemas = Sistema.query({page: 0, size: 99});

        if (!vm.cliente.sistemas) {
            vm.cliente.sistemas = [];
        }

        vm.users = User.query({
            page: 0,
            size: 100,
            filtro: ''
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.cliente.id !== null) {
                Cliente.update(vm.cliente, onSaveSuccess, onSaveError);
            } else {
                Cliente.save(vm.cliente, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:clienteUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function indexSistema(id) {
            for (var i = 0; i < vm.cliente.sistemas.length; i++) {
                if (vm.cliente.sistemas[i].id === id) {
                    return i;
                }
            }
            return -1;
        }

        function adicionarSistema(sistema) {
            vm.cliente.sistemas.push(sistema);
        }

        function removerSistema(sistema) {
            vm.cliente.sistemas.splice(vm.indexSistema(sistema.id), 1)

        }

    }
})();
