(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosPorSprint', ChamadosPorSprint);

    ChamadosPorSprint.$inject = ['$resource'];

    function ChamadosPorSprint($resource) {
        var resourceUrl = 'api/chamados-por-sprint';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            }

        });
    }


})();
