(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(configTextArea);


    configTextArea.$inject = ['$provide'];

    function configTextArea($provide) {

        $provide.decorator('taOptions', ['taRegisterTool', '$delegate', '$uibModal', function (taRegisterTool, taOptions, $uibModal) { // $delegate is the taOptions we are decorating

            taRegisterTool('customImageUpload', {
                iconclass: "fa fa-image",
                action: function ($deferred, restoreSelection) {
                    var textAngular = this;

                    var modalInstance = $uibModal.open({
                        size: 'lg',
                        animation: true,
                        templateUrl: 'app/entities/imagem/imagem-upload.html',
                        controller: 'ImagemController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                    });

                    modalInstance.result.then(function (anexo) {
                        restoreSelection();
                        textAngular.$editor().wrapSelection('insertImage', anexo.conteudo);
                        $deferred.resolve();
                    });
                    return false;
                }
            });
            taOptions.toolbar[3].push('customImageUpload');
            taOptions.toolbar[3].splice(taOptions.toolbar[3].indexOf('insertImage'), 1);
            return taOptions;
        }]);
    }

})();
