(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('solicitacaoDesenvolvimento', {
                parent: 'entity',
                url: '/tarefa?page&sort&search',
                data: {
                    authorities: ['ROLE_DESENVOLVEDOR', 'ROLE_PO', 'ROLE_SCRUM', 'ROLE_ATENDENTE'],
                    pageTitle: 'chamadosApp.solicitacaoDesenvolvimento.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/solicitacaodesenvolvimento/solicitacaodesenvolvimento.html',
                        controller: 'SolicitacaoDesenvolvimentoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('situacaoTarefa');
                        $translatePartialLoader.addPart('tipoHora');
                        return $translate.refresh();
                    }]
                }
            })
            .state('solicitacaoDesenvolvimento.new', {
                parent: 'solicitacaoDesenvolvimento',
                url: 'new',
                data: {
                    authorities: ['ROLE_PO', 'ROLE_DESENVOLVEDOR', 'ROLE_SCRUM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/solicitacaodesenvolvimento/solicitacaodesenvolvimento-edit.html',
                        controller: 'SolicitacaoDesenvolvimentoEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {situacao: 'A_FAZER', chamado: {situacao: 'EM_DESENVOLVIMENTO'}};
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('tipoHora');
                        return $translate.refresh();
                    }]
                }
            })
            .state('solicitacaoDesenvolvimento.edit', {
                parent: 'solicitacaoDesenvolvimento',
                url: 'tarefa/{id}/edit',
                data: {
                    authorities: ['ROLE_PO', 'ROLE_SCRUM'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/solicitacaodesenvolvimento/solicitacaodesenvolvimento-edit.html',
                        controller: 'SolicitacaoDesenvolvimentoEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'SolicitacaoDesenvolvimento', function ($stateParams, SolicitacaoDesenvolvimento) {
                        return SolicitacaoDesenvolvimento.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('tipoHora');
                        return $translate.refresh();
                    }]

                }
            })
            .state('solicitacaoDesenvolvimento.detail', {
                parent: 'solicitacaoDesenvolvimento',
                url: '/{id}/detail',
                data: {
                    authorities: ['ROLE_DESENVOLVEDOR', 'ROLE_PO', 'ROLE_SCRUM', 'ROLE_ATENDENTE'],
                },
                ncyBreadcrumb: {
                    label: 'Tarefa'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/solicitacaodesenvolvimento/solicitacaodesenvolvimento-detail.html',
                        controller: 'SolicitacaoDesenvolvimentoDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'SolicitacaoDesenvolvimento', function ($stateParams, SolicitacaoDesenvolvimento) {
                        return SolicitacaoDesenvolvimento.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('tipoHora');
                        $translatePartialLoader.addPart('complexidadeChamado');
                        return $translate.refresh();
                    }]

                }
            })
            .state('solicitacaoDesenvolvimento.importar', {
                parent: 'entity',
                url: '/importar',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/solicitacaodesenvolvimento/solicitacaodesenvolvimento-importar.html',
                        controller: 'SolicitacaoDesenvolvimentoImportarController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    tipo: 'HISTORIA',
                                    situacao: 'A_FAZER',
                                    id: null
                                };
                            }
                        }
                    }).result.then(function (result) {
                        if (result && result.id) {
                            $state.go("solicitacaoDesenvolvimento.detail", {id: result.id});
                        } else {
                            $state.go('solicitacaoDesenvolvimento', null, {reload: 'solicitacaoDesenvolvimento'});
                        }
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }
})();
