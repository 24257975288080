(function() {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('SubModulo', SubModulo);

    SubModulo.$inject = ['$resource'];

    function SubModulo ($resource) {
        var resourceUrl =  'api/sub-modulos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryByModulo': {
                url : 'api/sub-modulos-by-modulo-user/:id',
                method: 'GET',
                isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
