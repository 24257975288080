(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosPorSprintController', ChamadosPorSprintController);

    ChamadosPorSprintController.$inject = ['ChamadosPorSprint', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', 'Release', '$filter', 'Cliente'];

    function ChamadosPorSprintController(ChamadosPorSprint, DateUtils, Modulo, SubModulo, Sprint, Release, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        vm.diasSemMovimentar = 1;


        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        vm.imprimir = imprimir;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
            });
        }

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header', alignment: 'center'},
                    {text: 'Relatório de Acompanhamento dos Chamados por Ciclo', style: 'subheader'},
                    {text: vm.chamado.modulo ? 'Módulo: ' + vm.chamado.modulo.descricao : " "},
                    {text: vm.chamado.situacao ? 'Situação: ' + vm.chamado.situacao : " "},
                    {
                        text: vm.chamado.sprint ? 'Período: '
                            + " ( " + $filter('date')(vm.chamado.sprint.inicio, 'mediumDate') + " até " + $filter('date')(vm.chamado.sprint.fim, 'mediumDate') + ")" : " "
                    },
                    sprintsImpressao()
                ],
                footer: function (currentPage, pageCount) {
                    return {
                        margin: 10,
                        columns: [
                            {
                                fontSize: 9,
                                text: [
                                    {
                                        text: '______________________________________________________________' +
                                            '\n',
                                        margin: [0, 20]
                                    },
                                    {
                                        text: '© MGA - Gestão Pública. Página ' + currentPage.toString() + ' de ' + pageCount,
                                    }
                                ],
                                alignment: 'center'
                            }
                        ]
                    };
                },
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function sprintsImpressao() {
            var sprintsImprimir = [];

            angular.forEach(vm.sprintsProntas, function (sprint) {
                sprintsImprimir.push(
                    {text: sprint.descricao, style: 'header', alignment: 'center', margin: [5, 10, 5, 10]},
                    {
                        text: $filter('date')(sprint.inicio, 'mediumDate') + " até " + $filter('date')(sprint.fim, 'mediumDate'),
                        style: 'subheader', alignment: 'center'
                    },
                    table(getChamadosParaImpressao(sprint), ['Chamado', 'Tipo', 'Título', 'Tempo Est.', 'Módulo', 'Situação'])
                );

            });

            return sprintsImprimir;

        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function loadAll() {
            vm.chamadosAgrupados = {};
            vm.sprintsProntas = [];
            ChamadosPorSprint.query(vm.chamado, function (data) {
                vm.chamados = data;
                angular.forEach(vm.chamados, function (chamado) {
                    if (!vm.chamadosAgrupados[chamado.sprint.descricao]) {
                        vm.sprintsProntas.push(chamado.sprint);
                        vm.chamadosAgrupados[chamado.sprint.descricao] = {sprint: chamado.sprint, chamados: []};
                    }
                    vm.chamadosAgrupados[chamado.sprint.descricao].chamados.push(chamado);
                })

            });
        }


        function getChamadosParaImpressao(sprint) {
            var chamados = [];
            angular.forEach(vm.chamadosAgrupados[sprint.descricao].chamados, function (chamado) {
                chamados.push(
                    {
                        'Chamado': chamado.id,
                        'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado),
                        'Título': chamado.titulo,
                        'Tempo Est.': chamado.tempoEstimado,
                        'Módulo': chamado.modulo.descricao,
                        'Situação': $filter('translate')('chamadosApp.SituacaoChamado.' + chamado.situacao)
                    }
                )
            });
            return chamados;
        }

    }

})();


