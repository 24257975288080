(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('WikiDetailController', WikiDetailController);

    WikiDetailController.$inject = ['$scope', 'entity', 'Wiki', 'AlertService'];

    function WikiDetailController($scope, entity, Wiki, AlertService) {
        var vm = this;
        vm.wiki = entity;



        Wiki.query({
            filtro: vm.filtro
        }, onSuccess, onError);

        function onSuccess(dwiki) {
            vm.wikis = dwiki;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }
    }
})();
