(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector'];

    function authExpiredInterceptor($rootScope, $q, $injector) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            console.error(response);
            // If we have an unauthorized request we redirect to the login page
            // Don't do this check on the account API to avoid infinite loop
            if (response.status === 401 && angular.isDefined(response.data.path) && response.data.path.indexOf('/api/account') === -1) {
                var Auth = $injector.get('Auth');
                var to = $rootScope.toState;
                var params = $rootScope.toStateParams;
                Auth.logout();
                if (to.name !== 'accessdenied') {
                    Auth.storePreviousState(to.name, params);
                }
                var $state = $injector.get('$state');
                if ($state.parent !== 'account') {
                    $state.go('login');
                }
            }
            // }else if(response.status === 401){
            //     var $state = $injector.get('$state');
            //     var Alerta = $injector.get('Alerta');
            //     $state.go('home');
            //     Alerta.error("Atenção", "Você não tem permissão para acesso a esse conteúdo");
            // }


            if (response.status === 409) {
                var Alerta = $injector.get('Alerta');
                Alerta.error("Atenção", "O registro que você está tentando salvar está em uma versão diferente, provavelmente foi alterado por outro usuário, atualize a página e tente novamente");
            }
            if (response.status === 400) {
                var Alerta = $injector.get('Alerta');
                if (response.data.fieldErrors && response.data.fieldErrors.length > 0) {
                    var campos = "";
                    for (var i = 0; i < response.data.fieldErrors.length; i++) {
                        campos = response.data.fieldErrors[i];
                    }
                    Alerta.error("Atenção", campos);
                } else if (response.data.message) {
                    Alerta.error(response.data.message);
                } else if (response.message) {
                    Alerta.error(response.message);
                } else if (response.data.error == 'invalid_grant') {
                    //Alerta.error("Error - " + [status]);
                } else {
                    var headers = null;
                    angular.forEach(Object.keys(response.headers()), function (header) {
                        if(header.contains('alert')){
                            headers = header;
                        }
                    });
                    if(headers != null){
                        var mensagem = response.headers(headers);
                        Alerta.error(mensagem);
                    }
                }
            }
            if (status === 500) {
                Alerta.error(" Error - " + [status]);
            }


            return $q.reject(response);
        }
    }
})();
