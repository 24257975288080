(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosPorFilaController', ChamadosPorFilaController);

    ChamadosPorFilaController.$inject = ['ChamadosPorFila', 'Chamado', 'Modulo', 'SubModulo', 'Sprint', 'Release', '$filter', 'Cliente'];

    function ChamadosPorFilaController(ChamadosPorFila, Chamado, Modulo, SubModulo, Sprint, Release, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});
        vm.releases = Release.query({page: 0, size: 99});
        Chamado.getSituacoes({}, function (data) {
            vm.situacoes = data;
            vm.chamado.situacoes = [];

            angular.forEach(vm.situacoes, function (situacao) {
                if (situacao !== 'FECHADO' && situacao !== 'IMPEDIDO' && situacao !== 'MIGRACAO_REDMINE') {
                    vm.chamado.situacoes.push(situacao);
                }
            });
        });
        vm.diasSemMovimentar = 1;
        vm.getIconePorSituacao = getIconePorSituacao;

        Cliente.getByUser({}, function (data) {
            vm.clientes = data;
            vm.chamado.cliente = data[0];
        });

        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        vm.imprimir = imprimir;

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 60, 40, 60],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header', alignment: 'center'},
                    {text: 'Relatório de Acompanhamento dos Chamados por Fila', style: 'subheader'},
                    {text: vm.chamado.modulo ? 'Módulo: ' + vm.chamado.modulo.descricao : " "},
                    {text: vm.chamado.situacao ? 'Situação: ' + vm.chamado.situacao : " "},
                    {
                        text: vm.chamado.sprint ? 'Período: '
                            + " ( " + $filter('date')(vm.chamado.sprint.inicio, 'mediumDate') + " até " + $filter('date')(vm.chamado.sprint.fim, 'mediumDate') + ")" : " "
                    },
                    filasImpressao()
                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    footer: {
                        fontSize: 14,
                        italic: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function filasImpressao() {
            var filasImprimir = [];

            angular.forEach(vm.filasProntas, function (fila) {
                if (vm.chamadosAgrupados[fila]) {
                    filasImprimir.push(
                        {
                            text: $filter('translate')('chamadosApp.SituacaoChamado.' + fila),
                            style: 'header',
                            alignment: 'center',
                            margin: [5, 10, 5, 10]
                        },
                        table(getChamadosParaImpressao(fila), ['Severidade', 'Tipo', 'Chamado', 'Título', 'Módulo', 'Inicio Dev.', 'Fim Dev.', 'Lib. Teste', 'Validado', 'Última Mov.']),
                        {
                            text: " Total de Chamados na fila " + $filter('translate')('chamadosApp.SituacaoChamado.' + fila) + ": " + vm.chamadosAgrupados[fila].chamados.length,
                            style: 'footer', alignment: 'center', margin: [5, 10, 5, 10]
                        }
                    );
                }
            });

            return filasImprimir;

        }

        function table(data, columns) {
            return {
                table: {
                    margin: [5, 10, 5, 10],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function getChamadosParaImpressao(situacao) {
            var chamados = [];
            if (vm.chamadosAgrupados[situacao]) {
                angular.forEach(vm.chamadosAgrupados[situacao].chamados, function (chamado) {
                    chamados.push(
                        {
                            'Severidade': $filter('translate')('chamadosApp.Severidade.' + chamado.severidade),
                            'Tipo': $filter('translate')('chamadosApp.TipoChamado.' + chamado.tipoChamado),
                            'Chamado': chamado.id,
                            'Título': chamado.titulo,
                            'Módulo': chamado.modulo,
                            'Início Dev.': $filter('date')(chamado.inicioDesenvolvimento, 'shortDate'),
                            'Fim Dev.': $filter('date')(chamado.fimDesenvolvimento, 'shortDate'),
                            'Lib. Teste': $filter('date')(chamado.inicioTeste, 'shortDate'),
                            'Validado': $filter('date')(chamado.fimTeste, 'shortDate'),
                            'Última Mov.': $filter('date')(chamado.ultimaInteracao, 'shortDate')
                        }
                    )
                });
            }

            return chamados;
        }


        function loadAll() {
            vm.chamadosAgrupados = {};
            ChamadosPorFila.query(vm.chamado, function (data) {
                vm.chamados = data;
                vm.filasProntas = vm.chamado.situacoes;

                angular.forEach(vm.chamados, function (chamado) {
                    if (!vm.chamadosAgrupados[chamado.situacao]) {
                        vm.chamadosAgrupados[chamado.situacao] = {situacao: chamado.situacao, chamados: []};
                    }
                    vm.chamadosAgrupados[chamado.situacao].chamados.push(chamado);
                })

            });
        }


        function getIconePorSituacao(situacao) {
            switch (situacao) {
                case 'ABERTO':
                    return 'fa-envelope-o';
                case 'IMPEDIDO':
                    return 'fa-bell';
                case 'EM_SUPORTE':
                    return 'fa-group';
                case 'EM_ANALISE':
                    return 'fa-spinner';
                case 'EM_ANALISE_DESENVOLVIMENTO':
                    return 'fa-code';
                case 'TESTE_SUPORTE':
                    return 'fa-spinner';
                case 'EM_DESENVOLVIMENTO':
                    return 'fa-code-fork';
                case 'AGUARDANDO_TESTE':
                    return 'fa-slideshare';
                case 'AGUARDANDO_VALIDACAO':
                    return 'fa-check';
                case 'FECHADO':
                    return 'fa-chack-circle-o';
                case 'REJEITADO':
                    return 'fa-thumbs-o-down';
                case 'VALIDADO':
                    return 'fa-thumbs-o-up';
                case 'AGUARDANDO_DESENVOLVIMENTO':
                    return 'fa-laptop';
                default:
                    return 'fa-circle-o';
            }
        }

    }

})();


