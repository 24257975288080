(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SubModuloDialogController', SubModuloDialogController);

    SubModuloDialogController.$inject = ['$timeout', '$scope', 'Modulo', '$uibModalInstance', 'entity', 'SubModulo', 'User', 'Cliente'];

    function SubModuloDialogController($timeout, $scope, Modulo, $uibModalInstance, entity, SubModulo, User, Cliente) {
        var vm = this;

        vm.subModulo = entity;
        vm.clear = clear;
        vm.save = save;
        vm.modulos = Modulo.query();
        vm.users = User.query({
            page: 0,
            size: 100
        });
        vm.clientes = Cliente.query({
            page: 0,
            size: 100
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.subModulo.id !== null) {
                SubModulo.update(vm.subModulo, onSaveSuccess, onSaveError);
            } else {
                SubModulo.save(vm.subModulo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:subModuloUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
