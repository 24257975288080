(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Sistema', Sistema);

    Sistema.$inject = ['$resource'];

    function Sistema($resource) {
        var resourceUrl = 'api/sistemas/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getByCliente': {url: "api/sistemas-by-cliente/:clienteId", method: 'GET', isArray: true},
            'getByUser': {url: "api/sistemas-by-user", method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }
})();
