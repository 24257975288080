(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('TempoGastoDialogController', TempoGastoDialogController);

    TempoGastoDialogController.$inject = ['Principal', '$uibModalInstance',
        'Chamado', 'AlertService', 'Ata', 'User', 'Alerta', '$timeout', 'SolicitacaoDesenvolvimento', 'entity'];

    function TempoGastoDialogController(Principal, $uibModalInstance,
                                        Chamado, AlertService, Ata,
                                        User, Alerta, $timeout, SolicitacaoDesenvolvimento, entity
    ) {
        var vm = this;

        vm.buscar = buscar;
        vm.definirTempoGasto = definirTempoGasto;
        vm.buscar = buscar;
        vm.clear = clear;
        vm.tempoGasto = entity;
        vm.branches = [];

        function load() {
            if (vm.tempoGasto.tempo) {
                var totalMinutos = vm.tempoGasto.tempo *60;
                var horas = Math.floor(totalMinutos / 60);
                var minutos = totalMinutos % 60;
                vm.tempoGasto.horas = horas;
                vm.tempoGasto.minutos = Math.floor(minutos);
            }
        }

        load();

        function buscar() {

            vm.branches = [];
            SolicitacaoDesenvolvimento.getByNumero({numero: vm.numeroBusca},
                function (ticket) {
                    vm.tempoGasto.solicitacaoDesenvolvimento = ticket;
                    vm.tempoGasto.chamado = ticket.chamado;
                    vm.branches = SolicitacaoDesenvolvimento.getBranches({id: ticket.id});
                }, function (error) {
                    Chamado.get({id: vm.numeroBusca}, function (chamado) {
                        vm.tempoGasto.chamado = chamado;
                        vm.tempoGasto.solicitacaoDesenvolvimento = null;
                    }, function (error) {
                        Ata.getByNumero({numero: vm.numeroBusca}, function (ata) {
                            vm.tempoGasto.ata = ata;
                            vm.tempoGasto.solicitacaoDesenvolvimento = null;
                            vm.tempoGasto.chamado = null;
                            vm.tempoGasto.tipo = 'CERIMONIA_SCRUM';
                        }, function (error) {
                            Alerta.error("Ops!", "Não foi possível encontrar chamado, tarefa ou ata com o número" + vm.numeroBusca + "!");
                        });
                    });
                });


        }

        function definirTempoGasto() {
            vm.isSaving = true;
            vm.tempoGasto.tempo = vm.tempoGasto.horas + (vm.tempoGasto.minutos / 60)
            Chamado.defirnirTempo(vm.tempoGasto, function (data) {
                vm.openChat = false;
                Alerta.success("Operação realizada!", "Tempo apontado com sucesso!");
                $uibModalInstance.close(data);
            });
        }


        function carregarAtividades() {
            vm.numeroBusca = '';
            if (vm.tempoGasto.chamado) {
                vm.numeroBusca = vm.tempoGasto.chamado.id
            } else if (vm.tempoGasto.solicitacaoDesenvolvimento) {
                vm.numeroBusca = vm.tempoGasto.solicitacaoDesenvolvimento.numero;
                vm.tempoGasto.chamado = vm.tempoGasto.solicitacaoDesenvolvimento.chamado;
            } else if (vm.tempoGasto.ata) {
                vm.numeroBusca = vm.tempoGasto.ata.numero;
                vm.tempoGasto.tipo = 'CERIMONIA_SCRUM';
            }

        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        carregarAtividades();

    }
})();
