(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ChamadosAtrasadosController', ChamadosAtrasadosController);

    ChamadosAtrasadosController.$inject = ['ChamadosAtrasados', 'DateUtils', 'Modulo', 'SubModulo', 'Sprint', '$filter', 'Cliente'];

    function ChamadosAtrasadosController(ChamadosAtrasados, DateUtils, Modulo, SubModulo, Sprint, $filter, Cliente) {
        var vm = this;

        vm.chamado = {id: null, modulo: null, tipoChamado: null, titulo: null};
        vm.modulos = Modulo.query({page: 0, size: 99});
        vm.subModulos = SubModulo.query({page: 0, size: 99});
        vm.sprints = Sprint.query({page: 0, size: 99});

        vm.labels = ["No Prazo", "Não entregue e fora do Prazo", "Entregue Fora do Prazo", "Entregue no Prazo"];
        vm.colors = ['#46BFBD', '#F7464A', '#FDB45C', '#97BBCD'];

        vm.loadAll = loadAll;

        vm.imprimir = imprimir;

        load();

        function load() {
            Cliente.getByUser({}, function (data) {
                vm.clientes = data;
                vm.chamado.cliente = data[0];
            });
        }

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'landscape',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [ 40, 60, 40, 60 ],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header'},
                    {text: 'Relatório de acompanhamento dos Chamados', style: 'subheader'},
                    {text: vm.chamado.modulo ? 'Módulo: ' + vm.chamado.modulo.descricao :" "},
                    {text: vm.situacaoEntrega ? 'Situação: ' + vm.situacaoEntrega :" "},
                    table(getChamadosParaImpressao(), ['Chamado', 'Titulo', 'Prazo', 'Entregue Em', 'Situacao']),

                ],
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                }
            };

            // download the PDF
            pdfMake.createPdf(docDefinition).download('Chamados_Em_Atraso.pdf');
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if(row[column]){
                        dataRow.push(row[column].toString());
                    }else{
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }

        function table(data, columns) {
            return {
                table: {
                    margin: [ 5, 10, 5, 10 ],
                    style: 'tableExample',
                    headerRows: 1,
                    body: buildTableBody(data, columns)
                },
                layout: 'lightHorizontalLines'
            };
        }

        function loadAll() {
            vm.hoje = new Date();
            vm.data = [0, 0, 0, 0];
            vm.percentual = [0, 0, 0, 0];
            vm.diasAtraso = 0;
            var atrasoPorModulo = {};
            ChamadosAtrasados.query(vm.chamado, function (data) {
                if (vm.situacaoEntrega) {
                    vm.chamados = [];
                    angular.forEach(data, function (chamado) {
                        if (chamado.situacaoEntrega === vm.situacaoEntrega) {
                            vm.chamados.push(chamado);
                        }
                    });
                } else {
                    vm.chamados = data;
                }
                angular.forEach(vm.chamados, function (chamado) {
                    if (!atrasoPorModulo[chamado.modulo]) {
                        atrasoPorModulo[chamado.modulo] = [];
                    }
                    atrasoPorModulo[chamado.modulo].push(chamado);
                    chamado.inicio = DateUtils.convertDateTimeFromServer(chamado.inicio);
                    chamado.fim = DateUtils.convertDateTimeFromServer(chamado.fim);
                    chamado.entrega = DateUtils.convertDateTimeFromServer(chamado.entrega);

                    if (!chamado.entrega && chamado.fim > vm.hoje) {
                        chamado.cor = "info";
                        vm.data[0] = vm.data[0] + 1;
                    }
                    if (!chamado.entrega && chamado.fim < vm.hoje) {
                        chamado.cor = "danger";
                        vm.data[1] = vm.data[1] + 1;
                    }
                    if (chamado.entrega && chamado.entrega > chamado.fim) {
                        chamado.cor = "warning";
                        vm.data[2] = vm.data[2] + 1;
                    }
                    if (chamado.entrega && chamado.entrega <= chamado.fim) {
                        chamado.cor = "success";
                        vm.data[3] = vm.data[3] + 1;
                    }
                    if (chamado.diasAtraso) {
                        vm.diasAtraso = vm.diasAtraso + chamado.diasAtraso;
                    }
                });
                vm.diasAtraso = vm.diasAtraso / vm.chamados.length;
                for (var i = 0; i < vm.data.length; i++) {
                    var valor = vm.data[i];
                    vm.percentual[i] = (valor * 100) / vm.chamados.length;
                }
                vm.modulosAtrasados = [];

                angular.forEach(atrasoPorModulo, function (value, key) {
                    var modulo = {modulo: key, diasAtraso: 0};
                    angular.forEach(value, function (chamado) {
                        if (chamado.diasAtraso) {
                            modulo.diasAtraso = modulo.diasAtraso + chamado.diasAtraso;
                        }
                    });
                    modulo.diasAtraso = modulo.diasAtraso / value.length;
                    vm.modulosAtrasados.push(modulo);
                });
            });
        }


        function getChamadosParaImpressao() {
            var chamados = [];
            angular.forEach(vm.chamados, function (chamado) {
                chamados.push(
                    {
                        'Chamado': chamado.id,
                        'Titulo': chamado.titulo,
                        'Prazo': 'De ' + $filter('date')(chamado.inicio) + ' Até ' + $filter('date')(chamado.fim),
                        'Entregue Em': chamado.diasAtraso ?
                            chamado.diasAtraso + (chamado.diasAtraso > 1 ? ' dias' : ' dia') + ' de atraso' : $filter('date')(chamado.entrega),
                        'Situacao': chamado.situacaoEntrega
                    }
                )
            });
            return chamados;
        }
    }

})();


