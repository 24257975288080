(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('ScrumBoardController', ScrumBoardController);

    ScrumBoardController.$inject = ['$scope', '$timeout', '$rootScope', '$filter', 'Principal', '$state', 'Chamado', 'Sprint', 'SolicitacaoDesenvolvimento', 'ChartJs'];

    function ScrumBoardController($scope, $timeout, $rootScope, $filter, Principal, $state, Chamado, Sprint, SolicitacaoDesenvolvimento, ChartJs) {
        var vm = this;
        vm.load = load;
        vm.mover = mover;
        vm.imprimir = imprimir;
        vm.definirModoApresentacao = definirModoApresentacao;
        vm.selecionaModulo = selecionaModulo;
        vm.selecionaProjeto = selecionaProjeto;
        vm.getColunaPorSituacao = getColunaPorSituacao;
        vm.usuarios = [];
        vm.sistemas = [];
        vm.projeto = null;
        vm.modulo = null;
        vm.pieSeries = ['Sprint'];
        vm.pieOptions = {legend: {display: true}};

        $rootScope.$on('chamadosApp:solicitacaoDesenvolvimentoUpdate', function () {
            if ($rootScope.modoApresentacao) {
                load();
            }
        });

        function mover(tarefa, situacao) {
            if (tarefa.situacao != situacao.situacao) {
                tarefa.situacao = situacao.situacao;
                SolicitacaoDesenvolvimento.update(tarefa, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function selecionaModulo(modulo) {
            if (vm.modulo === modulo) {
                vm.modulo = null
            } else {
                vm.modulo = modulo;
            }
            filtrar();
        }

        function selecionaProjeto(projeto) {
            if (vm.projeto === projeto) {
                vm.projeto = null
            } else {
                vm.projeto = projeto
            }
            filtrar();
        }

        function zerarColunas() {
            vm.colunas = [
                {situacao: "A_FAZER", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "FAZENDO", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "CODE_REVIEW", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "A_TESTAR", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "TESTANDO", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "CONCLUIDA", tarefas: [], especial: false, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "IMPEDIDO", tarefas: [], especial: true, tempoEstimado: 0, tempoGasto: 0},
                {situacao: "REJEITADO", tarefas: [], especial: true, tempoEstimado: 0, tempoGasto: 0}
            ];
            vm.pieLabels = [];
            vm.pieDataHoras = [];
            vm.pieDataQuantidade = [];
            for (var i = 0; i < vm.colunas.length; i++) {
                var coluna = vm.colunas[i];
                vm.pieLabels.push(coluna.situacao);
                vm.pieDataHoras.push(0);
                vm.pieDataQuantidade.push(0);
            }

        }

        function filtrar() {
            zerarColunas();
            vm.tarefasAtrasadas = 0;
            vm.tempoPlanejado = 0;
            vm.tempoGasto = 0;
            vm.tarefas = [];

            vm.totalPlanejados = 0;
            vm.totalNaoPlanejados = 0;
            vm.totalDessaSprint = 0;
            vm.concluidasPlanejadas = 0;
            vm.concluidasNaoPlanejadas = 0;

            angular.forEach(vm.solicitacoesDesenvolvimento, function (tarefa) {
                if ((vm.modulo == null || vm.modulo == tarefa.modulo) &&
                    (vm.projeto == null || vm.projeto == tarefa.projeto)) {
                    if (tarefa.concluidaEm && tarefa.concluidaEm < vm.sprint.inicio) {
                        tarefa.concluidaEm = new Date(vm.sprint.inicio);
                    }
                    vm.tarefas.push(tarefa);
                    if (tarefa.chamado && tarefa.sprintChamado && tarefa.sprintChamado != tarefa.sprint) {
                        tarefa.atrasado = true;
                        vm.tarefasAtrasadas++;
                    } else {
                        vm.totalDessaSprint++;
                    }
                    var coluna = getColunaPorSituacao(tarefa.aberto ? tarefa.situacao : 'CONCLUIDA');
                    coluna.tarefas.push(tarefa);
                    coluna.tempoEstimado += tarefa.tempoEstimado;
                    coluna.tempoGasto += tarefa.tempoGasto;
                    vm.tempoPlanejado += tarefa.tempoEstimado;
                    vm.tempoGasto += tarefa.tempoGasto;
                    vm.pieDataHoras[vm.pieLabels.indexOf(coluna.situacao)] += tarefa.tempoEstimado;
                    vm.pieDataQuantidade[vm.pieLabels.indexOf(coluna.situacao)] += 1;


                    vm.totalPlanejados += tarefa.planejado ? 1 : 0;
                    vm.totalNaoPlanejados += tarefa.planejado ? 0 : 1;

                    vm.concluidasPlanejadas += tarefa.planejado && tarefa.situacao === 'CONCLUIDA' ? 1 : 0;
                    vm.concluidasNaoPlanejadas += !tarefa.planejado && tarefa.situacao === 'CONCLUIDA' ? 1 : 0;

                }
            });
            vm.efetividade = (vm.concluidasNaoPlanejadas + vm.concluidasPlanejadas) * 100 / vm.totalDessaSprint;

            vm.efetividadePlanejado = (vm.concluidasPlanejadas) * 100 / vm.totalDessaSprint;

            var hoje = new Date();
            vm.diasRestantes = (vm.sprint.fim.getTime() - hoje.getTime()) / (1000 * 3600 * 24);
            vm.burnDownData = [[], [], []];
            vm.burnDownLabels = [];
            vm.burnDownSeries = ['Base', 'Esperado', 'Realizado'];
            vm.dias = [];
            for (var d = new Date(vm.sprint.inicio); d <= vm.sprint.fim; d.setDate(d.getDate() + 1)) {
                vm.dias.push(new Date(d));
            }
            var tempoPorDia = vm.tempoPlanejado / vm.dias.length;
            var tempoGastoAcumulado = 0;
            for (var c = 0; c < vm.dias.length; c++) {
                var dia = new Date(vm.dias[c]);
                vm.burnDownLabels.push($filter('date')(dia));
                vm.burnDownData[0].push(0);
                vm.burnDownData[1].push(tempoPorDia * (c + 1));
                var coluna = getColunaPorSituacao('CONCLUIDA');
                var tempoGastoDia = 0;
                for (var i = 0; i < coluna.tarefas.length; i++) {
                    var tarefa = coluna.tarefas[i];
                    if (tarefa.concluidaEm && $filter('date')(tarefa.concluidaEm) == $filter('date')(dia)) {
                        tempoGastoDia += tarefa.tempoEstimado;
                    }
                }
                if (dia <= hoje) {
                    vm.burnDownData[2].push(tempoGastoAcumulado + tempoGastoDia);
                    tempoGastoAcumulado = tempoGastoAcumulado + tempoGastoDia
                }
            }

        }

        function load() {
            zerarColunas();
            vm.modulos = [];
            vm.projetos = [];

            Sprint.getBySituacao({situacao: 'EM_ANDAMENTO'}, function (aberta) {
                vm.sprint = aberta;

                SolicitacaoDesenvolvimento.getAllEmDesenvolvimento({}, function (data) {
                    vm.solicitacoesDesenvolvimento = data;
                    angular.forEach(vm.solicitacoesDesenvolvimento, function (tarefa) {

                        if (vm.modulos.indexOf(tarefa.modulo) < 0) {
                            vm.modulos.push(tarefa.modulo)
                        }
                        if (vm.projetos.indexOf(tarefa.projeto) < 0) {
                            vm.projetos.push(tarefa.projeto)
                        }
                    });
                    filtrar();
                })
            });

        }

        function getColunaPorSituacao(situacao) {
            for (var i = 0; i < vm.colunas.length; i++) {
                var coluna = vm.colunas[i];
                if (coluna.situacao === situacao) {
                    return coluna;
                }
            }
            var coluna = {situacao: situacao, tarefas: []};
            vm.colunas.push(coluna);
            return coluna;
        }


        function definirModoApresentacao() {
            $rootScope.modoApresentacao = !$rootScope.modoApresentacao;
            verificarModoApresentacao();
        }

        function verificarModoApresentacao() {
            if ($rootScope.modoApresentacao) {
                $timeout(function () {
                    $state.go("burnup")
                }, 60000);
            }
        }

        verificarModoApresentacao();

        load();

        function imprimir() {

            var docDefinition = {
                pageSize: 'A4',

                // by default we use portrait, you can change it to landscape if you wish
                pageOrientation: 'portrait',

                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [40, 10, 40, 10],

                content: [
                    {text: 'Webpublico - Helpdesk', style: 'header', alignment: 'center'},
                    {text: 'Relatório de Acompanhamento dos tickets', style: 'subheader'},
                    ticketsImpressao()
                ],
                styles: {
                    header: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, 5, 0, 5]
                    },
                    subheader: {
                        fontSize: 10,
                        italic: true,
                        margin: [0, 5, 0, 5]
                    },
                    tableExample: {
                        margin: [0, 5, 0, 5],
                        fontSize: 8,
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 8,
                        color: 'black'
                    }
                }
            };


            // download the PDF
            pdfMake.createPdf(docDefinition).download('Tickets.pdf');
        }

        function ticketsImpressao() {
            var filasImprimir = [];

            filasImprimir.push(
                {
                    text: "Impedidos",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('IMPEDIDO').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "Rejeitados",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('REJEITADO').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "A Fazer",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('A_FAZER').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "Fazendo",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('FAZENDO').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "Code Review",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('CODE_REVIEW').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "A Testar",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('A_TESTAR').tarefas), ['Número', 'Titulo', 'Usuário']),
                {
                    text: "Concluídos",
                    style: 'header'
                },
                table(getChamadosParaImpressao(getColunaPorSituacao('CONCLUIDA').tarefas), ['Número', 'Titulo', 'Usuário'])
            );


            return filasImprimir;

        }

        function table(data, columns) {
            if (data.length > 0) {
                return {
                    table: {
                        style: 'tableExample',
                        headerRows: 1,
                        widths: [100, '*', 100],
                        fontSize: 8,
                        body: buildTableBody(data, columns)
                    },
                    layout: 'lightHorizontalLines'
                };
            } else {
                return {
                    text: "Nenhum Registro",
                    style: 'subheader'
                }
            }
        }

        function getChamadosParaImpressao(tickets) {
            var retorno = []
            angular.forEach(tickets, function (ticket) {
                retorno.push(
                    {
                        'Número': ticket.numero,
                        'Titulo': ticket.titulo,
                        'Usuário': ticket.usuario ? ticket.usuario : ''
                    }
                )

            });
            return retorno;
        }

        function buildTableBody(data, columns) {
            var body = [];

            body.push(columns);

            data.forEach(function (row) {
                var dataRow = [];

                columns.forEach(function (column) {
                    if (row[column]) {
                        dataRow.push(row[column].toString());
                    } else {
                        dataRow.push(" ");
                    }
                });

                body.push(dataRow);
            });

            return body;
        }
    }

})();


