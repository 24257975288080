(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('BrowserNotificationController', BrowserNotification);

    BrowserNotification.$inject = ['$scope', 'WsNotificationService', 'webNotification'];

    function BrowserNotification($scope, WsNotificationService, webNotification) {
        var vm = this;

        vm.showActivity = showActivity;
        vm.initialize = initialize;

        function initialize() {
            WsNotificationService.subscribe();

            WsNotificationService.receive().then(null, null, function (activity) {
                vm.showActivity(activity);
            });

        };

        vm.initialize();

        function showActivity(activity) {

            webNotification.showNotification(activity.title, {
                body: activity.message,
                onClick: function onNotificationClicked() {
                    console.log('Notification clicked.');
                },
                autoClose: 4000 //auto close the notification after 4 seconds (you can manually close it via hide function)
            });

            console.log("|Atividade: ", activity);
            $scope.$emit('chamadosApp:activityNotification', activity);
            $scope.$emit('chamadosApp:solicitacaoDesenvolvimentoUpdate', activity)
        }

        $scope.$on("$destroy", function () {
            WsNotificationService.unsubscribe();
        });


    }

})();


