(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('MovimentacaoDemandas', MovimentacaoDemandas);

    MovimentacaoDemandas.$inject = ['$resource'];

    function MovimentacaoDemandas($resource) {
        var resourceUrl = 'api/movimentacao-demandas';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            },
            'situacaoPorPeriodo': {
                url : 'api/situacao-chamado-por-periodo',
                method: 'GET',
                isArray: true
            }

        });
    }


})();
