(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Wiki', Wiki);

    Wiki.$inject = ['$resource'];

    function Wiki($resource) {
        var resourceUrl = 'api/wiki/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET'
            }, 'first': {
                url: 'api/wiki/first',
                method: 'GET'
            },
            'update': {method: 'PUT'},
        });
    }
})();
