(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('AnexoChamado', AnexoChamado);

    AnexoChamado.$inject = ['$resource', '$http'];

    function AnexoChamado($resource, $http) {
        var resourceUrl = 'api/anexo-chamados/:id';

        var resource = $resource(resourceUrl, {}, {
            'query': {url: 'api/anexo-chamados-por-chamado/:id', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'upload': {url: 'api/upload', method: 'POST'}
        });


        function toDataUrl(anexo, callback) {
            var url = anexo.conteudo;
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        resource.toDataUrl = toDataUrl;

        function downloadFile(anexo, callback) {
            $http.get('/api/download-anexo/' + anexo.id, {responseType: 'arraybuffer'})
                .success(function (data) {
                    var file = new Blob([data], {type: anexo.mimeType});
                    callback(file);
                });
        }

        resource.downloadFile = downloadFile;

        resource.downloadAnexo = function (anexo) {
            if (anexo.sincronizado) {
                toDataUrl(anexo, function (file) {
                    saveAs(file, anexo.descricao);
                });

            } else {
                downloadFile(anexo, function (file) {
                    saveAs(file, anexo.descricao);
                });
            }
        };

        return resource;
    }
})();
