(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'Principal', '$location', '$anchorScroll','Alerta'];

    function LoginController($rootScope, $state, $timeout, Auth, Principal, $location, $anchorScroll, Alerta) {
        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.ano = new Date().getFullYear();



        $timeout(function () {
            angular.element('#username').focus();
            if (angular.element("#box-login").height() > 0) {
                var tamanho =  angular.element('#box-login > .widget').height();
                angular.element('#box-logo > .widget').height(tamanho);
            }
        }, 500);

        Principal.identity().then(function (account) {
            if (account) {
                //manda p o home caso tente acessar o login e já está logado
                $state.go('home', {reload: true});
            }
        });

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
                $state.go('home');
                // if ($state.current.name === 'login' ||
                //     $state.current.name === 'register' || $state.current.name === 'activate' ||
                //     $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                //     $state.go('home');
                // } else {
                //     // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                //     // since login is successful, go to stored previousState and clear previousState
                //     if (Auth.getPreviousState()) {
                //         var previousState = Auth.getPreviousState();
                //         Auth.resetPreviousState();
                //         $state.go(previousState.name, previousState.params);
                //     }
                // }
            }).catch(function () {
                vm.authenticationError = true;
                Alerta.warn("Atenção!", "Usuário ou senha inválidos, digite novamente");
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }
    }
})();
