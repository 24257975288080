(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('PlanejamentoController', PlanejamentoController);

    PlanejamentoController.$inject = ['$scope', 'Principal', '$state', 'Chamado', 'Sprint', 'Modulo', 'Sistema', 'Cliente'];

    function PlanejamentoController($scope, Principal, $state, Chamado, Sprint, Modulo, Sistema, Cliente) {
        var vm = this;
        vm.load = load;
        vm.alocar = alocar;
        vm.usuarioTemCliente = usuarioTemCliente;
        vm.desalocar = desalocar;
        vm.getChamadosSprintPorModulo = getChamadosSprintPorModulo;
        vm.getHorasPormodulo = getHorasPormodulo;
        vm.carregarSistema = carregarSistema;
        vm.atualizarChamado = atualizarChamado;
        vm.selecionarSistema = selecionarSistema;
        vm.agruparChamados = agruparChamados;
        vm.chamadosPorModulo = {};

        function carregarModulos() {
            Modulo.getBySistema({id: vm.sistema.id}, function (data) {
                vm.modulos = data;
                vm.modulo = data[0];
                agruparChamados();
            });
        }

        function selecionarSistema(sistema) {
            vm.sistema = sistema;
            carregarClientes();
        }

        function atualizarChamado(chamado) {
            Chamado.update(chamado, agruparChamados);
        }

        function load() {
            Principal.identity().then(function (account) {
                vm.account = account;
                carregarSistema();
            });

        }

        function adicionarChamadoPraPlanejar(chamado) {
            vm.chamadosPorModulo[chamado.modulo.id].chamados.push(chamado);
            if (!chamado.dependentes || chamado.dependentes.length === 0) {
                vm.chamadosPorModulo[chamado.modulo.id].tempoDisponivel =
                    vm.chamadosPorModulo[chamado.modulo.id].tempoDisponivel + chamado.tempoEstimado;

                vm.horasDisponiveisCliente[chamado.cliente.id].horasSemDependencia =
                    vm.horasDisponiveisCliente[chamado.cliente.id].horasSemDependencia + chamado.tempoEstimado;
            }
        }

        function carregarClientes() {
            Cliente.getBySistema({sistemaId: vm.sistema.id}, function (data) {
                vm.clientes = data;
                vm.horasDisponiveisCliente = {};
                for (var i = 0; i < vm.clientes.length; i++) {
                    vm.clientes[i].selecionado = true;
                    vm.horasDisponiveisCliente[vm.clientes[i].id] = {
                        cliente: vm.clientes[i],
                        horasOcupadas: 0,
                        horasSemDependencia: 0,
                        horasDisponiveis: vm.clientes[i].horasDisponiveis
                    };
                }
                carregarModulos();
            });
        }

        function agruparChamados() {

            Sprint.getBySituacao({situacao: 'ABERTA'}, function (aberta) {
                vm.chamadosPorModulo = {};

                for (var i = 0; i < aberta.clientes.length; i++) {

                    var cliente = aberta.clientes[i];

                    if (vm.horasDisponiveisCliente[cliente.cliente.id]) {
                        vm.horasDisponiveisCliente[cliente.cliente.id].horasDisponiveis = cliente.horasDisponiveis;
                        vm.horasDisponiveisCliente[cliente.cliente.id].horasOcupadas = 0;
                        vm.horasDisponiveisCliente[cliente.cliente.id].horasSemDependencia = 0;
                    }

                    if (vm.horasDisponiveisCliente[cliente.cliente.id] && vm.horasDisponiveisCliente[cliente.cliente.id].cliente.selecionado) {
                        Chamado.getProximasSprints({
                            idSistema: vm.sistema.id,
                            idCLiente: cliente.cliente.id
                        }, function (chamados) {

                            angular.forEach(chamados, function (chamado) {
                                if (!vm.chamadosPorModulo[chamado.modulo.id]) {
                                    vm.chamadosPorModulo[chamado.modulo.id] = {
                                        modulo: chamado.modulo,
                                        chamados: [],
                                        tempoDisponivel: 0
                                    };
                                }

                                Chamado.getDependentes({id: chamado.id}, function (data) {
                                    chamado.dependentes = data;
                                    adicionarChamadoPraPlanejar(chamado);
                                }, function (error) {
                                    adicionarChamadoPraPlanejar(chamado);
                                });

                            });
                        });
                    }
                }

                Chamado.queryBySprint({sprintId: aberta.id, sistemaId: vm.sistema.id}, function (chamados) {
                    aberta.chamados = chamados;
                    vm.sprintAberta = aberta;

                    angular.forEach(vm.sprintAberta.chamados, function (chamado) {
                        if (vm.horasDisponiveisCliente[chamado.cliente.id]) {
                            vm.horasDisponiveisCliente[chamado.cliente.id].horasOcupadas =
                                vm.horasDisponiveisCliente[chamado.cliente.id].horasOcupadas + chamado.tempoEstimado;
                        }
                    });
                });
            })
        }

        load();

        function carregarSistema() {
            Sistema.getByUser({}, function (data) {
                vm.sistemas = data;
                selecionarSistema(vm.sistemas[0]);
            })
        }

        function alocar(chamado) {
            chamado.sprintTemporaria = null;
            chamado.sprint = vm.sprintAberta;
            Chamado.alocar(chamado, agruparChamados);
        }

        function desalocar(chamado) {
            chamado.sprint = null;
            Chamado.update(chamado, agruparChamados);
        }

        function usuarioTemCliente(cliente) {
            for (var i = 0; i < vm.account.clientes.length; i++) {
                var obj = vm.account.clientes[i];
                if (obj.id === cliente.id) {
                    return true;
                }
            }

            return false;
        }

        function getChamadosSprintPorModulo(modulo) {
            return vm.sprintAberta.chamados.filter(function (chamado) {
                return chamado.modulo.id === modulo.id && chamado.sistema.id === vm.sistema.id;
            })
        }

        function getHorasPormodulo(modulo) {
            var tempo = 0;
            if (modulo.id != null) {
                var chamados = getChamadosSprintPorModulo(modulo);
                for (var i = 0; i < chamados.length; i++) {
                    var chamado = chamados[i];
                    tempo += chamado.tempoEstimado;
                }
            }

            return tempo;
        }
    }

})
();


