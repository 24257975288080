(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('WikiEditController', WikiEditController);

    WikiEditController.$inject = ['$scope', '$state', 'entity', 'Wiki'];

    function WikiEditController($scope, $state, entity, Wiki) {
        var vm = this;
        vm.wiki = entity;
        vm.cancel = cancel;
        vm.save = save;

        if(vm.wiki.superior && vm.wiki.superior.id){
            Wiki.get({id: vm.wiki.superior.id}, function (data){
                vm.wiki.superior =data;
            })
        }

        function cancel() {
            $state.go('wiki');
        }

        function save() {
            vm.isSaving = true;
            if (vm.wiki.id !== null) {
                Wiki.update(vm.wiki, onSaveSuccess, onSaveError);
            } else {
                Wiki.save(vm.wiki, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:sprintUpdate', result);
            cancel();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
