(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('TeamBoardController', TeamBoardController);

    TeamBoardController.$inject = ['$scope', '$rootScope', '$filter', 'Principal', '$state', 'Chamado', 'Sprint', 'SolicitacaoDesenvolvimento', 'Account', 'Modulo', 'User'];

    function TeamBoardController($scope, $rootScope, $filter, Principal, $state, Chamado, Sprint, SolicitacaoDesenvolvimento, Account, Modulo, User) {
        var vm = this;
        vm.modulos = Modulo.queryByUser();
        vm.recuperarTickets = recuperarTickets;
        vm.getQuantidadeTickets = getQuantidadeTickets;
        vm.sprints = Sprint.query();
        vm.totalHoraTrabalhadas = 0;
        vm.totalTarefas = 0;
        vm.users = User.getAllWithEquipe();

        function getQuantidadeTickets(situacao) {
            var quantidade = 0;
            angular.forEach(vm.tarefas, function (tipo) {
                angular.forEach(vm.tarefas[tipo], function (ticket) {
                    if (ticket.situacao == situacao) {
                        quantidade = quantidade + 1;
                    }
                });

            });
            return quantidade;
        }

        function load() {
            Principal.identity().then(function (account) {
                vm.account = account;
                Sprint.getBySituacao({situacao: 'EM_ANDAMENTO'}, function (aberta) {
                    vm.sprint = aberta;
                    vm.filtro = {};
                    vm.filtro.inicio = vm.sprint.inicio;
                    vm.filtro.fim = vm.sprint.fim;
                    vm.filtro.user = vm.account;
                    recuperarTickets();
                });
            });
        }

        function recuperarTickets() {
            vm.tarefas = {};
            vm.chamados = [];
            vm.horasPorModulo = [];
            vm.horasPonto = [];
            vm.atividades = [];

            Sprint.get({id: vm.sprint.id}, function (aberta) {
                vm.sprint = aberta;
                vm.filtro.inicio = vm.sprint.inicio;
                vm.filtro.fim = vm.sprint.fim;
                recuperarHorasApontada();

            });
            if (!vm.filtro.user.imagem) {
                Account.getImagemAjustadaByLogin({login: vm.filtro.user.login}, function (data) {
                    vm.filtro.user.imagem = data.imagemAjustada;
                });
            }

        }

        function adicionarTarefa(atividade) {
            if (atividade.solicitacaoDesenvolvimento) {
                if (atividade.tipo === 'CODE_REVIEW') {
                    return;
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (!vm.tarefas[tipo]) {
                    vm.tarefas[tipo] = [];
                }
                var tarefa = atividade.solicitacaoDesenvolvimento;
                var indice = vm.tarefas[tipo].map(function (value) {
                    return value.numero
                }).indexOf(tarefa.numero);
                if (indice > -1) {
                    vm.tarefas[tipo][indice].tempoGasto += atividade.tempo;
                    vm.totalTarefas ++;
                } else {
                    tarefa.tempoGasto = atividade.tempo;
                    vm.tarefas[tipo].push(tarefa);
                }
            }
        }

        function adicionarChamado(atividade) {
            if (atividade.chamado) {
                if (atividade.tipo === 'CODE_REVIEW') {
                    return;
                }
                var chamado = atividade.chamado;
                var indice = vm.chamados.map(function (value) {
                    return value.id
                }).indexOf(chamado.id);
                if (indice > -1) {
                    vm.chamados[indice].tempoGasto += atividade.tempo;
                } else {
                    chamado.tempoGasto = atividade.tempo;
                    vm.chamados.push(chamado);
                }
            }
        }

        function recuperarHorasApontada() {
            Chamado.queryTempoGasto(vm.filtro, function (data) {
                vm.atividades = data;
                vm.horaApontadaPorDia = {};
                vm.totalHoraTrabalhadas = 0;
                for (var d = new Date(vm.sprint.inicio); d <= vm.sprint.fim - 1; d.setDate(d.getDate() + 1)) {
                    if (d.getDay() === 6 || d.getDay() === 0) {
                        continue;
                    }
                    var criadoEm = $filter('date')(new Date(d), 'yyyy-MM-dd');
                    vm.horaApontadaPorDia[criadoEm] = {dia: criadoEm, tempoGasto: 0, horaPonto: 0, percentual: 0};
                }

                angular.forEach(vm.atividades, function (atividade) {
                    var criadoEm = $filter('date')(atividade.criadoEm, 'yyyy-MM-dd');
                    if (!vm.horaApontadaPorDia[criadoEm]) {
                        vm.horaApontadaPorDia[criadoEm] = {dia: criadoEm, tempoGasto: 0, horaPonto: 0, percentual: 0};
                    }
                    vm.horaApontadaPorDia[criadoEm].tempoGasto = vm.horaApontadaPorDia[criadoEm].tempoGasto + atividade.tempo;
                    vm.totalHoraTrabalhadas = vm.totalHoraTrabalhadas + atividade.tempo;

                    adicionarTarefa(atividade);
                    adicionarChamado(atividade);
                });

                recuperarHorasPonto();
            });
        }

        function recuperarHorasPonto() {
            Chamado.queryTempoGastoPonto(vm.filtro, function (data) {
                vm.horasPonto = data;
                angular.forEach(vm.horasPonto, function (ponto) {
                    if (ponto.horasTrabalhadas) {
                        var criadoEm = $filter('date')(ponto.data, 'yyyy-MM-dd');
                        if (!vm.horaApontadaPorDia[criadoEm]) {
                            vm.horaApontadaPorDia[criadoEm] = {
                                dia: criadoEm,
                                tempoGasto: 0,
                                horaPonto: 0,
                                percentual: 0
                            };
                        }
                        vm.horaApontadaPorDia[criadoEm].horaPonto += timeToDecimal(ponto.horasTrabalhadasString);
                    }
                });
                calculaPercentual();
            }, function (error) {
                calculaPercentual();
            });
        }

        function calculaPercentual() {
            angular.forEach(vm.horaApontadaPorDia, function (dia) {
                var horaPonto = dia.horaPonto;
                if (horaPonto <= 0) {
                    horaPonto = 8.48;
                }
                dia.percentual = ((dia.tempoGasto * 100) / horaPonto);
            });
            montarGraficoHoras();
        }

        function montarGraficoHoras() {
            vm.labelsHoras = [];
            vm.dataHoras = [[], [], []];
            vm.serieHoras = ["Trabalhado", "Esperado", "Apontado"];
            angular.forEach(vm.horaApontadaPorDia, function (dia) {
                vm.labelsHoras.push($filter('date')(dia.dia, 'dd/MM/yyyy'));
                vm.dataHoras[0].push(dia.horaPonto);
                vm.dataHoras[1].push(8.48);
                vm.dataHoras[2].push(dia.tempoGasto);
            });
            montarGraficoModulos();
        }

        function montarGraficoModulos() {
            vm.labelsModulo = [];
            vm.dataModulo = [];
            vm.totalModulo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                if (vm.labelsModulo.indexOf(atividade.chamado.modulo.descricao) < 0) {
                    vm.labelsModulo.push(atividade.chamado.modulo.descricao);
                }
            });
            angular.forEach(vm.labelsModulo, function (modulo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    if (atividade.chamado.modulo.descricao === modulo) {
                        if (!vm.dataModulo[vm.labelsModulo.indexOf(modulo)]) {
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] = 0;
                        }
                        vm.dataModulo[vm.labelsModulo.indexOf(modulo)] =
                            vm.dataModulo[vm.labelsModulo.indexOf(modulo)] + atividade.tempo;
                        vm.totalModulo += atividade.tempo;
                    }
                });
            });
            montarGraficoTipos()
        }

        function montarGraficoTipos() {
            vm.labelsTipo = [];
            vm.dataTipo = [];
            vm.totalTipo = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var tipo = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;
                if (vm.labelsTipo.indexOf(tipo) < 0) {
                    vm.labelsTipo.push(tipo);
                }
            });
            angular.forEach(vm.labelsTipo, function (tipo) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var tipoAtividade = atividade.tipo ? $filter('translate')('chamadosApp.TipoHora.' + atividade.tipo) : atividade.conteudo;

                    if (tipoAtividade === tipo) {
                        if (!vm.dataTipo[vm.labelsTipo.indexOf(tipo)]) {
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] = 0;
                        }
                        vm.dataTipo[vm.labelsTipo.indexOf(tipo)] =
                            vm.dataTipo[vm.labelsTipo.indexOf(tipo)] + atividade.tempo;
                        vm.totalTipo += atividade.tempo;
                    }
                });
            });
            montarGraficoComplexidade()
        }

        function montarGraficoComplexidade() {
            vm.labelsComplexidade = [];
            vm.dataComplexidade = [];
            vm.totalComplexidade = 0;
            angular.forEach(vm.atividades, function (atividade) {
                if (!atividade.chamado) {
                    return;
                }
                var complexidade = atividade.chamado.complexidade ? $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade) : "Sem Comple.";
                if (vm.labelsComplexidade.indexOf(complexidade) < 0) {
                    vm.labelsComplexidade.push(complexidade);
                }
            });
            angular.forEach(vm.labelsComplexidade, function (complexidade) {
                angular.forEach(vm.atividades, function (atividade) {
                    if (!atividade.chamado) {
                        return;
                    }
                    var complexidadeAtividade = $filter('translate')('chamadosApp.ComplexidadeChamado.' + atividade.chamado.complexidade);

                    if (complexidadeAtividade === complexidade) {
                        if (!vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)]) {
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] = 0;
                        }
                        vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] =
                            vm.dataComplexidade[vm.labelsComplexidade.indexOf(complexidade)] + atividade.tempo;
                        vm.totalComplexidade += atividade.tempo;
                    }
                });
            });
        }

        function timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1] / 6) * 10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
        }

        load();

    }

})();


