(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('BornupController', BornupController);

    BornupController.$inject = ['$scope', '$rootScope', '$filter', 'Principal', '$state', 'Chamado', 'Sprint', 'Cliente', '$timeout', 'Modulo', 'User'];

    function BornupController($scope, $rootScope, $filter, Principal, $state, Chamado, Sprint, Cliente, $timeout, Modulo, User) {
        var vm = this;
        vm.recuperarChamados = recuperarChamados;
        vm.getChamadosNaoPlanejados = getChamadosNaoPlanejados;
        vm.getChamadosDevEntregues = getChamadosDevEntregues;
        vm.getChamadosSupEntregues = getChamadosSupEntregues;
        vm.definirModoApresentacao = definirModoApresentacao;
        vm.quantidadeRodadas = 0;

        vm.chartOptions = {
            scales: {
                yAxes: [
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        display: true,
                        position: 'left'
                    },
                    {
                        id: 'y-axis-2',
                        type: 'linear',
                        display: true,
                        position: 'right'
                    }
                ]
            }
        };
        vm.colors = ['#6f6d74', '#ff7c00', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'];


        vm.sprints = Sprint.query();

        function load() {
            vm.tarefas = [];
            Principal.identity().then(function (account) {
                vm.account = account;
                Sprint.getBySituacao({situacao: 'EM_ANDAMENTO'}, function (aberta) {
                    vm.sprint = aberta;

                    Cliente.getByUser({}, function (data) {
                        vm.clientes = data;
                        vm.cliente = data[0];
                        recuperarChamados();
                    });
                });
            });
        }

        function inicializarGraficos() {
            vm.labelsGraficoDev = [];
            vm.labelsGraficoSup = [];

            vm.dataGraficoDev = [];
            vm.dataGraficoSup = [];

            var horaCliente = [];
            var dataHoje = new Date(vm.sprint.inicio);
            var dataFim = new Date(vm.sprint.fim);


            dataFim.setDate(dataFim.getDate() - 1);

            while ($filter('date')(dataHoje, 'yyyy-MM-dd') <= $filter('date')(dataFim, 'yyyy-MM-dd')) {
                vm.labelsGraficoDev.push($filter('date')(dataHoje, 'dd/MM/yyyy'));
                vm.labelsGraficoSup.push($filter('date')(dataHoje, 'dd/MM/yyyy'));
                horaCliente.push(vm.cliente.tempoPlanejado);
                dataHoje.setDate(dataHoje.getDate() + 1);
            }
            vm.dataGraficoDev.push(horaCliente);
            vm.dataGraficoSup.push(horaCliente);
        }

        function recuperarChamados() {
            var filtro = {
                sprint: vm.sprint,
                cliente: vm.cliente,
            };
            Chamado.getChamadosBornup(filtro, function (chamados) {
                vm.chamados = chamados;

                vm.cliente.tempoPlanejado = 0;
                angular.forEach(vm.chamados, function (chamado) {
                    if (chamado.tipoChamado !== 'SUPORTE') {
                        vm.cliente.tempoPlanejado = vm.cliente.tempoPlanejado + chamado.tempoEstimado;
                    }
                });

                inicializarGraficos();

                var dataHoje = new Date(vm.sprint.inicio)
                var dataFim = new Date(vm.sprint.fim);

                var horasEntregeDev = [];
                var horasEntregeSup = [];

                while ($filter('date')(dataHoje, 'yyyy-MM-dd') <= $filter('date')(dataFim, 'yyyy-MM-dd')) {
                    var horasEntregeDiaDev = 0;
                    var horasEntregeDiaSup = 0;

                    var date = new Date($filter('date')(dataHoje, 'yyyy-MM-dd'));
                    date.setHours(0, 0, 0, 0);

                    angular.forEach(vm.chamados, function (chamado) {
                        if (chamado.fimDesenvolvimento) {
                            if ($filter('date')(chamado.fimDesenvolvimento, 'yyyy-MM-dd') == $filter('date')(dataHoje, 'yyyy-MM-dd')) {
                                horasEntregeDiaDev = horasEntregeDiaDev + chamado.tempoEstimado;
                            }
                        }
                        if (chamado.fimTeste) {
                            if ($filter('date')(chamado.fimTeste, 'yyyy-MM-dd') == $filter('date')(dataHoje, 'yyyy-MM-dd')) {
                                horasEntregeDiaSup = horasEntregeDiaSup + chamado.tempoEstimado;
                            }
                        }
                    });

                    if (horasEntregeDev.length > 0) {
                        var horasNovas = horasEntregeDev[horasEntregeDev.length - 1] + horasEntregeDiaDev;
                        horasEntregeDev.push(horasNovas);
                    } else {
                        horasEntregeDev.push(horasEntregeDiaDev);
                    }

                    if (horasEntregeSup.length > 0) {
                        var horasNovas = horasEntregeSup[horasEntregeSup.length - 1] + horasEntregeDiaSup;
                        horasEntregeSup.push(horasNovas);
                    } else {
                        horasEntregeSup.push(horasEntregeDiaSup);
                    }

                    dataHoje.setDate(dataHoje.getDate() + 1);
                }
                if (horasEntregeDev.length > 0) {
                    vm.dataGraficoDev.push(horasEntregeDev)
                }
                if (horasEntregeSup.length > 0) {
                    vm.dataGraficoSup.push(horasEntregeSup)
                }
            });
        }


        load();

        function getChamadosNaoPlanejados() {
            var total = 0;
            angular.forEach(vm.chamados, function (chamado) {
                if (!chamado.planejado) {
                    total = total + chamado.tempoEstimado;
                }
            });
            return total;
        }

        function getChamadosDevEntregues() {
            var total = 0;
            var dataFim = new Date(vm.sprint.fim)
            angular.forEach(vm.chamados, function (chamado) {
                if (chamado.fimDesenvolvimento) {
                    if ($filter('date')(chamado.fimDesenvolvimento, 'yyyy-MM-dd') <= $filter('date')(dataFim, 'yyyy-MM-dd')) {
                        total = total + 1;
                    }
                }
            });
            return total;
        }

        function getChamadosSupEntregues() {
            var total = 0;
            var dataFim = new Date(vm.sprint.fim)
            angular.forEach(vm.chamados, function (chamado) {
                if (chamado.fimTeste) {
                    if ($filter('date')(chamado.fimTeste, 'yyyy-MM-dd') <= $filter('date')(dataFim, 'yyyy-MM-dd')) {
                        total = total + 1;
                    }
                }
            });
            return total;
        }

        function definirModoApresentacao() {
            $rootScope.modoApresentacao = !$rootScope.modoApresentacao;
            verificarModoApresentacao();
        }


        function verificarModoApresentacao() {
            if ($rootScope.modoApresentacao) {
                $timeout(function () {
                    if (vm.quantidadeRodadas === vm.clientes.length) {
                        $state.go("home");
                    } else {
                        vm.quantidadeRodadas ++;
                        vm.cliente = vm.clientes[vm.quantidadeRodadas];
                        vm.recuperarChamados();
                        verificarModoApresentacao();
                    }
                }, 30000);
            }
        }

        verificarModoApresentacao();

    }

})();


