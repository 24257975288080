(function() {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('aplicacao', {
            parent: 'entity',
            url: '/aplicacao?page&sort&search',
            data: {
                authorities: ['ROLE_PO', 'ROLE_DESENVOLVEDOR'],
                pageTitle: 'chamadosApp.aplicacao.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/aplicacao/aplicacao.html',
                    controller: 'AplicacaoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('aplicacao');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('aplicacao.new', {
            parent: 'aplicacao',
            url: '/new',
            data: {
                authorities: ['ROLE_PO', 'ROLE_DESENVOLVEDOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aplicacao/aplicacao-dialog.html',
                    controller: 'AplicacaoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nome: null,
                                url: null,
                                id: null,
                                cliente: null,
                                sistema: null,
                                ocorrencias: []
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('aplicacao', null, { reload: 'aplicacao' });
                }, function() {
                    $state.go('aplicacao');
                });
            }]
        })
        .state('aplicacao.edit', {
            parent: 'aplicacao',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_PO', 'ROLE_DESENVOLVEDOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/aplicacao/aplicacao-dialog.html',
                    controller: 'AplicacaoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Aplicacao', function(Aplicacao) {
                            return Aplicacao.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('aplicacao', null, { reload: 'aplicacao' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
