(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SprintDialogController', SprintDialogController);

    SprintDialogController.$inject = ['$timeout', '$scope', 'Cliente', '$uibModalInstance', 'entity', 'Sprint', 'Chamado'];

    function SprintDialogController($timeout, $scope, Cliente, $uibModalInstance, entity, Sprint, Chamado) {
        var vm = this;

        vm.sprint = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.chamados = Chamado.query();

        carregarClientes();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.sprint.id !== null) {
                Sprint.update(vm.sprint, onSaveSuccess, onSaveError);
            } else {
                Sprint.save(vm.sprint, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('chamadosApp:sprintUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.inicio = false;
        vm.datePickerOpenStatus.fim = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function indexCliente(cliente) {
            var j = -1;
            for (var i = 0; i < vm.sprint.clientes.length; i++) {
                var obj = vm.sprint.clientes[i];
                if (obj.cliente.id === cliente.id) {
                    j = i;
                }
            }
            return j;
        }

        function carregarClientes() {
            if (!vm.sprint.clientes) {
                vm.sprint.clientes = [];
            }
            Cliente.query({page: 0, size: 99}, function (clientes) {
                for (var i = 0; i < clientes.length; i++) {
                    var cliente = clientes[i];
                    if (indexCliente(cliente) < 0) {
                        vm.sprint.clientes.push({cliente: cliente, horasDisponiveis: cliente.horasDisponiveis})
                    }
                }
            })
        }
    }
})();
