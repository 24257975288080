(function() {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('acao', {
                parent: 'admin',
                url: '/acao?page&sort&search',
                data: {
                    authorities: ['ROLE_SCRUM'],
                    pageTitle: 'Ação'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/acao/acao.html',
                        controller: 'AcaoController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('situacaoAcao');
                        return $translate.refresh();
                    }]
                }
            })
            .state('acao.new', {
                parent: 'acao',
                url: '/new',
                data: {
                    authorities: ['ROLE_SCRUM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/acao/acao-edit.html',
                        controller: 'AcaoEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {situacaoAcao: 'PENDENTE'};
                    }
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('situacaoAcao');
                    return $translate.refresh();
                }]
            })
            .state('acao.edit', {
                parent: 'acao',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SCRUM']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/acao/acao-edit.html',
                        controller: 'AcaoEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Acao', function ($stateParams, Acao) {
                        return Acao.get({id: $stateParams.id}).$promise;
                    }]
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('situacaoAcao');
                    return $translate.refresh();
                }]
            })
    }

})();
