(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('ChamadosPorRelease', ChamadosPorRelease);

    ChamadosPorRelease.$inject = ['$resource'];

    function ChamadosPorRelease($resource) {
        var resourceUrl = 'api/chamados-por-release';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'POST',
                isArray: true
            }

        });
    }


})();
