(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AcaoJustificativaDialogController', AcaoJustificativaDialogController);

    AcaoJustificativaDialogController.$inject = ['$scope', '$uibModalInstance', 'Acao'];

    function AcaoJustificativaDialogController($scope, $uibModalInstance, Acao) {
        var vm = this;

        vm.clear = clear;
        vm.justificar = justificar;
        vm.acoes;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function buscarAcoesPendentes() {
            Acao.byUserPendente(function (data) {
                vm.acoes = data;
                if (!vm.acoes || vm.acoes.length == 0)
                    clear();
            });
        }

        buscarAcoesPendentes();

        function justificar(acao) {
            acao.situacaoAcao = 'FINALIZADA';
            Acao.update(acao, function () {
                $scope.$emit('chamadosApp:acaoUpdate');
                buscarAcoesPendentes();
            });
        }
    }
})();
