(function () {
        'use strict';

        angular
            .module('chamadosApp')
            .controller('SolicitacaoDesenvolvimentoDetailController', SolicitacaoDesenvolvimentoDetailController);

        SolicitacaoDesenvolvimentoDetailController.$inject = ['$timeout', '$scope', '$stateParams', 'entity',
            'SolicitacaoDesenvolvimento', '$state', 'Sprint', 'Principal', 'Account',
            '$sanitize', 'AnexoChamado', '$http', 'Upload', 'Chamado', '$uibModal', '$filter', 'FeedBack', 'Alerta'];

        function SolicitacaoDesenvolvimentoDetailController($timeout, $scope, $stateParams, entity,
                                                            SolicitacaoDesenvolvimento, $state, Sprint, Principal, Account,
                                                            $sanitize, AnexoChamado, $http, Upload, Chamado, $uibModal, $filter, FeedBack, Alerta) {
            var vm = this;
            vm.solicitacaoDesenvolvimento = entity;
            vm.atribuirTarefa = atribuirTarefa;
            vm.desatribuirTarefa = desatribuirTarefa;
            vm.alterarSituacao = alterarSituacao;
            vm.avancarTarefa = avancarTarefa;
            vm.alocarTarefa = alocarTarefa;
            vm.desalocarTarefa = desalocarTarefa;
            vm.save = save;
            vm.adicionarRepositorio = adicionarRepositorio;
            vm.criarFeedback = criarFeedback;
            vm.carregarRepositorio = carregarRepositorio;
            vm.carregarComentarios = carregarComentarios;
            vm.comentar = comentar;
            vm.trustAsHtml = trustAsHtml;
            vm.upload = upload;
            vm.downloadAnexo = downloadAnexo;
            vm.reload = reload;
            vm.calcularPercentualTempo = calcularPercentualTempo;
            vm.diffHour = diffHour;
            vm.sincronizarGit = sincronizarGit;

            vm.comentario = {};
            vm.comentarios = [];
            vm.isSaving = false;
            vm.agrupadorSituacao = {};
            vm.labelsSituacao = []
            vm.dataSituacao = [];

            $timeout(function () {
                angular.element('.form-group:eq(1)>input').focus();
            });

            Sprint.getBySituacao({situacao: 'EM_ANDAMENTO'}, function (aberta) {
                vm.sprint = aberta;
            });

            Principal.identity().then(function (account) {
                vm.account = account;
            });


            function downloadAnexo(index) {
                var anexo = vm.anexos[index];
                AnexoChamado.downloadAnexo(anexo);
            }

            function atribuirTarefa() {
                vm.isSaving = true;
                SolicitacaoDesenvolvimento.atribuir(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function desatribuirTarefa() {
                vm.isSaving = true;
                SolicitacaoDesenvolvimento.dasatribuir(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function alocarTarefa() {
                vm.isSaving = true;
                SolicitacaoDesenvolvimento.alocar(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function desalocarTarefa() {
                vm.isSaving = true;
                vm.solicitacaoDesenvolvimento.sprint = null;
                SolicitacaoDesenvolvimento.update(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function save() {
                vm.isSaving = true;
                SolicitacaoDesenvolvimento.update(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function avancarTarefa() {
                vm.isSaving = true;
                vm.editTarefa = false;
                SolicitacaoDesenvolvimento.avancar(vm.solicitacaoDesenvolvimento, onSaveSuccess, onSaveError);
            }

            function reload() {
                $state.reload();
            }

            function onSaveSuccess(result) {
                vm.solicitacaoDesenvolvimento = result;
                vm.isSaving = false;
                vm.editTarefa = false;
                $scope.$emit('chamadosApp:solicitacaoDesenvolvimentoUpdate', result);
                $state.reload();
            }

            function onSaveError() {
                vm.isSaving = false;
            }

            function carregarComentarios() {
                if (vm.solicitacaoDesenvolvimento.id) {
                    SolicitacaoDesenvolvimento.getComentarios({id: vm.solicitacaoDesenvolvimento.id}, function (data) {
                        vm.comentarios = data;
                        angular.forEach(vm.comentarios, function (comentario) {
                            Account.getImagemAjustadaByLogin({login: comentario.usuario.login}, function (data) {
                                comentario.imagemUsuario = data.imagemAjustada;
                            });
                        })
                    });
                }
            }

            function carregarHistorico() {
                if (vm.solicitacaoDesenvolvimento.id) {
                    SolicitacaoDesenvolvimento.getStatusBySolicitacao({id: vm.solicitacaoDesenvolvimento.id}, function (data) {
                        vm.historicos = data;
                        vm.agrupadorSituacao = {};
                        vm.labelsSituacao = []
                        vm.dataSituacao = [];
                        for (var i = 0; i < vm.historicos.length; i++) {
                            var historico = vm.historicos[i];
                            if (!vm.agrupadorSituacao[historico.situacao]) {
                                vm.agrupadorSituacao[historico.situacao] = {situacao: historico.situacao, dados: []};
                                vm.labelsSituacao.push(historico.situacao);
                                vm.dataSituacao.push(0);
                            }
                            historico.horas = Math.abs(historico.inicio - (historico.fim ? historico.fim : new Date())) / 36e5;
                            vm.agrupadorSituacao[historico.situacao].dados.push(historico);
                        }
                        for (var x = 0; x < vm.labelsSituacao.length; x++) {
                            var situacao = vm.labelsSituacao[x];
                            for (var j = 0; j < vm.agrupadorSituacao[situacao].dados.length; j++) {
                                vm.dataSituacao[j] =
                                    vm.dataSituacao[j] + vm.agrupadorSituacao[situacao].dados[j].horas;
                            }
                        }
                        console.log(vm.labelsSituacao);
                        console.log(vm.dataSituacao);

                    });
                }
            }

            function diffHour(historico) {
                var date1 = historico.inicio;
                var date2 = historico.fim;
                if (!date1) {
                    date1 = new Date();
                }
                if (!date2) {
                    date2 = new Date();
                }
                var diffMs = (date2 - date1); // milliseconds between now & Christmas
                var diffDays = Math.floor(diffMs / 86400000); // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                if (diffDays) {
                    return diffDays + " dia(s), " + diffHrs + " hora(s) e " + diffMins + " minuto(s).";
                }
                if (diffHrs) {
                    return diffHrs + " hora(s) e " + diffMins + " minuto(s).";
                }
                if (diffMins) {
                    return diffMins + " minuto(s).";
                }
                return diffHrs;
            }


            function comentar() {
                vm.isSaving = true;
                vm.comentario.solicitacaoDesenvolvimento = vm.solicitacaoDesenvolvimento;
                vm.comentario.usuario = vm.account;
                SolicitacaoDesenvolvimento.comentarSolicitacao(vm.comentario, function () {
                    vm.comentario = {solicitacaoDesenvolvimento: vm.solicitacaoDesenvolvimento, usuario: vm.account};
                    carregarComentarios();
                    vm.isSaving = false;
                }, onSaveError);
            }

            function trustAsHtml(value) {
                return $sanitize(value);
            }

            function carregarAnexos() {
                vm.anexos = vm.solicitacaoDesenvolvimento.chamado ? AnexoChamado.query({id: vm.solicitacaoDesenvolvimento.chamado.id}) : [];
            }

            function carregarSituacoes() {
                vm.situacoes = SolicitacaoDesenvolvimento.getSituacoePossiveis({situacao: vm.solicitacaoDesenvolvimento.situacao})
            }

            function upload($file) {
                if ($file) {
                    if (!vm.anexos) {
                        vm.anexos = [];
                    }
                    Upload.dataUrl($file, true).then(
                        function (conteudo) {
                            var anexo = {};
                            anexo.descricao = $file.name;
                            anexo.conteudo = conteudo;
                            anexo.mimeType = $file.type;
                            if (vm.solicitacaoDesenvolvimento.chamado.id) {
                                anexo.chamado = vm.solicitacaoDesenvolvimento.chamado;
                                anexo.tipo = 'INTERNO';
                                AnexoChamado.save(anexo, function (data) {
                                    vm.anexos.push(data);
                                });
                            }
                        }
                    );
                } else {
                    Alerta.warn("Atenção!", "Você deve selecionar um arquivo de até 100Mb");
                }
            }

            function contarTempoGasto() {
                SolicitacaoDesenvolvimento.getTempoGastoByTicket({id: vm.solicitacaoDesenvolvimento.id}, function (data) {
                    adicionarTempo(data);
                });
            }

            function adicionarTempo(data) {
                vm.tempos = {};
                vm.horasApontadas = data;
                vm.tempoTotalGasto = 0;
                angular.forEach(data, function (tempo) {
                    vm.tempoTotalGasto = vm.tempoTotalGasto + tempo.tempo;
                    if (!vm.tempos[tempo.tipo]) {
                        vm.tempos[tempo.tipo] = {
                            tipo: tempo.tipo ? $filter('translate')('chamadosApp.TipoHora.' + tempo.tipo) : tempo.conteudo,
                            tempo: tempo.tempo
                        };
                    } else {
                        vm.tempos[tempo.tipo].tempo = vm.tempos[tempo.tipo].tempo + tempo.tempo;
                    }
                });
            }

            function calcularPercentualTempo() {
                if (vm.tempoTotalGasto && vm.solicitacaoDesenvolvimento.tempoEstimado) {
                    var number = (vm.tempoTotalGasto * 100) / vm.solicitacaoDesenvolvimento.tempoEstimado;
                    return number > 100 ? 100 : number;
                }
                return 0;
            }

            function sincronizarGit() {
                SolicitacaoDesenvolvimento.sincronizarGit({id: vm.solicitacaoDesenvolvimento.id}, carregarBranches);
            }


            function alterarSituacao() {
                var modalInstance = $uibModal.open({
                    templateUrl: 'app/entities/solicitacaodesenvolvimento/alterar-situacao.html',
                    controller: 'AlterarSituacaoTarefaController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        solicitacaoDesenvolvimento: function () {
                            return SolicitacaoDesenvolvimento.get({id: vm.solicitacaoDesenvolvimento.id}).$promise;
                        },
                        tempoGasto: function () {
                            var tempoGasto = {criadoEm: new Date(), tipo: 'DESENVOLVIMENTO', horas: 0, minutos: 0};
                            if (vm.solicitacaoDesenvolvimento.tipo === 'BUG') {
                                tempoGasto.tipo = "CORRECAO";
                            }
                            return tempoGasto;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    $scope.$emit('chamadosApp:solicitacaoDesenvolvimentoUpdate', result);
                    $state.reload();
                });
            }

            function carregarBranches() {
                vm.branches = SolicitacaoDesenvolvimento.getBranches({id: vm.solicitacaoDesenvolvimento.id});
            }

            function carregarRepositorio() {
                vm.projetos = SolicitacaoDesenvolvimento.getGitRepositories();
                vm.vaiAddRepositorio = true;
                carregarBranches();
            }

            function adicionarRepositorio() {
                if (!vm.solicitacaoDesenvolvimento.projetosGit) {
                    vm.solicitacaoDesenvolvimento.projetosGit = [];
                }
                vm.solicitacaoDesenvolvimento.projetosGit
                    .push({
                        projetoGit: vm.projetoGit.key,
                        repositorioGit: vm.repositorioGit.name
                    })
                vm.projetoGit = {};
                vm.vaiAddRepositorio = false;
                save();
            }

            function carregarFotoSolicitante() {
                if (vm.solicitacaoDesenvolvimento.responsavel) {
                    Account.getImagemAjustadaByLogin({login: vm.solicitacaoDesenvolvimento.responsavel.login}, function (data) {
                        vm.fotoSolicitante = data.imagemAjustada;
                    });
                }
            }

            carregarComentarios();
            carregarAnexos();
            carregarSituacoes();
            carregarBranches();
            contarTempoGasto();
            carregarHistorico();
            carregarFotoSolicitante();


            function criarFeedback() {
                var modalInstance = $uibModal.open({
                    size: 'lg',
                    animation: true,
                    templateUrl: 'feedbackContent.html',
                    controller: 'FeedBackChamadoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    resolve: {
                        feedBack: {chamado: vm.solicitacaoDesenvolvimento.chamado}
                    }
                });
                modalInstance.result.then(function (feedBack) {
                    FeedBack.save(feedBack, function (data) {
                        vm.feedBack = data;
                        Alerta.success("Obrigado...", "Feedback salvo com sucesso!");
                    })
                });
            }
        }

    }

)
();

angular
    .module('chamadosApp')
    .controller('FeedBackChamadoController', FeedBackChamadoController);

FeedBackChamadoController.$inject = ['$uibModalInstance', 'feedBack'];


function FeedBackChamadoController($uibModalInstance, feedBack) {
    var vm = this;
    vm.feedBack = feedBack;

    vm.ok = function () {
        vm.isSaving = true;
        vm.feedBack.criadoEm = new Date();
        $uibModalInstance.close(vm.feedBack);
    };

};
