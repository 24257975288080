(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('NotificacaoDetailController', NotificacaoDetailController);

    NotificacaoDetailController.$inject = ['$scope', '$rootScope', '$sce', 'previousState', 'entity', 'Notificacao'];

    function NotificacaoDetailController($scope, $rootScope, $sce, previousState, entity, Notificacao) {
        var vm = this;

        vm.notificacao = entity;
        vm.previousState = previousState.name;
        vm.trustAsHtml = trustAsHtml;

        if (!vm.notificacao.lida) {
            vm.notificacao.lida = true;
            Notificacao.update(vm.notificacao, function (result) {
                $rootScope.$broadcast('chamadosApp:notificacaoUpdate');
            });
        }

        function trustAsHtml(value) {
            return $sce.trustAsHtml(value);
        }
    }
})();
