(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AcompanhamentoSprintController', AcompanhamentoSprintController);

    AcompanhamentoSprintController.$inject = ['AcompanhamentoSprint', '$filter', 'Modulo', 'SubModulo', 'Sprint'];

    function AcompanhamentoSprintController(AcompanhamentoSprint, $filter, Modulo, SubModulo, Sprint) {
        var vm = this;

        vm.sprints = Sprint.query({page: 0, size: 99});

        vm.chartOptions = {responsive: true, legend: {display: true, position: 'bottom', fullWidth: true}};

        vm.loadAll = loadAll;
        vm.loadUsers = loadUsers;
        vm.loadByUser = loadByUser;

        function loadUsers() {
            if (vm.sprint) {
                AcompanhamentoSprint.getUsersRedmine({id: vm.sprint.id}, function (data) {
                    vm.users = data;
                    if (vm.users.length > 0) {
                        vm.user = vm.users[0];
                        AcompanhamentoSprint.getTicketsRedminePorUsuario({
                            idSprint: vm.sprint.id,
                            idUser: vm.user.id
                        }, loadSuccess);
                    }
                })
            }
        }

        function loadByUser(user) {
            if (vm.sprint) {
                vm.user = user;
                AcompanhamentoSprint.getTicketsRedminePorUsuario({
                    idSprint: vm.sprint.id,
                    idUser: vm.user.id
                }, loadSuccess);
            }
        }

        function loadAll() {
            if (vm.sprint) {
                vm.users = null;
                vm.user = null;
                AcompanhamentoSprint.getTicketsRedmine({id: vm.sprint.id}, loadSuccess);
            }
        }

        function loadSuccess(data) {
            vm.labelsGraficoProjetos = [];
            vm.dataGraficoProjetos = [];

            vm.labelsGraficoTipo = [];
            vm.dataGraficoTipo = [];

            vm.relatorio = data;
            angular.forEach(vm.relatorio.projetos, function (projeto) {
                vm.labelsGraficoProjetos.push(projeto.nome);
                vm.dataGraficoProjetos.push(projeto.tempoGasto);
            });

            angular.forEach(vm.relatorio.tiposTarefas, function (tipo) {
                vm.labelsGraficoTipo.push(tipo.nome);
                vm.dataGraficoTipo.push(tipo.tempoGasto);
            });
        }


    }

})();


