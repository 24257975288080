(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('AprovarDesenvolvimentoController', AprovarDesenvolvimentoController);
    AprovarDesenvolvimentoController.$inject = ['chamado', 'Chamado', '$state', 'SolicitacaoDesenvolvimento'];

    function AprovarDesenvolvimentoController(chamado, Chamado, $state, SolicitacaoDesenvolvimento) {
        var vm = this;
        vm.chamado = chamado;
        vm.chamado.alteracaoCodigo = true;
        vm.solicitacaoDesenvolvimento = {
            chamado: chamado, conteudo: chamado.conteudo, titulo: chamado.titulo
        };
        vm.chamado.complexidade = '';
        vm.calcularPlanoDesenvolvimento = calcularPlanoDesenvolvimento;
        vm.atualizarHoraEstimada = atualizarHoraEstimada;
        vm.conferirTempoApontadoComMedia = conferirTempoApontadoComMedia;
        vm.buscarHorasDoModuloAndSubmodulo = buscarHorasDoModuloAndSubmodulo;

        function buscarHorasDoModuloAndSubmodulo() {
            if (!vm.horasPorModulo || vm.horasPorModulo.length === 0) {
                Chamado.getTempoPorModuloSubModulo(vm.chamado, function (data) {
                    vm.horasPorModulo = data;
                    calcularPlanoDesenvolvimento();
                })
            }
        }

        buscarHorasDoModuloAndSubmodulo();

        function calcularPlanoDesenvolvimento() {
            vm.tempoTarefa = 0;
            if (vm.chamado.complexidade && chamado.tempoEstimado) {
                Chamado.calcularPlanoDesenvolvimento(vm.chamado, function (data) {
                    vm.chamado.planoDesenvolvimento = data;
                    var total = 0;
                    for (var i = 0; i < vm.chamado.planoDesenvolvimento.length; i++) {
                        var plano = vm.chamado.planoDesenvolvimento[i];
                        total = total + plano.tempoEstimado;

                        if (plano.tipoHora === 'DESENVOLVIMENTO' || plano.tipoHora === 'REVISAO') {
                            vm.tempoTarefa = vm.tempoTarefa + plano.tempoEstimado;
                        }

                    }
                });
                vm.conferirTempoApontadoComMedia();
            }
        }

        function atualizarHoraEstimada() {
            var horas = 0;
            angular.forEach(vm.chamado.planoDesenvolvimento, function (plano) {
                horas = horas + plano.tempoEstimado;
            });

            vm.chamado.tempoEstimado = horas;
        }


        function conferirTempoApontadoComMedia() {
            vm.tempoEstimadoMenorQueMedia = false;
            for (var i = 0; i < vm.horasPorModulo.length; i++) {
                var media = vm.horasPorModulo[i];
                media.escolhida = false;
                if (media.tipoChamado === vm.chamado.tipoChamado && media.complexidade === vm.chamado.complexidade && vm.chamado.tempoEstimado < media.tempoGasto) {
                    vm.tempoEstimadoMenorQueMedia = true;
                    media.escolhida = true;
                    vm.verHorasModulo = true;
                }
            }
        }

        vm.ok = function () {
            vm.isSaving = true;
            vm.solicitacaoDesenvolvimento.chamado = vm.chamado;
            if (vm.chamado.criterio) {
                vm.solicitacaoDesenvolvimento.criterio = vm.chamado.criterio;
            }
            vm.solicitacaoDesenvolvimento.conteudo =
                +"<p><b>Resumo Técnico</b></p>" + "<p>" + vm.complemento + "</p>"
                + "<p><b>Prevê Alteração no código fonte? </b>" + (vm.chamado.alteracaoCodigo ? 'Sim' : 'Não')
                + "</p>" + "<p><b>Prevê Alteração na estrutura de tabelas? " + (vm.chamado.alteracaoTabela ? 'Sim' : 'Não')
                + "</p>" + "<p><b>Prevê Alteração na arquitetura do sistema? " + (vm.chamado.alteracaoArquitetura ? 'Sim' : 'Não') + "</p>"
                + "<p><b>Outros Casos de Uso que podem sofrer impacto</b></p>" + "<p>" + vm.impacto + "</p>";

            vm.solicitacaoDesenvolvimento.tempoEstimado = vm.tempoTarefa;

            SolicitacaoDesenvolvimento.save(vm.solicitacaoDesenvolvimento, function (solicitacao) {
                solicitacao.horaAnalise = vm.horaAnalise;
                // $uibModalInstance.close(solicitacao);

                vm.tempoGasto = {chamado: vm.chamado, criadoEm: new Date(), horas: 0, minutos: 0};
                vm.tempoGasto.criadoEm = new Date();
                vm.tempoGasto.numero = 23;
                vm.tempoGasto.conteudo = 'Análise';
                vm.tempoGasto.tempo = solicitacao.horaAnalise;
                Chamado.defirnirTempo(vm.tempoGasto);
                $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
            }, function (error) {
            });

        };

        vm.cancel = function () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('edit-chamado', {id: vm.chamado.id}, {reload: true});
        };
    };


})();
