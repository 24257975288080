(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Chamado', Chamado);

    Chamado.$inject = ['$resource', 'DateUtils'];

    function Chamado($resource, DateUtils) {
        var resourceUrl = 'api/chamados/:id';

        function covertData(data) {
            if (data) {
                data = angular.fromJson(data);
                data.criadoEm = DateUtils.convertLocalDateFromServer(data.criadoEm);
                data.prazo = DateUtils.convertLocalDateFromServer(data.prazo);
            }
            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryBySituacao': {
                method: 'GET',
                url: 'api/chamados-by-situacao/:situacao',
                isArray: true
            },
            'queryBySprint': {
                method: 'GET',
                url: 'api/chamados-by-sprint',
                isArray: true
            },
            'getChamadosSprintAtual': {
                method: 'GET',
                url: 'api/chamados-by-sprint-atual/:sprintId',
                isArray: true
            },
            'getChamadosByRelease': {
                method: 'GET',
                url: 'api/chamados-by-release/:releaseId',
                isArray: true
            },
            'queryValidadosSemRelease': {
                method: 'GET',
                url: 'api/chamados-validados-sem-release',
                isArray: true
            },
            'sincronizarArquivos': {
                method: 'GET',
                url: 'api/sincronizar-arquivos'
            },
            'queryByFiltro': {
                method: 'POST',
                url: 'api/chamados-by-filtro/',
                isArray: true
            },
            'getTempoPorModuloSubModulo': {
                method: 'POST',
                url: 'api/tempos-por-modulo/',
                isArray: true
            },
            'contarByFiltro': {
                method: 'POST',
                url: 'api/contar-chamados/',
                isArray: true
            },
            'solicitarDesenvolvimento': {
                method: 'POST',
                url: 'api/soliciar-desenvolvimento',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateToServer(copy.criadoEm);
                    copy.prazo = DateUtils.convertLocalDateToServer(copy.prazo);
                    return angular.toJson(copy);
                }
            },
            'getSituacoes': {
                method: 'GET',
                url: 'api/situacoes',
                isArray: true
            },
            'getSeveridades': {
                method: 'GET',
                url: 'api/severidades',
                isArray: true
            },
            'getProximasSprints': {
                method: 'GET',
                url: 'api/proximas-sprints',
                isArray: true
            },
            'getSolicitacoes': {
                method: 'GET',
                url: 'api/solicitacoes/:id',
                isArray: true
            },
            'removerSolicitacaoDesenvolvimento': {
                method: 'DELETE',
                url: 'api/solicitacoes/:id',
                isArray: true
            },
            'getTempoGasto': {
                method: 'GET',
                url: 'api/tempo-gasto/:id',
                isArray: true
            },
            'getAtividades': {
                method: 'GET',
                url: 'api/atividades',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return covertData(data);
                }
            },
            'copiar': {
                method: 'GET',
                url: 'api/copiar/:id',
                transformResponse: function (data) {
                    return covertData(data);
                }
            },
            'contarPorSituacao': {
                method: 'GET',
                url: 'api/countar-por-situacao',
                isArray: true
            },
            'criar': {
                method: 'GET',
                url: 'api/chamados-criar',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertLocalDateFromServer(data.criadoEm);
                        data.prazo = DateUtils.convertLocalDateFromServer(data.prazo);
                    }
                    return data;
                }
            },
            'aceitar': {
                method: 'POST',
                url: 'api/chamados-aceitar',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertLocalDateFromServer(data.criadoEm);
                        data.prazo = DateUtils.convertLocalDateFromServer(data.prazo);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateToServer(copy.criadoEm);
                    copy.prazo = DateUtils.convertLocalDateToServer(copy.prazo);
                    return angular.toJson(copy);
                }
            },
            'alocar': {
                method: 'PUT',
                url: 'api/chamados-alocar',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateToServer(copy.criadoEm);
                    copy.prazo = DateUtils.convertLocalDateToServer(copy.prazo);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateToServer(copy.criadoEm);
                    copy.prazo = DateUtils.convertLocalDateToServer(copy.prazo);
                    return angular.toJson(copy);
                }
            },
            'saveSuporte': {
                url: 'api/chamados-suporte',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateToServer(copy.criadoEm);
                    copy.prazo = DateUtils.convertLocalDateToServer(copy.prazo);
                    return angular.toJson(copy);
                }
            },
            'comentar': {
                method: 'POST',
                url: 'api/chamados-comentar'
            },
            'defirnirTempo': {
                method: 'POST',
                url: 'api/tempo-gasto'
            },
            'atualizarTempo': {
                method: 'PUT',
                url: 'api/tempo-gasto'
            },
            'excluirTempo': {
                method: 'DELETE',
                url: 'api/tempo-gasto/:id'
            },
            'queryTempoGasto': {
                url: 'api/buscar-tempo-gasto',
                method: 'POST',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'queryTempoGastoPonto': {
                url: 'api/buscar-horas-apontadas-ponto',
                method: 'POST',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.data = DateUtils.convertLocalDateFromServer(copy.data);
                    copy.hora = DateUtils.convertDateTimeFromServer(copy.hora);
                    return angular.toJson(copy);
                }
            },
            'getProximaOrdem': {
                method: 'GET',
                url: 'api/proxima-ordem/:modulo'
            },
            'getChamadoRedmine': {
                method: 'GET',
                url: 'api/chamados-redmine/:id'
            },
            'getDependentes': {
                method: 'GET',
                isArray: true,
                url: 'api/dependentes-chamados/:id'
            },
            'getRelacoes': {
                method: 'GET',
                isArray: true,
                url: 'api/relacoes-chamados/:id'
            },
            'criarDependente': {
                method: 'POST',
                url: 'api/dependentes-chamados'
            },
            'calcularPlanoDesenvolvimento': {
                isArray: true,
                method: 'POST',
                url: 'api/calcular-plano-desenvolvimento'
            },
            'removerDependente': {
                method: 'DELETE',
                url: 'api/dependentes-chamados/:id'
            },
            'downloadAnexo': {
                method: 'GET',
                url: 'api/download-anexo/:id'
            },
            'getComentarios': {
                method: 'GET',
                url: 'api/comentarios/:id',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertDateTimeFromServer(data.criadoEm);
                    }
                    return data;
                }
            },
            'getComentariosMeiaHora': {
                method: 'GET',
                url: 'api/comentarios/',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertDateTimeFromServer(data.criadoEm);
                    }
                    return data;
                }
            },
            'migrarRedmineByQuery': {
                method: 'GET',
                url: 'api/migrar-chamados-redmine'
            },
            'montarRelatorioAtividades': {
                method: 'POST',
                url: 'api/montar-relatorio-atividades',
                isArray: false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.inicio = DateUtils.convertLocalDateToServer(copy.inicio);
                    copy.fim = DateUtils.convertLocalDateToServer(copy.fim);
                    return angular.toJson(copy);
                }
            },
            'getChamadosBornup': {
                method: 'POST',
                url: 'api/chamados-bornup',
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    /*copy.inicio = DateUtils.convertLocalDateToServer(copy.inicio);
                    copy.fim = DateUtils.convertLocalDateToServer(copy.fim);*/
                    return angular.toJson(copy);
                }
            },
            'alocarSprintAtual': {url: 'api/alocar-chamado', method: 'PUT'},
            'desalocarChamadoSprintAtual': {url: 'api/desalocar-chamado', method: 'PUT'},

            'salvarTagChamado': {
                method: 'POST',
                url: 'api/nova-tag-chamado',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'getTags': {
                method: 'GET',
                url: 'api/buscar-tags?descricao=:descricao&chamado=:chamado',
                isArray: true
            },
            'getTagsPorChamado': {
                method: 'GET',
                url: 'api/buscar-tags-por-chamado/:id',
                isArray: true
            },

        });
    }
})();
