(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Agrupador', Agrupador);

    Agrupador.$inject = ['$resource', 'DateUtils'];

    function Agrupador($resource, DateUtils) {
        var resourceUrl = 'api/chamado-agrupador/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'contarPorCliente': {url: 'api/agrupador/contar-por-cliente', method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertLocalDateFromServer(data.criadoEm);
                        data.previsaoEntrega = DateUtils.convertLocalDateFromServer(data.previsaoEntrega);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'alocar': {
                method: 'POST',
                url: 'api/agrupar-chamado',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.criadoEm = DateUtils.convertLocalDateFromServer(copy.criadoEm);
                    copy.previsaoEntrega = DateUtils.convertLocalDateFromServer(copy.previsaoEntrega);
                    return angular.toJson(copy);
                }
            },
            'getBychamado': {
                method: 'GET',
                url: 'api/agrupador-by-chamado/:id',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.criadoEm = DateUtils.convertLocalDateFromServer(data.criadoEm);
                        data.previsaoEntrega = DateUtils.convertLocalDateFromServer(data.previsaoEntrega);
                    }
                    return data;
                }
            },
            'getBySituacao': {
                method: 'GET',
                url: 'api/agrupador-por-situacao',
                isArray: true,

            }
        });
    }
})();
