(function () {
    'use strict';
    /* globals SockJS, Stomp */

    angular
        .module('chamadosApp')
        .factory('WsNotificationService', WsNotificationService);

    WsNotificationService.$inject = ['$rootScope', '$window', '$cookies', '$http', '$q', 'AuthServerProvider'];

    function WsNotificationService($rootScope, $window, $cookies, $http, $q, AuthServerProvider) {
        var stompClient = null;
        var subscriber = null;
        var listener = $q.defer();
        var connected = $q.defer();
        var alreadyConnectedOnce = false;

        var service = {
            connect: connect,
            disconnect: disconnect,
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe
        };

        return service;

        function connect() {
            //building absolute path so that websocket doesn't fail when deploying with a context path
            var loc = $window.location;
            var url = '//' + loc.host + loc.pathname + 'websocket';
            console.log("URL WS " + url);
            var authToken = AuthServerProvider.getToken();
            if (authToken) {
                url += '?access_token=' + authToken;
            }
            var socket = new SockJS(url);
            stompClient = Stomp.over(socket);
            var headers = {};
            stompClient.connect(headers, function () {
                connected.resolve('success');
                sendActivity();
                if (!alreadyConnectedOnce) {
                    alreadyConnectedOnce = true;
                }
            });

        }

        function disconnect() {
            if (stompClient !== null) {
                stompClient.disconnect();
                stompClient = null;
            }
        }

        function receive() {
            return listener.promise;
        }

        function sendActivity() {
            if (stompClient !== null && stompClient.connected) {
                stompClient
                    .send('/topic/notification',
                        {},
                        angular.toJson({'page': $rootScope.toState.name}));
            }
        }

        function subscribe() {
            connected.promise.then(function () {
                subscriber = stompClient.subscribe('/user/queue/notification', function (data) {
                    listener.notify(angular.fromJson(data.body));
                });
            }, null, null);
        }

        function unsubscribe() {
            if (subscriber !== null) {
                subscriber.unsubscribe();
            }
            listener = $q.defer();
        }

    }
})();
