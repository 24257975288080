(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Notificacao', Notificacao);

    Notificacao.$inject = ['$resource'];

    function Notificacao($resource) {
        var resourceUrl = 'api/notificacoes/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'queryNaoLidas': {url: 'api/notificacoes-nao-lidas', method: 'GET', isArray: true},
            'marcarComoLida': {url: 'api/notificacoes-marcar-como-lidas', method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'notificar': {url: 'api/notificar', method: 'GET'},
            'deploy': {url: 'public/riobranco/deploy-producao/:id', method: 'GET'}
        });
    }
})();
