(function () {
    'use strict';
    angular
        .module('chamadosApp')
        .factory('Uf', Uf);

    Uf.$inject = ['$resource'];

    function Uf($resource) {
        return $resource('api/ufs/:id', {}, {
            'findAll': {
                url: 'api/ufs',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
