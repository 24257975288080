(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('burnup', {
            parent: 'app',
            url: '/burnup',
            data: {
                authorities: ['ROLE_PO', 'ROLE_ATENDENTE', 'ROLE_DESENVOLVEDOR'],
            },
            ncyBreadcrumb: {
                label: 'Bornup'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/burnup/burnup.html',
                    controller: 'BornupController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('situacaoChamado');
                    $translatePartialLoader.addPart('tipoChamado');
                    $translatePartialLoader.addPart('situacaoTarefa');
                    return $translate.refresh();
                }]
            }
        })
    }
})();
