(function() {
    'use strict';

    angular
        .module('chamadosApp')
        .controller('SubModuloController', SubModuloController);

    SubModuloController.$inject = ['$scope', '$state', 'SubModulo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Alerta'];

    function SubModuloController ($scope, $state, SubModulo, ParseLinks, AlertService, paginationConstants, pagingParams, Alerta) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.remover = remover;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        loadAll();

        function loadAll () {
            SubModulo.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.subModulos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function remover(id) {
            Alerta.confirm("Atenção", "Tem certeza que deseja remover esse SubModulo?", "warning", function () {
                SubModulo.delete({id: id},
                    function () {
                        Alerta.success("Operação realizada", "O SubModulo foi removido!");
                        loadAll();
                    });
            });
        }

    }
})();
