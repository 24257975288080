(function () {
    'use strict';

    angular
        .module('chamadosApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pesquisa', {
                parent: 'entity',
                url: '/pesquisa?search',
                data: {
                    authorities: ['ROLE_ATENDENTE', 'ROLE_CLIENTE', 'ROLE_RESPONSAVEL', 'ROLE_DESENVOLVEDOR', 'ROLE_PO'],
                    pageTitle: 'Pesquisa'
                },
                ncyBreadcrumb: {
                    label: 'Pesquisa'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pesquisa/pesquisa.html',
                        controller: 'PesquisaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('chamado');
                        $translatePartialLoader.addPart('severidade');
                        $translatePartialLoader.addPart('tipoChamado');
                        $translatePartialLoader.addPart('complexidadeChamado');
                        $translatePartialLoader.addPart('tipoHora');
                        $translatePartialLoader.addPart('situacaoChamado');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
